import moment from "moment";
import { GlobalVariable } from "./globleVariable";
export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let croppedImage = new File([u8arr], filename, { type: mime });
  return croppedImage;
};

export const getStartTimeAndEndTime = (data) => {
  let filterData = []
  for (let i = 0; i < data.length; i++) {
    let day = data[i].day;
        filterData.push({ day, ...{ startTime: 0, endTime: 24 } })
    }
    return filterData
}


export const getproperData = (times, timeData) => {
  let finalData = []
  for (let i = 0; i < times.length; i++) {
    let startTime = times[i].startTime
    let endTime = times[i].endTime
    let filterDayData = timeData.filter((e) => e?.day === times[i]?.day)
    let dataObject = [];
    if (filterDayData && filterDayData?.length > 0) {
      let keys = Object.keys(filterDayData[0].hourWiseCount)
      for (let h of GlobalVariable.hours) {
        let filterHour = keys.find((ho) => +ho === h)
        if (startTime !== "" && endTime !== "") {
          if (keys.length > 0 && filterHour) {
            dataObject.push({
              hour: String(h),
              ticket: filterDayData[0].hourWiseCount[`${h}`]
            })
          } else if (dataObject.length > 0 || (h >= startTime && h <= endTime)) {
            dataObject.push({
              hour: String(h),
              ticket: 0
            })
          }

        } else {
          if (keys.length > 0 && filterHour) {
            dataObject.push({
              hour: String(h),
              ticket: filterDayData[0].hourWiseCount[`${h}`]
            })
          } else if (dataObject.length > 0) {
            dataObject.push({
              hour: String(h),
              ticket: 0
            })
          }
        }
      }
    }
    finalData.push([{ day: filterDayData[0]?.day }, [...dataObject]])
  }
  return finalData
}

export const convertToAmPm = (hour) => {
  if (hour === 0) {
    return "12am";
  } else if (hour === 24) {
    return "12am";
  } else if (hour === 12) {
    return "12pm";
  } else if (hour < 12) {
    return `${hour}am`;
  } else {
    return `${hour - 12}pm`;
  }
};
