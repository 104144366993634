import React from "react";
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";
import { connect } from "react-redux";
// import ReactCountryFlag from "react-country-flag";
import { history } from "../../../history";
import { IntlContext } from "../../../utility/context/Internationalization";
import { logOutAction, setLanguage } from "../../../redux/actions/auth/index";
import { cleanDashboardReducer } from "../../../redux/actions/dashboard";
import { GlobalVariable } from "../../../util";
import i18n from "../../../i18n/i18n";
import { changeDirection } from "../../../redux/actions/customizer";
import { useTranslation } from "react-i18next";

const langArr = {
  en: "English",
  // "de" : "German",
  // "fr" : "French",
  // "pt" : "Portuguese"
  ar: "العربية",
};

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  const { t } = useTranslation()
  // const { logout, isAuthenticated } = useAuth0()
  return (
    <DropdownMenu style={{ minWidth: "8.5rem" }}>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/profile")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">{t("COMMON.Profile")}</span>
      </DropdownItem>

      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/password/reset")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">{t("COMMON.Change Password")}</span>
      </DropdownItem>

      <DropdownItem divider />
      <DropdownItem
        tag="a"
        // href="/pages/login"
        onClick={(e) => {
          e.preventDefault();
          history.push("/login");
          props.cleanDashboardReducer();
          props.logOutAction();
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">{t("COMMON.Log Out")}</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    shoppingCart: [],
    suggestions: [],
    language: localStorage.getItem(GlobalVariable.localLang) || 'en',
  };

  componentDidMount() {
    // axios.get("/api/main-search/data").then(({ data }) => {
    //   this.setState({ suggestions: data.searchResult });
    // });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown });

  langChange = (lang) => {
    // this.setState({ language: lang});
    // this.props.setLanguage(lang);
    // i18n.changeLanguage(lang)
    localStorage.setItem(GlobalVariable.localLang, lang);
    // this.props.changeDirection(lang === GlobalVariable.arabic ? 'rtl' : 'ltr')
    window.location.reload();
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <Dropdown
          tag="li"
          className="dropdown-language nav-item"
          isOpen={this.state.langDropdown}
          toggle={this.handleLangDropdown}
          data-tour="language"
        >
          <DropdownToggle tag="a" className="nav-link">
            {/* <img
              className="country-flag"
              src={require("../../../assets/img/svg/us.svg")}
              alt="close"
            /> */}
            <Icon.Globe size={22} className="mr-50" />
            <span className="d-sm-inline-block d-none text-capitalize align-middle ">
              {langArr[this.state.language]}
            </span>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              tag="a"
              onClick={(e) => {
                this.langChange('en');
              }}
            >
              {/* <ReactCountryFlag
                      className="country-flag"
                      countryCode="us"
                      svg
                    /> */}
              {/* <img
                className="country-flag"
                src={require("../../../assets/img/svg/us.svg")}
                alt="close"
              /> */}
               <Icon.Globe size={22} className="mr-50" />
              <span className="align-middle">English</span>
            </DropdownItem>
            <DropdownItem
              tag="a"
              onClick={(e) => {
                this.langChange('ar');
              }}
            >
              {/* <ReactCountryFlag
                      className="country-flag"
                      countryCode="us"
                      svg
                    /> */}
              {/* <img
                className="country-flag"
                src={require("../../../assets/img/svg/us.svg")}
                alt="close"
              /> */}
              <Icon.Globe size={22} className="mr-50" />
              <span className="align-middle">العربية</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <UncontrolledDropdown
          tag="li"
          className="dropdown-user nav-item user-block-navbar"
        >
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <span data-tour="user" className="d-flex align-items-center w-100">
              {/* <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              /> */}
              <span className="username-block">
                <span className="h6 f-600 mb-0 ml-1 d-flex">
                  <div style={{ minWidth:'45px'}}>
                  {" "}
                  {this.props.role === GlobalVariable.manager ? (this.props.user?.name) :
                  this.props.currentUser && (
                    this.props.lang === GlobalVariable.arabic ? this.props.currentUser.arName : this.props.currentUser.name
                  )}
                  </div>
                </span>
              </span>
              <span className="right-down-arrow ml-auto d-none d-md-flex">
                <Icon.ChevronDown className="ficon" />
              </span>
            </span>
            {/* <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">Available</span>
            </div> */}
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.login.user,
    lang: state.auth.login.lang,
    currentUser: state.auth.login.currentUser,
    role: state.auth.login.user.role,
  };
};

export default connect(mapStateToProps, {
  logOutAction,
  cleanDashboardReducer,
  setLanguage,
  changeDirection
})(NavbarUser);
