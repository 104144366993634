import {
    SET_SEARCH,
  } from "../../actions/types";
  
  const initState = {
    search: "",
  };
  
  const form = (state = initState, action) => {
    switch (action.type) {
      case SET_SEARCH: {
        return { ...state, search: action.payload };
      }
      
      default: {
        return { ...state };
      }
    }
  };
  
  export default form;
  