import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Disc, X, Circle } from "react-feather";
import classnames from "classnames";
import { connect } from "react-redux";
class SidebarHeader extends Component {
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow,
    } = this.props;

    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row align-items-center">
          <li className="nav-item mr-auto">
            <NavLink to="/" className="navbar-brand mt-0">
              <div>
                {collapsed === false ? (
                  this.props.currentUser.logoUrl ? (
                    <div style={{ height: "50px" }}>
                      <img
                        src={this.props.currentUser.logoUrl}
                        height="100%"
                        width="auto"
                        style={{ objectFit: "cover" }}
                        alt="logo"
                      />
                    </div>
                  ) : (
                    <span className="shrink-blk">
                      <h2 className="brand-text mb-0">
                        {this.props.currentUser.name &&
                          this.props.currentUser.name.charAt(0)}
                      </h2>
                    </span>
                  )
                ) : this.props.currentUser.mobileLogoURL ? (
                  <img
                    src={this.props.currentUser.mobileLogoURL}
                    height="50"
                    width="50"
                    alt=""
                  />
                ) : (
                  <span className="shrink-blk">
                    <h2 className="brand-text pl-0 mb-0 d-block">
                      {this.props.currentUser.name &&
                        this.props.currentUser.name.charAt(0)}
                    </h2>
                  </span>
                )}
              </div>

              {/* <h2 className="brand-text mb-0">{this.props.currentUser.name && this.props.currentUser.name}</h2> */}
            </NavLink>
          </li>
          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                <Disc
                  onClick={() => {
                    toggleSidebarMenu(true);
                    toggle();
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark",
                    }
                  )}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                <Circle
                  onClick={() => {
                    toggleSidebarMenu(false);
                    toggle();
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark",
                    }
                  )}
                  size={20}
                />
              )}
              <X
                onClick={sidebarVisibility}
                className={classnames(
                  "toggle-icon icon-x d-block d-xl-none font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark",
                  }
                )}
                size={20}
              />
            </div>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // user: state.auth.login.user,
    currentUser: state.auth.login.currentUser,
  };
};

export default connect(mapStateToProps, {})(SidebarHeader);

// export default SidebarHeader
