import {
  SET_IS_AUTH,
  SET_USER_TOKEN,
  SET_USER_REFRESHTOKEN,
  SET_AUTH_USER,
  SET_CURRENT_USER,
  SET_FORGOTPASSWORD_USER,
  IS_ADD_USER_AVAIABLE,
  SET_USER_CREATE_AVAILABLE,
  SET_INACTIVE_USER,
  SET_PUBLIC_KEY,
  USER_PERMISSION,
  SET_LANG,
} from "../../actions/types";

const initState = {
  isAuthenticated: false,
  userRole: "admin",
  token: "",
  user: {},
  refreshToken: "",
  currentUser: {},
  forgotPasswordUser: {},
  isAddUserAvailable: true,
  availbleUserAddCount: 0,
  availbleinActiveUser: 0,
  publickKey: {},
  lang:'en'
};

const login = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload };
    }
    case SET_IS_AUTH: {
      return { ...state, isAuthenticated: action.payload };
    }
    case SET_USER_TOKEN: {
      return { ...state, token: action.payload };
    }
    case SET_USER_REFRESHTOKEN: {
      return { ...state, refreshToken: action.payload };
    }
    case SET_AUTH_USER: {
      return { ...state, user: action.payload };
    }
    case USER_PERMISSION: {
      return { ...state, user: { ...state.user, permission: action.payload } };
    }
    case SET_CURRENT_USER: {
      return { ...state, currentUser: action.payload };
    }
    case SET_FORGOTPASSWORD_USER: {
      return { ...state, forgotPasswordUser: action.payload };
    }
    case IS_ADD_USER_AVAIABLE: {
      return { ...state, isAddUserAvailable: action.payload };
    }
    case SET_USER_CREATE_AVAILABLE: {
      return { ...state, availbleUserAddCount: action.payload };
    }
    case SET_INACTIVE_USER: {
      return { ...state, availbleinActiveUser: action.payload };
    }
    case SET_PUBLIC_KEY: {
      return { ...state, publickKey: action.payload };
    }
    case SET_LANG: {
      return { ...state, lang: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default login;
