import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import navigationConfig from "../../../../../configs/navigationConfig";
import SideMenuGroup from "./SideMenuGroup";
import { Badge } from "reactstrap";
import { ChevronRight } from "react-feather";
import { FormattedMessage } from "react-intl";
import { history } from "../../../../../history";
import i18n from "../../../../../i18n/i18n";
import { donationTypeMenu } from "../../../../../constants";

class SideMenuContent extends React.Component {
  
  constructor(props) {
    super(props);

    this.parentArr = [];
    this.collapsedPath = null;
    this.redirectUnauthorized = () => {
      history.push("/misc/not-authorized");
    };
  }
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
    dashboardTab: true,
    appointmentDashboardTab:true,
    eserviceDashboardTab:true,
    serviceTab: true,
    queueTab: true,
    userTab: true,
    branchTab: true,
    feedbackTab: true,
    settingTab: true,
    logTab: true,
    reportTab: true,
    workProfileTab: true,
    categoryTab:true,
    // eventTab:true,
    livefeedTab:true,
    config: [],
    isRender: false,
    language: "",
    formTab:true
    
  };

  handleGroupClick = (id, parent = null, type = "") => {
    let open_group = this.state.activeGroups;
    let active_group = this.state.currentActiveGroup;
    let temp_arr = this.state.tempArr;
    // Active Group to apply sidebar-group-active class
    if (type === "item" && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === "item" && parent !== null) {
      active_group = [];
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [];
        temp_arr.push(parent);
      }
      active_group = temp_arr.slice(0);
    } else if (type === "collapse" && parent === null) {
      temp_arr = [];
      temp_arr.push(id);
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0);
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      temp_arr = [];
    }

    if (type === "collapse") {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function (obj) {
          return active_group.indexOf(obj) === -1;
        });
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function (obj) {
            return !temp.includes(obj);
          });
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0);
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id);
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0);
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group,
    });
  };

  initRender = (parentArr) => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false,
    });

  };

  componentDidMount() {
    this.handleInitalCall();
    const { permission } = this.props.user;
    const { agentPerformance, servicePerformance,isAppointment,isEservice,isLiveFeed,isDonation } = this.props;
    let navArr = [];
    // make sure key must be same as navigationConfig id
    let dashboardTab = { 
      id:'dashboard',
      isChild:true,
      child:[
        {
          id:'ticketDashboard',
          ticketDashboard:permission[0].dashboardTab.read 
        },
        {
          id:'appointmentDashboard',
          appointmentDashboard: permission[14]?.appointmentsDashboardTab?.read && isAppointment ? true : false 
        }, 
        {
          id:'eserviceDashboard',
          eserviceDashboard: permission[15]?.eservicesDashboardTab?.read && isEservice ? true : false 
        },
        {
          id:'donationDashboard',
          donationDashboard: permission[19]?.donationDashboardTab?.read && isDonation ? true : false
        }
      ]
    };

    let serviceTab = {
      id:'services',
      services: permission[1].serviceTab.read 
    };
    let queueTab = {
      id:'queues', 
      queues: permission[2].queueTab.read 
    };
    let userTab = {
      id : 'users',
      users: permission[3].userTab.read 
    };
    let branchTab = {
      id:'branches',
      branches: permission[4].branchTab.read 
    };
    let feedbackTab = { 
      id:'feedback',
      feedback: permission[5].feedbackTab.read 
    };
    let settingTab = { 
      id:'settings',
      settings: permission[6].settingTab.read 
    };
    let logTab = { 
      id:'logs',
      isChild:true,
      child:[
        {
          id:'merchantUsersLogs',
          merchantUsersLogs: permission[7].logTab.read 
        },
        {
          id:'instantTicketsLogs',
          instantTicketsLogs: permission[16]?.instantTicketsLogTab?.read 
        },
        {
          id:'appointmentsLogs',
          appointmentsLogs: permission[17]?.appointmentsLogTab?.read && isAppointment ? true : false  
        },
        {
          id:'eservicesLogs',
          eservicesLogs: permission[18]?.eservicesLogTab?.read && isEservice ? true : false 
        },
        {
          id:'donationLogs',
          donationLogs: permission[20]?.donationLogTab?.read && isDonation ? true : false 
        },
      ]
    };
    let reportTab = {
      id:'reports',
      reports:
        permission[8]?.reportTab?.read &&
        (agentPerformance === true || servicePerformance === true)
          ? true
          : false,
    };
    let workProfileTab = { 
      id:'workProfiles',
      workProfiles: permission[9]?.workProfileTab?.read 
    };
    let categoryTab = {
      id:'categories',
      categories: permission[10]?.categoryTab?.read 
        ? true
        : false,
    };
    // let eventTab = {
      // id:'events',
    //   events:
    //     permission[11]?.eventTab?.read &&
    //     (isEvent === true)
    //       ? false
    //       : false
    //    ,
    // };
    let formTab = {
      id:'forms',
      forms: permission[12]?.formTab?.read 
        ? true
        : false,
    };
    let livefeedTab = {
      id:'livefeed',
      livefeed: permission[13]?.livefeedTab?.read && (isLiveFeed === true) ? true : false ,
    };

    navArr.push(
      dashboardTab,
      serviceTab,
      queueTab,
      userTab,
      branchTab,
      feedbackTab,
      settingTab,
      logTab,
      reportTab,
      workProfileTab,
      categoryTab,
      // eventTab,
      formTab,
      livefeedTab
    );


    let config = [];
    let navigationConfigTemp = [...navigationConfig];
    for (let i = 0; i < navigationConfigTemp.length; i++) {
      let navObject = {...navigationConfigTemp[i]};
      let module = navArr.find(m => m.id === navObject.id);
      let donationModule = {}
      if(isDonation){
        donationModule = donationTypeMenu.find(m => m.id === navObject.id);
      }
      if(module && donationModule){
        if(module.isChild){
          let child = [];
          let configChild = [...navObject.children];
          for(let j = 0; j < configChild.length; j++) {
            let submodule = module.child.find(m => m.id === configChild[j].id);
            let subDonationModule = {}
            if(isDonation){
              subDonationModule = donationModule?.child.find(m => m.id === configChild[j].id);
            }
            if(submodule && subDonationModule && submodule[configChild[j].id]){
              child.push(configChild[j])
            }
          }
          if(child.length){
            navObject.children = child;
            config.push(navObject);
          }
        }else{
          if (module[navObject.id]) config.push(navObject)
        }
      }
    }
    this.setState({ config: config });
    this.setState({language:this.props.lang})
    this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.agentPerformance === false &&
      this.props.servicePerformance === false
    ) {
      let configTemp = [...this.state.config];
      if (configTemp.find((c) => c.id === "reports")) {
        let configFilter = configTemp.filter((c) => c.id !== "reports");
        this.setState({ config: configFilter });
      }
    }
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths);
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      );
    }
    
  }

  handleInitalCall(){
    let openGroup =[];
    for (let navConfig of navigationConfig) {
      if(navConfig.type === 'collapse'){
        let children = navConfig?.children || []
        for(let child of children){
          if(this.props.activeItemState === child.navLink){
            openGroup.push(navConfig.id);
          }
        }
      }
    }
    this.parentArr = [openGroup];
  }
  
  render() {
    // Loop over sidebar items
    // eslint-disable-next-line
    // eslint-disable-next-line array-callback-return
    let menuItems = this.state.config.map((item) => {
      const CustomAnchorTag = item.type === "external-link" ? `a` : Link;
      // checks if item has groupheader
      if (item.type === "groupHeader") {
        return (
          <li
            className="navigation-header"
            key={`group-header-${item.groupTitle}`}
          >
            <span>{item.groupTitle}</span>
          </li>
        );
      }
      let renderItem = (
        <li
          className={classnames("nav-item", {
            "has-sub": item.type === "collapse",
            open:
              this.state.activeGroups.includes(item.id),
            "sidebar-group-active": this.state.currentActiveGroup.includes(
              item.id
            ),
            hover: this.props.hoverIndex === item.id,
            active:
              (this.props.activeItemState === item.navLink &&
                item.type === "item") ||
              (item.parentOf &&
                item.parentOf.includes(this.props.activeItemState)),
            disabled: item.disabled || (this.props.activeItemState === item.navLink &&
              item.type === "item"),
          })}
          key={item.id}
          onClick={(e) => {
            e.stopPropagation();
            if (item.type === "item") {
              this.props.handleActiveItem(item.navLink);
              this.handleGroupClick(item.id, null, item.type);
              if (this.props.deviceWidth <= 1200 && item.type === "item") {
                this.props.toggleMenu();
              }
            } else {
              this.handleGroupClick(item.id, null, item.type);
            }
          }}
        >
          <CustomAnchorTag
            to={
              item.filterBase
                ? item.filterBase
                : item.navLink && item.type === "item"
                ? item.navLink
                : ""
            }
            href={item.type === "external-link" ? item.navLink : ""}
            className={`d-flex ${
              item.badgeText
                ? "justify-content-between"
                : "justify-content-start"
            }`}
            onMouseEnter={() => {
              this.props.handleSidebarMouseEnter(item.id);
            }}
            onMouseLeave={() => {
              this.props.handleSidebarMouseEnter(item.id);
            }}
            key={item.id}
            onClick={(e) => {
              return item.type === "collapse" ? e.preventDefault() : "";
            }}
            target={item.newTab ? "_blank" : undefined}
          >
            <div className="menu-text">
              {item.icon}
              <span className="menu-item menu-title">
                <FormattedMessage id={i18n.t(`COMMON.${item.id}`)} />
              </span>
            </div>

            {item.badge ? (
              <div className="menu-badge">
                <Badge color={item.badge} className="mr-1" pill>
                  {item.badgeText}
                </Badge>
              </div>
            ) : (
              ""
            )}
            {item.type === "collapse" ? (
              <ChevronRight className="menu-toggle-icon" size={13} />
            ) : (
              ""
            )}
          </CustomAnchorTag>
          {item.type === "collapse" ? (
            <SideMenuGroup
              group={item}
              handleGroupClick={this.handleGroupClick}
              activeGroup={this.state.activeGroups}
              handleActiveItem={this.props.handleActiveItem}
              activeItemState={this.props.activeItemState}
              handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
              activePath={this.props.activePath}
              hoverIndex={this.props.hoverIndex}
              initRender={this.initRender}
              parentArr={this.parentArr}
              triggerActive={undefined}
              currentActiveGroup={this.state.currentActiveGroup}
              permission={this.props.permission}
              currentUser={this.props.currentUser}
              redirectUnauthorized={this.redirectUnauthorized}
              collapsedMenuPaths={this.props.collapsedMenuPaths}
              toggleMenu={this.props.toggleMenu}
              deviceWidth={this.props.deviceWidth}
            />
          ) : (
            ""
          )}
        </li>
      );

      if (
        item.navLink &&
        item.collapsed !== undefined &&
        item.collapsed === true
      ) {
        this.collapsedPath = item.navLink;
        this.props.collapsedMenuPaths(item.navLink);
      }

      if (
        item.type === "collapse" ||
        item.type === "external-link" ||
        (item.type === "item" &&
          item.permissions &&
          item.permissions.includes(this.props.currentUser)) ||
        item.permissions === undefined
      ) {
        return renderItem;
      } else if (
        item.type === "item" &&
        item.navLink === this.props.activePath &&
        !item.permissions.includes(this.props.currentUser)
      ) {
        return this.redirectUnauthorized();
      }
    });

    return <React.Fragment>{menuItems}</React.Fragment>;
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.login.user,
    role: state.auth.login.user.role,
    agentPerformance: state.auth.login.currentUser.agentPerformance,
    servicePerformance: state.auth.login.currentUser.servicePerformance,
    isEvent: state.auth.login.currentUser.isEvent,
    lang: state.auth.login.lang,
    isAppointment: state.auth.login.currentUser.isAppointment,
    isEservice:state.auth.login.currentUser.isEservice,
    isLiveFeed:state.auth.login.currentUser.isLiveFeed,
    isDonation:state.auth.login.currentUser.isDonate
  };
};
export default connect(mapStateToProps, {})(SideMenuContent);
