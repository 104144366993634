import {
  ADD_FEEDBACK,
  TOTAL_FEEDBACK_COUNT,
  ADD_AGENT,
} from "../../actions/types";

const initState = {
  feedback: [],
  count: 0,
  agent: [],
};

const feedback = (state = initState, action) => {
  switch (action.type) {
    case ADD_FEEDBACK: {
      return { ...state, feedback: action.payload };
    }
    case TOTAL_FEEDBACK_COUNT: {
      return { ...state, count: action.payload };
    }
    case ADD_AGENT: {
      return { ...state, agent: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default feedback;
