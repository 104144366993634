import {
  ADD_SERVICE,
  GET_QUEUE_SERVICE,
  ADD_MANAGER_SERVICE,
  SERVICE_TYPE_DROPDOWN
} from "../types"
import { api } from "../../../api/api"
import { QueueApi } from "../../../api/queueApi"
import toast from "react-hot-toast"
import { store } from "../../storeConfig/store"
import { GlobalVariable } from "../../../util/index"
import { history } from "../../../history"
import { getTranslation } from "../../../i18n/i18n"
import { setLoaderAction } from "../loader/index"
import { getQueueAction } from "../queue/index"
import { encryptStorage } from "../../../util/encryptStorage"
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input"
import { urlRegex, EMAIL_VALIDATION } from "../../../constants"
import moment from "moment"

const api_status = GlobalVariable.apiResponseStatus

export const urlPatternValidation = (URL) => {
  const regex = new RegExp(urlRegex)
  return regex.test(URL)
}

/**
 @name  addServiceAction
 @file service.js
 @description This method is used for create new service
 */
export const addServiceAction = (service) => {
  return async (dispatch, store) => {
    let {
      name,
      arabicName,
      servingTime,
      status,
      documentFeedName,
      documentFeedArabicName,
      prefix,
      inValidPrefix,
      prefixLoading,
      // appointmentPrefix,
      // inValidAppointmentPrefix,
      // appointmentPrefixLoading,
      maxRebookTime,
      limitedSeat,
      informative,
      type,
      serviceLogo,
      isServiceOnHome,
      textUnderTime,
      arabicTextUnderTime,
      textUnderPeople,
      arabicTextUnderPeople,
      form,
      serviceBanner,
      email,
      serviceImage,
      titleOnListEnglish,
      titleOnListArabic,
      textUnderTimeFinish,
      arabicTextUnderTimeFinish,
      maxTimeToAllowBooking,
      minTimeBeforeAllowBooking,
      workProfile,
      level1,
      level2,
      level3,
      appointmentServingTime
    } = service
    let workHoursseperate = []
    if (!name.trim()) {
      toast.error(getTranslation("GLOBLE_MESSAGE.nameRequired"))
      return true
    }

    if (!arabicName.trim()) {
      toast.error(getTranslation("GLOBLE_MESSAGE.arNameRequired"))
      return true
    }

    if (!status.value) {
      toast.error(getTranslation("GLOBLE_MESSAGE.statusRequired"))
      return true
    }

    // if(!documentFeedName){
    //     toast.error(getTranslation('GLOBLE_MESSAGE.feedName'));
    //     return true;
    // }

    // if(!documentFeedArabicName){
    //     toast.error(getTranslation('GLOBLE_MESSAGE.arabicFeedName'));
    //     return true;
    // }

    if (type === "queue" || type === "appointment") {
      if (type === "queue") {
        if (!servingTime) {
          toast.error(getTranslation("GLOBLE_MESSAGE.servingTimeRequired"))
          return true
        }
      }

      if (!prefix) {
        toast.error(getTranslation("GLOBLE_MESSAGE.PrifixRequired"))
        return true
      }

      if (prefixLoading || inValidPrefix) {
        toast.error(getTranslation("GLOBLE_MESSAGE.PrifixNotValid"))
        return true
      }

      // if (!appointmentPrefix) {
      //   toast.error(getTranslation("GLOBLE_MESSAGE.AppointmentPrifixRequired"));
      //   return true;
      // }

      // if (inValidAppointmentPrefix || appointmentPrefixLoading) {
      //   toast.error(getTranslation("GLOBLE_MESSAGE.AppointmentPrifixNotValid"));
      //   return true;
      // }

      if (type === "queue") {
        if (!maxRebookTime) {
          toast.error(getTranslation("GLOBLE_MESSAGE.maxRebookTimeRequired"))
          return true
        }
      }

      if (type === "appointment") {
        if (!workProfile.value) {
          toast.error(getTranslation("SERVICE.workProfile"))
          return true
        }
        if (!maxTimeToAllowBooking) {
          toast.error(getTranslation("SERVICE.maxTimeToAllowBooking"))
          return true
        }
        if (Number(maxTimeToAllowBooking) > 60) {
          toast.error(getTranslation("SERVICE.maxDayisNotGraterthen"))
          return true
        }
        if (!minTimeBeforeAllowBooking) {
          toast.error(getTranslation("SERVICE.minTimeBeforeAllowBooking"))
          return true
        }
        if (Number(minTimeBeforeAllowBooking) > Number(maxTimeToAllowBooking)) {
          toast.error(getTranslation("SERVICE.MinDayCheck"))
          return true
        }
        if (!level1.days) {
          toast.error(getTranslation("SERVICE.level1Days"))
          return true
        }
        if (Number(level1.days) > Number(maxTimeToAllowBooking)) {
          toast.error(getTranslation("SERVICE.level1isGrater"))
          return true
        }

        if (Number(level1.hours) > 23) {
          toast.error(getTranslation("SERVICE.level1Hours23"))
          return true
        }
        if (!level2.days) {
          toast.error(getTranslation("SERVICE.level2Days"))
          return true
        }
        if (Number(level2.days) > Number(level1.days)) {
          toast.error(getTranslation("SERVICE.level2isGrater"))
          return true
        }

        if (Number(level2.hours) > 23) {
          toast.error(getTranslation("SERVICE.level2Hours23"))
          return true
        }
        if (Number(level2.days) === Number(level1.days)) {
          if (Number(level2.hours) >= Number(level1.hours)) {
            toast.error(getTranslation("SERVICE.level2HoursCheck"))
            return true
          }
        }
        // if (!level2.hours) {
        //   toast.error(getTranslation("SERVICE.level2Hours"))
        //  return true
        // }
        if (!level3.days) {
          toast.error(getTranslation("SERVICE.level3Days"))
          return true
        }
        if (Number(level3.days) > Number(level2.days)) {
          toast.error(getTranslation("SERVICE.level3isGrater"))
          return true
        }
        if (Number(level3.hours) > 23) {
          toast.error(getTranslation("SERVICE.level3Hours23"))
          return true
        }
        if (Number(level3.days) === Number(level2.days)) {
          if (Number(level3.hours) >= Number(level2.hours)) {
            toast.error(getTranslation("SERVICE.level3HoursCheck"))
            return true
          }
        }
        // if (!level3.hours) {
        //   toast.error(getTranslation("SERVICE.level3Hours"))
        //  return true
        // }
      }

      if (
        (documentFeedName.trim() && !documentFeedArabicName.trim()) ||
        (documentFeedArabicName.trim() && !documentFeedName.trim())
      ) {
        toast.error(getTranslation("GLOBLE_MESSAGE.serviceNameRequiredBothLang"))
        return true
      }
    }

    if (type === "interactive" || type === "informative") {
      if (!service.parentCategory.value) {
        toast.error(getTranslation("SERVICE.CategoryisRequire"))
        return true
      }
      if (!titleOnListEnglish.trim()) {
        toast.error(getTranslation("SERVICE.titleOnListEnglish"))
        return true
      }
      if (!titleOnListArabic.trim()) {
        toast.error(getTranslation("SERVICE.titleOnListArabic"))
        return true
      }

      if (type === "interactive") {
        if (!textUnderPeople.trim()) {
          toast.error(getTranslation("SERVICE.textUnderPeople"))
          return true
        }
        if (!arabicTextUnderPeople.trim()) {
          toast.error(getTranslation("SERVICE.arabicTextUnderPeople"))
          return true
        }
        if (!textUnderTime.trim()) {
          toast.error(getTranslation("SERVICE.textUnderTime"))
          return true
        }
        if (!arabicTextUnderTime.trim()) {
          toast.error(getTranslation("SERVICE.arabicTextUnderTime"))
          return true
        }
        if (!textUnderTimeFinish.trim()) {
          toast.error(getTranslation("SERVICE.textUnderTimeFinish"))
          return true
        }
        if (!arabicTextUnderTimeFinish.trim()) {
          toast.error(getTranslation("SERVICE.arabicTextUnderTimeFinish"))
          return true
        }
        if (!prefix) {
          toast.error(getTranslation("SERVICE.ticketPrefix"))
          return true
        }
        for (let i = 0; i < limitedSeat.length; i++) {
          if (!limitedSeat[i].routeName.trim()) {
            toast.error(getTranslation("SERVICE.routeName"))
            return true
          }
          if (!limitedSeat[i].arabicRouteName.trim()) {
            toast.error(getTranslation("SERVICE.arabicRouteName"))
            return true
          }
          if (!limitedSeat[i].scheduleDate) {
            toast.error(getTranslation("SERVICE.scheduleDate"))
            return true
          }
          if (!limitedSeat[i].startTime) {
            toast.error(getTranslation("SERVICE.startTime"))
            return true
          }
          if (!limitedSeat[i].endTime) {
            toast.error(getTranslation("SERVICE.endTime"))
            return true
          }
          if (!limitedSeat[i].totalSeat) {
            toast.error(getTranslation("SERVICE.totalSeat"))
            return true
          }
          if (limitedSeat[i].totalSeat && Number(limitedSeat[i].totalSeat) < 0) {
            toast.error(getTranslation("SERVICE.minTotalSeat"))
            return true
          }
          // if (
          //   limitedSeat[i].totalSeat &&
          //   Number(limitedSeat[i].totalSeat) > 100
          // ) {
          //   toast.error(getTranslation("SERVICE.maxTotalSeat"));
          //   return true;
          // }
        }
        let newLimitedSeat = limitedSeat.map((s) => {
          let date = moment(s.scheduleDate)
          let startTime = moment(s.startTime).set({
            year: date.get("year"),
            month: date.get("month"),
            date: date.get("date")
          })
          let endTime = moment(s.endTime).set({
            year: date.get("year"),
            month: date.get("month"),
            date: date.get("date")
          })
          return {
            ...s,
            routeName: s.routeName.trim(),
            arabicRouteName: s.arabicRouteName.trim(),
            startTime: startTime.toDate(),
            endTime: endTime.toDate()
          }
        })
        limitedSeat = newLimitedSeat
      }
      if (type === "informative") {
        if (!serviceLogo) {
          toast.error(getTranslation("SERVICE.serviceLogo"))
          return true
        }
        if (serviceBanner.length === 0) {
          toast.error(getTranslation("SERVICE.bannerRequired"))

          return true
        }
        if (!serviceImage) {
          toast.error(getTranslation("SERVICE.bannerRequired"))

          return true
        }
        for (let i = 0; i < informative.length; i++) {
          if (!informative[i].providerName.trim()) {
            toast.error(getTranslation("SERVICE.providerName"))
            return true
          }
          if (!informative[i].arabicProviderName.trim()) {
            toast.error(getTranslation("SERVICE.arabicProviderName"))
            return true
          }
          informative[i].phone = informative[i].phone || ""
          informative[i].whatsappPhone = informative[i].whatsappPhone || ""
          if (
            !parsePhoneNumber(informative[i].phone)?.nationalNumber &&
            !parsePhoneNumber(informative[i].whatsappPhone)?.nationalNumber &&
            !informative[i].url.trim() &&
            !informative[i].locationLink.trim()
          ) {
            toast.error(getTranslation("SERVICE.anyOnerequired"))
            return true
          }

          if (
            parsePhoneNumber(informative[i].phone)?.nationalNumber &&
            !isValidPhoneNumber(informative[i].phone)
          ) {
            toast.error(getTranslation("SERVICE.inValidPhone"))
            return true
          }

          if (
            parsePhoneNumber(informative[i].whatsappPhone)?.nationalNumber &&
            !isValidPhoneNumber(informative[i].whatsappPhone)
          ) {
            toast.error(getTranslation("SERVICE.InvalidWhatsappPhone"))
            return true
          }

          if (informative[i].url && !urlPatternValidation(informative[i].url)) {
            toast.error(getTranslation("SERVICE.invalidUrl"))
            return true
          }

          if (!informative[i].description.trim()) {
            toast.error(getTranslation("SERVICE.description"))
            return true
          }
          if (!informative[i].arabicDescription.trim()) {
            toast.error(getTranslation("SERVICE.arabicDescription"))
            return true
          }
          if (!informative[i].bannerImage) {
            toast.error(getTranslation("SERVICE.bannerImage"))
            return true
          }
        }
        let newInformative = informative.map((s) => {
          return {
            ...s,
            providerName: s.providerName.trim(),
            arabicProviderName: s.arabicProviderName.trim(),
            url: s.url.trim(),
            phone: parsePhoneNumber(s.phone)?.nationalNumber ? s.phone : "",
            whatsappPhone: parsePhoneNumber(s.whatsappPhone)?.nationalNumber
              ? s.whatsappPhone
              : ""
          }
        })
        informative = newInformative
        for (let i = 0; i < informative.length; i++) {
          let workHours = [...informative[i].workHours]
          for (let hours of workHours) {
            hours.start = moment(hours.start).format("HH:mm")
            hours.end = moment(hours.end).format("HH:mm")
          }

          workHoursseperate.push({
            id: informative[i].id,
            workHours: workHours
          })
          delete informative[i].workHours
        }
      }
    }
    if (type === "form") {
      if (!email.trim()) {
        toast.error(getTranslation("SERVICE.emailReq"))
        return true
      }
      if (email && !email.match(EMAIL_VALIDATION)) {
        toast.error(getTranslation("SERVICE.email"))
        return true
      }
      if (!serviceLogo) {
        toast.error(getTranslation("SERVICE.serviceLogo"))
        return true
      }
      if (form.length === 0) {
        toast.error(getTranslation("SERVICE.formDatarequire"))
        return true
      }
    }

    if (type !== "form") {
      email = ""
    }

    if (type === 'eservice' || type === 'donation') {
      if (!prefix) {
        toast.error(getTranslation("GLOBLE_MESSAGE.PrifixRequired"))
        return true
      }
      if (prefixLoading || inValidPrefix) {
        toast.error(getTranslation("GLOBLE_MESSAGE.PrifixNotValid"))
        return true
      }
    }

    dispatch(setLoaderAction(true))

    let serviceData = {
      name: name.trim(),
      workHours: workHoursseperate,
      merchantID: store().auth.login.user.merchantID,
      servingTime:
        type === "appointment" ? appointmentServingTime.value : servingTime,
      prefix: ["queue", "appointment", "eservice", "donation"].includes(type) ? prefix : "",
      // appointmentPrefix: appointmentPrefix,
      isActive: status.value === "active" ? true : false,
      maxRebookTime: maxRebookTime,
      parentCategoryId:
        service.parentCategory.value !== ""
          ? service.parentCategory.value.parentCategory
          : "",
      serviceCategoryId:
        service.parentCategory.value !== "" ? service.parentCategory.value.id : "",
      nameTrans: [
        {
          name: name.trim(),
          lang: "en",
          description: documentFeedName.trim()
        },
        {
          name: arabicName.trim(),
          lang: "ar",
          description: documentFeedArabicName.trim()
        }
      ],
      interactive: limitedSeat,
      informative: informative,
      type: type,
      serviceLogo: serviceLogo,
      isServiceOnHome,
      ticketPrefix: type === "interactive" ? prefix : "",
      textUnderTime: textUnderTime.trim(),
      arabicTextUnderTime: arabicTextUnderTime.trim(),
      textUnderPeople: textUnderPeople.trim(),
      arabicTextUnderPeople: arabicTextUnderPeople.trim(),
      form,
      serviceBanner,
      email: email.trim(),
      serviceImage,
      titleOnListEnglish,
      titleOnListArabic,
      textUnderTimeFinish: textUnderTimeFinish.trim(),
      arabicTextUnderTimeFinish: arabicTextUnderTimeFinish.trim(),
      minApptBookingDays: type === "appointment" ? (parseInt(minTimeBeforeAllowBooking)).toString() : "",
      maxApptBookingDays: type === "appointment" ? (parseInt(maxTimeToAllowBooking)).toString() : "",
      workProfileId: type === "appointment" ? workProfile.value : "",
      apptReminder1:
        type === "appointment" ? `${parseInt(level1.days)},${parseInt(level1.hours || 0)}` : "",
      apptReminder2:
        type === "appointment" ? `${parseInt(level2.days)},${parseInt(level2.hours || 0)}` : "",
      apptReminder3:
        type === "appointment" ? `${parseInt(level3.days)},${parseInt(level3.hours || 0)}` : "",
    }

    let serviceget = await api("service", serviceData, "post")

    if (serviceget.status === api_status.success) {
      //  dispatch(getServiceAction({ name: "" }))
      toast.success(getTranslation("SERVICE.create"))
      dispatch(setLoaderAction(false))
      dispatch(getQueueServiceAction())
      if (type !== 'eservice' && type !== 'donation') {
        history.push("/services")
      }
      return false
    } else {
      if (serviceget.status === api_status.unAuthorized) {
        toast.error(serviceget.message)
        dispatch(setLoaderAction(false))
        return true
      } else {
        toast.error(serviceget.message.message)
        dispatch(setLoaderAction(false))
        return true
      }
    }
  }
}

/**
 @name  getServiceAction
 @file service.js
 @description This method is used for get service
 */
export const getServiceAction = (data, isRedux = true) => {
  return async (dispatch, store) => {
    let { name, branchId } = data
    let query = `service?merchantID=${store().auth.login.user.merchantID}`
    if (store().auth.login.user.role === GlobalVariable.manager) {
      let { id } = store().auth.login.user
      query = query + `&userID=${id}`
    }
    if (name) {
      query = query + `&name=${name}`
    }
    if (branchId) {
      query = query + `&branchId=${branchId}`
    }
    if (data && data.status && data.status !== "All") {
      // status
      if (data.status === "Active") {
        query = query + `&isActive=true`
      }
      if (data.status === "Inactive") {
        query = query + `&isActive=false`
      }
    }
    if (store()?.auth?.login?.currentUser?.isDonate === true) {
      query = query + `&serviceType=donation`
    } else {
      if (data && data.type && data.type !== "All") {
        query = query + `&serviceType=${data.type}`
      }
    }
    if (data && data.lang) {
      query = query + `&lang=${data.lang}`
    }

    let getService = await api(query, {}, "get")

    if (getService.status === api_status.success) {
      if (isRedux) {
        dispatch({
          type: ADD_SERVICE,
          payload: getService.data.data
        })
      } else {
        return { success: true, data: getService.data.data }
      }
    } else {
      if (isRedux) {
        dispatch({
          type: ADD_SERVICE,
          payload: []
        })
      } else {
        return { success: false, data: [] }
      }
    }
  }
}

/**
 @name  getServiceByIdAction
 @file service.js
 @description This method is used for get service by id
 */
export const getServiceByIdAction = async (id) => {
  let getService = await api(`service/${id}`, {}, "get")

  if (getService.status === api_status.success) {
    return { success: true, data: getService.data.data }
    // toast.success('Category delete Successfully!');
  } else {
    return { success: false }
  }
}

/**
 @name  updateServiceAction
 @file service.js
 @description This method is used for update service
 */
export const updateServiceAction = (service, mainData) => {
  return async (dispatch, store) => {
    let {
      name,
      arabicName,
      servingTime,
      status,
      documentFeedName,
      documentFeedArabicName,
      prefix,
      inValidPrefix,
      prefixLoading,
      // appointmentPrefix,
      // inValidAppointmentPrefix,
      // appointmentPrefixLoading,
      maxRebookTime,
      //  parentCategory,
      limitedSeat,
      //  informative,
      type,
      //  serviceLogo,
      //  isServiceOnHome,
      //  textUnderTime,
      //  arabicTextUnderTime,
      //  textUnderPeople,
      arabicTextUnderPeople,
      deleteLogoArray,
      form,
      deleteBannerArray,
      //  serviceBanner,
      deletedWorkHour,
      //  email,
      //  serviceImage,
      //  titleOnListEnglish,
      //  titleOnListArabic,
      //  textUnderTimeFinish,
      //  arabicTextUnderTimeFinish,
      maxTimeToAllowBooking,
      minTimeBeforeAllowBooking,
      workProfile,
      level1,
      level2,
      level3,
      appointmentServingTime,
    } = service

    let workHoursseperate = []

    if (!name.trim()) {
      toast.error(getTranslation("GLOBLE_MESSAGE.nameRequired"))
      return true
    }
    if (!arabicName.trim()) {
      toast.error(getTranslation("GLOBLE_MESSAGE.arNameRequired"))
      return true
    }

    if (!status.value) {
      toast.error(getTranslation("GLOBLE_MESSAGE.statusRequired"))
      return true
    }

    // if(!documentFeedName){
    //     toast.error(getTranslation('GLOBLE_MESSAGE.feedName'));
    //     return true;
    // }

    // if(!documentFeedArabicName){
    //     toast.error(getTranslation('GLOBLE_MESSAGE.arabicFeedName'));
    //     return true;
    // }
    if (type === "queue" || type === "appointment") {
      if (type === "queue") {
        if (!servingTime) {
          toast.error(getTranslation("GLOBLE_MESSAGE.servingTimeRequired"))
          return true
        }
      }
      if (!prefix) {
        toast.error(getTranslation("GLOBLE_MESSAGE.PrifixRequired"))
        return true
      }

      if (prefixLoading || inValidPrefix) {
        toast.error(getTranslation("GLOBLE_MESSAGE.PrifixNotValid"))
        return true
      }

      // if (!appointmentPrefix) {
      //   toast.error(getTranslation("GLOBLE_MESSAGE.AppointmentPrifixRequired"));
      //   return true;
      // }

      // if (inValidAppointmentPrefix || appointmentPrefixLoading) {
      //   toast.error(getTranslation("GLOBLE_MESSAGE.AppointmentPrifixNotValid"));
      //   return true;
      // }
      if (type === "queue") {
        if (!maxRebookTime) {
          toast.error(getTranslation("GLOBLE_MESSAGE.maxRebookTimeRequired"))
          return true
        }
      }
      if (type === "appointment") {
        if (!workProfile || !workProfile.value) {
          toast.error(getTranslation("SERVICE.workProfile"))
          return true
        }
        if (!maxTimeToAllowBooking) {
          toast.error(getTranslation("SERVICE.maxTimeToAllowBooking"))
          return true
        }
        if (Number(maxTimeToAllowBooking) > 60) {
          toast.error(getTranslation("SERVICE.maxDayisNotGraterthen"))
          return true
        }
        if (!minTimeBeforeAllowBooking) {
          toast.error(getTranslation("SERVICE.minTimeBeforeAllowBooking"))
          return true
        }
        if (Number(minTimeBeforeAllowBooking) > Number(maxTimeToAllowBooking)) {
          toast.error(getTranslation("SERVICE.MinDayCheck"))
          return true
        }
        if (!level1.days) {
          toast.error(getTranslation("SERVICE.level1Days"))
          return true
        }
        if (Number(level1.days) > Number(maxTimeToAllowBooking)) {
          toast.error(getTranslation("SERVICE.level1isGrater"))
          return true
        }
        if (Number(level1.hours) > 23) {
          toast.error(getTranslation("SERVICE.level1Hours23"))
          return true
        }
        if (!level2.days) {
          toast.error(getTranslation("SERVICE.level2Days"))
          return true
        }
        if (Number(level2.days) > Number(level1.days)) {
          toast.error(getTranslation("SERVICE.level2isGrater"))
          return true
        }
        if (Number(level2.hours) > 23) {
          toast.error(getTranslation("SERVICE.level2Hours23"))
          return true
        }
        if (Number(level2.days) === Number(level1.days)) {
          if (Number(level2.hours) >= Number(level1.hours)) {
            toast.error(getTranslation("SERVICE.level2HoursCheck"))
            return true
          }
        }
        // if (!level2.hours) {
        //   toast.error(getTranslation("SERVICE.level2Hours"))
        //  return true
        // }
        if (!level3.days) {
          toast.error(getTranslation("SERVICE.level3Days"))
          return true
        }
        if (Number(level3.days) > Number(level2.days)) {
          toast.error(getTranslation("SERVICE.level3isGrater"))
          return true
        }
        if (Number(level3.hours) > 23) {
          toast.error(getTranslation("SERVICE.level3Hours23"))
          return true
        }
        if (Number(level3.days) === Number(level2.days)) {
          if (Number(level3.hours) >= Number(level2.hours)) {
            toast.error(getTranslation("SERVICE.level3HoursCheck"))
            return true
          }
        }
        // if (!level3.hours) {
        //   toast.error(getTranslation("SERVICE.level3Hours"))
        //  return true
        // }
      }
      if (
        (documentFeedName.trim() && !documentFeedArabicName.trim()) ||
        (documentFeedArabicName.trim() && !documentFeedName.trim())
      ) {
        toast.error(getTranslation("GLOBLE_MESSAGE.serviceNameRequiredBothLang"))
        return true
      }
    }

    if (type === "interactive" || type === "informative") {
      if (!service.parentCategory.value) {
        toast.error(getTranslation("SERVICE.CategoryisRequire"))
        return true
      }
      //  if (!titleOnListEnglish.trim()) {
      //   toast.error(getTranslation("SERVICE.titleOnListEnglish"))
      //   return true
      //  }
      //  if (!titleOnListArabic.trim()) {
      //   toast.error(getTranslation("SERVICE.titleOnListArabic"))
      //   return true
      //  }

      if (type === "interactive") {
        // if (!textUnderPeople.trim()) {
        //  toast.error(getTranslation("SERVICE.textUnderPeople"))
        //  return true
        // }
        if (!arabicTextUnderPeople.trim()) {
          toast.error(getTranslation("SERVICE.arabicTextUnderPeople"))
          return true
        }
        // if (!textUnderTime.trim()) {
        //  toast.error(getTranslation("SERVICE.textUnderTime"))
        //  return true
        // }
        // if (!arabicTextUnderTime.trim()) {
        //  toast.error(getTranslation("SERVICE.arabicTextUnderTime"))
        //  return true
        // }
        // if (!textUnderTimeFinish.trim()) {
        //  toast.error(getTranslation("SERVICE.textUnderTimeFinish"))
        //  return true
        // }
        // if (!arabicTextUnderTimeFinish.trim()) {
        //  toast.error(getTranslation("SERVICE.arabicTextUnderTimeFinish"))
        //  return true
        // }
        if (!prefix) {
          toast.error(getTranslation("SERVICE.ticketPrefix"))
          return true
        }
        for (let i = 0; i < limitedSeat.length; i++) {
          if (!limitedSeat[i].routeName.trim()) {
            toast.error(getTranslation("SERVICE.routeName"))
            return true
          }
          if (!limitedSeat[i].arabicRouteName.trim()) {
            toast.error(getTranslation("SERVICE.arabicRouteName"))
            return true
          }
          if (!limitedSeat[i].scheduleDate) {
            toast.error(getTranslation("SERVICE.scheduleDate"))
            return true
          }
          if (limitedSeat[i].isDateChanges) {
            if (moment().isAfter(limitedSeat[i].scheduleDate, "date")) {
              toast.error(getTranslation("SERVICE.InvalidDateMessage"))
              return true
            }
          }
          delete limitedSeat[i].isDateChanges
          if (!limitedSeat[i].startTime) {
            toast.error(getTranslation("SERVICE.startTime"))
            return true
          }
          if (!limitedSeat[i].endTime) {
            toast.error(getTranslation("SERVICE.endTime"))
            return true
          }
          if (!limitedSeat[i].totalSeat) {
            toast.error(getTranslation("SERVICE.totalSeat"))
            return true
          }
          if (limitedSeat[i].totalSeat && Number(limitedSeat[i].totalSeat) < 0) {
            toast.error(getTranslation("SERVICE.minTotalSeat"))
            return true
          }
          // if (
          //   limitedSeat[i].totalSeat &&
          //   Number(limitedSeat[i].totalSeat) > 100
          // ) {
          //   toast.error(getTranslation("SERVICE.maxTotalSeat"));
          //   return true;
          // }
        }
        let newLimitedSeat = limitedSeat.map((s) => {
          let date = moment(s.scheduleDate)
          let startTime = moment(s.startTime).set({
            year: date.get("year"),
            month: date.get("month"),
            date: date.get("date")
          })
          let endTime = moment(s.endTime).set({
            year: date.get("year"),
            month: date.get("month"),
            date: date.get("date")
          })
          return {
            ...s,
            routeName: s.routeName.trim(),
            arabicRouteName: s.arabicRouteName.trim(),
            startTime: startTime.toDate(),
            endTime: endTime.toDate()
          }
        })
        limitedSeat = newLimitedSeat
      }
      if (type === "informative") {
        // if (!serviceLogo) {
        //  toast.error(getTranslation("SERVICE.serviceLogo"))
        //  return true
        // }
        // if (serviceBanner.length === 0) {
        //  toast.error(getTranslation("SERVICE.bannerRequired"))

        //  return true
        // }
        // if (!serviceImage) {
        //  toast.error(getTranslation("SERVICE.bannerRequired"))

        //  return true
        // }
        // for (let i = 0; i < informative.length; i++) {
        //  if (!informative[i].providerName.trim()) {
        //   toast.error(getTranslation("SERVICE.providerName"))
        //   return true
        //  }
        //  if (!informative[i].arabicProviderName.trim()) {
        //   toast.error(getTranslation("SERVICE.arabicProviderName"))
        //   return true
        //  }
        //  informative[i].phone = informative[i].phone || ""
        //  informative[i].whatsappPhone = informative[i].whatsappPhone || ""
        //  if (
        //   !parsePhoneNumber(informative[i].phone)?.nationalNumber &&
        //   !parsePhoneNumber(informative[i].whatsappPhone)?.nationalNumber &&
        //   !informative[i].url.trim() &&
        //   !informative[i].locationLink.trim()
        //  ) {
        //   toast.error(getTranslation("SERVICE.anyOnerequired"))
        //   return true
        //  }
        //  if (
        //   parsePhoneNumber(informative[i].phone)?.nationalNumber &&
        //   !isValidPhoneNumber(informative[i].phone)
        //  ) {
        //   toast.error(getTranslation("SERVICE.inValidPhone"))
        //   return true
        //  }

        //  if (
        //   parsePhoneNumber(informative[i].whatsappPhone)?.nationalNumber &&
        //   !isValidPhoneNumber(informative[i].whatsappPhone)
        //  ) {
        //   toast.error(getTranslation("SERVICE.InvalidWhatsappPhone"))
        //   return true
        //  }
        //  if (informative[i].url && !urlPatternValidation(informative[i].url)) {
        //   toast.error(getTranslation("SERVICE.invalidUrl"))
        //   return true
        //  }

        //  if (!informative[i].description.trim()) {
        //   toast.error(getTranslation("SERVICE.description"))
        //   return true
        //  }
        //  if (!informative[i].arabicDescription.trim()) {
        //   toast.error(getTranslation("SERVICE.arabicDescription"))
        //   return true
        //  }
        //  if (!informative[i].bannerImage) {
        //   toast.error(getTranslation("SERVICE.bannerImage"))
        //   return true
        //  }
        // }
        // let newInformative = informative.map((s) => {
        //  return {
        //   ...s,
        //   providerName: s.providerName.trim(),
        //   arabicProviderName: s.arabicProviderName.trim(),
        //   url: s.url.trim(),
        //   phone: parsePhoneNumber(s.phone)?.nationalNumber ? s.phone : "",
        //   whatsappPhone: parsePhoneNumber(s.whatsappPhone)?.nationalNumber
        //    ? s.whatsappPhone
        //    : ""
        //  }
        // })
        // informative = newInformative
        // for (let i = 0; i < informative.length; i++) {
        //  let workHours = [...informative[i].workHours]
        //  for (let hours of workHours) {
        //   hours.start = moment(hours.start).format("HH:mm")
        //   hours.end = moment(hours.end).format("HH:mm")
        //  }

        //  workHoursseperate.push({
        //   id: informative[i].id,
        //   workHours: workHours,
        //   status: "u"
        //  })

        //  delete informative[i].workHours
        // }
      }
    }

    if (type === "form") {
      //  if (!email.trim()) {
      //   toast.error(getTranslation("SERVICE.emailReq"))
      //  }
      //  if (email && !email.match(EMAIL_VALIDATION)) {
      //   toast.error(getTranslation("SERVICE.email"))
      //   return true
      //  }
      //  if (!serviceLogo) {
      //   toast.error(getTranslation("SERVICE.serviceLogo"))
      //   return true
      //  }
      if (form.length === 0) {
        toast.error(getTranslation("SERVICE.formDatarequire"))
        return true
      }
    }

    // if (type !== "form") {
    //  email = ""
    // }

    dispatch(setLoaderAction(true))
    for (let i = 0; i < mainData.serviceName.length; i++) {
      if (mainData.serviceName[i].lang === "en") {
        mainData.serviceName[i].name = name
        mainData.serviceName[i].description = documentFeedName.trim()
      }
      if (mainData.serviceName[i].lang === "ar") {
        mainData.serviceName[i].name = arabicName
        mainData.serviceName[i].description = documentFeedArabicName.trim()
      }
    }
    let serviceData = {
      id: mainData.id,
      workHours: [...workHoursseperate, ...deletedWorkHour],
      name: name.trim(),
      merchantID: store().auth.login.user.merchantID,
      servingTime:
        type === "appointment" ? appointmentServingTime.value : servingTime,
      prefix: ["queue", "appointment", "eservice", "donation"].includes(type) ? prefix : "",
      // appointmentPrefix: appointmentPrefix,
      isActive: status.value === "active" ? true : false,
      nameTrans: mainData.serviceName,
      maxRebookTime,
      parentCategoryId:
        service.parentCategory.value !== ""
          ? service.parentCategory.value.parentCategory
          : "",
      serviceCategoryId:
        service.parentCategory.value !== "" ? service.parentCategory.value.id : "",
      interactive: limitedSeat,
      //  informative: informative,
      type: type,
      //  serviceLogo: serviceLogo,
      //  isServiceOnHome,
      ticketPrefix: type === "interactive" ? prefix : "",
      //  textUnderTime: textUnderTime.trim(),
      //  arabicTextUnderTime: arabicTextUnderTime.trim(),
      //  textUnderPeople: textUnderPeople.trim(),
      //  arabicTextUnderPeople: arabicTextUnderPeople.trim(),
      form,
      //  serviceBanner,
      //  email: email.trim(),
      //  serviceImage,
      //  titleOnListEnglish,
      //  titleOnListArabic,
      //  textUnderTimeFinish: textUnderTimeFinish.trim(),
      //  arabicTextUnderTimeFinish: arabicTextUnderTimeFinish.trim(),
      minApptBookingDays: type === "appointment" ? (parseInt(minTimeBeforeAllowBooking)).toString() : "",
      maxApptBookingDays: type === "appointment" ? (parseInt(maxTimeToAllowBooking)).toString() : "",
      workProfileId: type === "appointment" ? workProfile.value : "",
      apptReminder1:
        type === "appointment" ? `${parseInt(level1.days)},${parseInt(level1.hours || 0)}` : "",
      apptReminder2:
        type === "appointment" ? `${parseInt(level2.days)},${parseInt(level2.hours || 0)}` : "",
      apptReminder3:
        type === "appointment" ? `${parseInt(level3.days)},${parseInt(level3.hours || 0)}` : "",
    }

    let serviceget = await api("service", serviceData, "put")
    if (serviceget.status === api_status.success) {
      if (deleteLogoArray?.length > 0 || deleteBannerArray?.length > 0) {
        await imageDelete([...deleteLogoArray, ...deleteBannerArray])
      }
      let data = {
        serviceId: serviceget.data.data.id,
        serviceName: serviceget.data.data.name,
        type: type,
        arServiceName: type === "appointment" ? serviceget.data.data.arName : "",
        apptReminder1:
          type === "appointment" ? `${parseInt(level1.days)},${parseInt(level1.hours || 0)}` : "",
        apptReminder2:
          type === "appointment" ? `${parseInt(level2.days)},${parseInt(level2.hours || 0)}` : "",
        apptReminder3:
          type === "appointment" ? `${parseInt(level3.days)},${parseInt(level3.hours || 0)}` : ""
      }
      let updateServiceName = await QueueApi("ticket/service", data, "post")
      if (updateServiceName.status === api_status.success) {
        dispatch(getQueueAction({ name: "" }))
      }
      //  dispatch(getServiceAction({ name: "" }))
      toast.success(getTranslation("SERVICE.update"))
      dispatch(setLoaderAction(false))
      dispatch(getQueueServiceAction())
      history.push("/services")
    } else {
      if (serviceget.status === api_status.unAuthorized) {
        toast.error(serviceget.message)
        dispatch(setLoaderAction(false))
        return true
      } else {
        toast.error(serviceget.message.message)
        dispatch(setLoaderAction(false))
        return true
      }
    }
  }
}

/**
 @name  deleteServiceAction
 @file service.js
 @description This method is used for delete service
 */
export const deleteServiceAction = (id) => {
  return async (dispatch, store) => {
    dispatch(setLoaderAction(true))
    let deleteMerchant = await api(`service?id=${id}`, {}, "delete")

    if (deleteMerchant.status === api_status.success) {
      let serviceDelete = {
        serviceId: id
      }
      await QueueApi(`delete/service`, serviceDelete, "post")
      await dispatch(getQueueServiceAction())
      //  dispatch(getServiceAction({ name: "" }))
      dispatch(setLoaderAction(false))
      toast.success(getTranslation("SERVICE.delete"))
      return { success: true }
    } else {
      if (deleteMerchant.status === api_status.unAuthorized || deleteMerchant.status === api_status.error) {
        toast.error(deleteMerchant.message)
        dispatch(setLoaderAction(false))
      } else {
        dispatch(setLoaderAction(false))
      }
    }
  }
}

/**
 @name  checkServicePrifixAction
 @file service.js
 @description This method is used for check service prefix
 */
export const checkServicePrifixAction = async (data) => {
  data["merchantID"] = store.getState().auth.login.user.merchantID

  let checkPrifix = await api(`service/checkPrifix`, data, "post")

  if (checkPrifix.status === api_status.success) {
    return { success: true, data: checkPrifix.data.data }
    // toast.success('Category delete Successfully!');
  } else {
    return { success: false }
  }
}

/**
 @name  checkServiceAppointmentPrifixAction
 @file service.js
 @description This method is used for check appointment prefix
 */
export const checkServiceAppointmentPrifixAction = async (data) => {
  data["merchantID"] = store.getState().auth.login.user.merchantID

  let checkPrifix = await api(`service/checkAppointmentPrefix`, data, "post")

  if (checkPrifix.status === api_status.success) {
    return { success: true, data: checkPrifix.data.data }
    // toast.success('Category delete Successfully!');
  } else {
    return { success: false }
  }
}

/**
 @name  getQueueServiceAction
 @file service.js
 @description This method is used for get queue service
 */
export const getQueueServiceAction = () => {
  return async (dispatch, store) => {
    let query = `service/mercahntGetunUseService?merchantID=${store().auth.login.user.merchantID
      }`

    let getService = await api(query, {}, "get")

    if (getService.status === api_status.success) {
      dispatch({
        type: GET_QUEUE_SERVICE,
        payload: getService.data.data
      })
    }
  }
}
/**
 * @name getBranchesServiceAction
 * @file service.js
 * @param merchantID, branchId
 * @description This method is for get services of perticular branch , maainy for Manager multi branch service  .
 */
export const getBranchServiceAction = (type) => {
  return async (dispatch, store) => {
    let query = `service/user?merchantID=${store().auth.login.user.merchantID}`
    if (store().auth.login.user.role === GlobalVariable.manager) {
      let { branchId, id } = store().auth.login.user
      query = query + `&branchId=${branchId}&userID=${id}`
      if (type) {
        query = query + `&serviceType=${type}`
      }
    }
    let getService = await api(query, {}, "get")
    if (getService.status === api_status.success) {
      let service = [
        ...new Map(
          getService.data.data.service.map((item) => [item["id"], item])
        ).values()
      ]
      dispatch({
        type: ADD_MANAGER_SERVICE,
        payload: service
      })
    }
  }
}

/**
 @name  getServiceCategory
 @file service.js
 @description This method is used for get all Category
 */
export const getServiceCategory = async (type) => {
  let getAllCategory = await api(`get-service-cat?type=${type}`, {}, "get")

  if (getAllCategory.status === api_status.success) {
    return { success: true, data: getAllCategory.data.data }
  } else {
    return { success: false }
  }
}

/**
 @name  imageUploadWithFormdata
 @file merchant.js
 @description this method is used for image upload with formdata
 */
export const imageUploadWithFormdata = async (type, file) => {
  // debugger
  if (!file?.name) {
    return false
  }
  const formdata = new FormData()
  formdata.append("type", type)
  formdata.append(type, file)
  let imageUrl = await api("upload", formdata, "postWithFormdata")
  if (imageUrl.status === api_status.success) {
    if (Array.isArray(imageUrl?.data?.data)) {
      return imageUrl?.data?.data[0]?.url
    } else {
      return imageUrl?.data?.data
    }
  } else {
    return false
  }
}

/**
 @name  imageDelete
 @file merchant.js
 @description this method is used for delete
 */
export const imageDelete = async (deletedImages) => {
  let data = {
    link: deletedImages
  }
  await api("image/delete", data, "post")
}

/**
 @name  getServiceListAction
 @file service.js
 @description This method is used for get service by id
 */
export const getServiceListAction = async () => {
  let loginId = encryptStorage.getItem(GlobalVariable.loginId)
  let getService = await api(`service/list?merchantID=${loginId}`, {}, "get")

  if (getService.status === api_status.success) {
    return { success: true, data: getService.data.data }
  } else {
    return { success: false }
  }
}

/**
 @name  getFormServiceAction
 @file service.js
 @description This method is used for get form unique service
 */
export const getFormServiceAction = async (type) => {
  let loginId = encryptStorage.getItem(GlobalVariable.loginId)
  let getService = await api(
    `remaning/formService?merchantID=${loginId}&type=${type}`,
    {},
    "get"
  )

  if (getService.status === api_status.success) {
    return { success: true, data: getService.data.data }
  } else {
    return { success: false }
  }
}

/**
 @name  getServiceTypeDropdown
 @file service.js
 @description This method is used for get service type that given from admin panel
 */
export const getServiceTypeDropdown = () => {
  return async (dispatch, store) => {
    let isAppointment = store().auth.login.currentUser.isAppointment;
    let isEservice = store().auth.login.currentUser.isEservice;
    let isEvent = store().auth.login.currentUser.isEvent;
    let isDonate = store().auth.login.currentUser.isDonate;
    let value = [
      { value: "queue", label: getTranslation("SERVICE.Queue") },
    ]
    if (isAppointment) {
      value = [
        ...value,
        { value: "appointment", label: getTranslation("BRANCH.Appointment") },
      ]
    }
    if (isEservice) {
      value = [
        ...value,
        { value: "eservice", label: getTranslation("FORMS.e-Service") },
      ]
    }
    if (isDonate) {
      value = [
        ...value,
        { value: "donation", label: getTranslation("SERVICE.Donation") },
      ]
    }
    // if(isEvent){
    //   value = [
    //     ...value,
    //     { value: "interactive", label: "Interactive(Limited seat)" },
    //     { value: "informative", label: "Informative" },
    //     { value: "form", label: "Form Service" },
    //   ]
    // }
    if (value.length === 1) {
      value = [];
    }
    dispatch({
      type: SERVICE_TYPE_DROPDOWN,
      payload: value
    })
  }
}
