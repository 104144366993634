import {
    SET_LOADER,
    SET_BREDCUME,  
    SET_DASHBOARD_LOADER
} from '../types';

export const setLoaderAction = value => {
    return {
        type: SET_LOADER,
        payload: value,
    };
};

export const setBredcumeAction = value => {
    return {
        type: SET_BREDCUME,
        payload: value,
    };
};

export const setDashboardLoaderAction = value => {
    return {
        type: SET_DASHBOARD_LOADER,
        payload: value,
    };
};