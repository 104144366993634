import React from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import { useSelector } from "react-redux";
import { GlobalVariable } from "../../util";
const Breadcum = (props) => {
  const breadcums = props.breadcums;
  const lang = useSelector((state) => state.auth.login.lang);
  const listItems = breadcums.map((breadcum, index) => (
    <li className="bradcums-lists" key={index}>
      {!breadcum.lastName ? (
        <Link to={breadcum.Link}>{breadcum.tittle}</Link>
      ) : (
        <a style={{ color: "#000000" }}>{breadcum.tittle} </a>
      )}

      <div className="arrow-svgs">
        <span className="breadcrumb-separators flex items-start">
          <span className="feather-icon select-none relative">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="menu-toggle-icon"><polyline points="9 18 15 12 9 6"></polyline></svg> */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevrons-right w-4 h-4">
              <polyline points="13 17 18 12 13 7"></polyline>
              <polyline points="6 17 11 12 6 7"></polyline>
            </svg> */}
            {lang === GlobalVariable.arabic ?
              <Icon.ChevronsLeft size={24} />
              :
              <Icon.ChevronsRight size={24} />
            }
          </span>
        </span>
      </div>
    </li>
  ));

  return <ul className="bradcums-list-block">{listItems}</ul>;
};

export default Breadcum;
