import { ADD_SERVICE, ADD_MANAGER_SERVICE, SERVICE_TYPE_DROPDOWN } from "../../actions/types";

const initState = {
  service: {
    service: [],
    pageNo: 0,
  },
  managerService: [],
  serviceType:[]
};

const service = (state = initState, action) => {
  switch (action.type) {
    case ADD_SERVICE: {
      return { ...state, service: action.payload };
    }
    case ADD_MANAGER_SERVICE: {
      return { ...state, managerService: action.payload };
    }

    case SERVICE_TYPE_DROPDOWN: {
      return {...state,serviceType:action.payload}
    }
    default: {
      return { ...state };
    }
  }
};

export default service;
