import { store } from "../redux/storeConfig/store";
import axios from "axios";
import {
  setAuthTokenAction,
  setIsLoginAction,
  setAuthRefreshTokenAction,
  setLoginUserAction,
  checkTokenRefresh,
  // errorDetection,
} from "../redux/actions/auth/index";
import { apiErrorHandling, GlobalVariable } from "../util/index";
import { setLoaderAction } from "../redux/actions/loader/index";
import { history } from "../history";
import i18n from "../i18n/i18n";

const main_url = GlobalVariable.apiUrl.notificationUrl;
const api_status = GlobalVariable.apiResponseStatus;

export const NotificationApi = async (endpoint, data, type) => {
  await checkTokenRefresh();
  let res = {};
  res = await callApi(endpoint, data, type);

  if (res.status === api_status.unAuthorized) {
    await unAuthorizeFunction(res, endpoint, data, type);
    res = await callApi(endpoint, data, type);
  } else {
    await checkResponse(res, endpoint, data, type);
  }

  return res;
};

export const checkResponse = async (data, endpoint, bodyData, apiType) => {
  if (data.status === api_status.serverError) {
    // navigate('ServerError');
    return true;
  }

  return true;
};

export const unAuthorizeFunction = async (
  data,
  endpoint,
  bodyData,
  apiType
) => {
  if (data.status === api_status.unAuthorized) {
    try {
      store.dispatch(setIsLoginAction(false));
      store.dispatch(setLoginUserAction({}));
      store.dispatch(setAuthTokenAction({}));
      store.dispatch(setAuthRefreshTokenAction({}));
      sessionStorage.remove(GlobalVariable.localStoreName);
      store.dispatch(setLoaderAction(false));
      history.push("/login");
      return true;
    } catch (e) {
      store.dispatch(setLoaderAction(false));
      history.push("/login");
    }
  }
};

export const callApi = async (endpoint, data, type) => {
  let res = {};

  let token = await store.getState().auth.login.token;

  let url = main_url + endpoint;

  let headers = {
    "Content-Type": "application/json",
    "x-auth": token,
    "lang": i18n.language,
  };

  switch (type) {
    case "post":
      try {
        await axios
          .post(url, data, { headers: headers })
          .then(function (response) {
            res = response;
          })
          .catch((err) => {
            res = apiErrorHandling(err, "post");
          });
      } catch (e) {}
      break;
    case "get":
      await axios
        .get(url, { headers: headers })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "get");
        });

      break;
    case "put":
      await axios
        .put(url, data, { headers: headers })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "put");
        });

      break;
    case "patch":
      await axios
        .patch(url, data, { headers: headers })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "patch");
        });

      break;
    case "delete":
      await axios
        .delete(url, { headers: headers })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "delete");
        });
      break;
    default:
      return true;
  }

  return res;
};
