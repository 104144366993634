import { SET_LOADER,SET_BREDCUME, SET_DASHBOARD_LOADER } from '../../actions/types';
  
const initState = {
    loader: false,
    breducume: [],
    dashboardLoader: false
};

const loader = (state = initState, action) => {
switch (action.type) {
    
    case SET_LOADER: {
        return { ...state, loader: action.payload }
    }  
    case SET_BREDCUME: {
        return {...state,breducume: action.payload}
    }
    case SET_DASHBOARD_LOADER: {
        return { ...state, dashboardLoader: action.payload }
    }  
    default: {
        return {...state}
    }
}
}
  
export default loader;
  