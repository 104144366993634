import { ADD_CATEGORY, SET_CATEGORY_TYPE } from "../../actions/types";

const initState = {
  category: [],
  pageNo: 0,
  categoryType:[]
};

const category = (state = initState, action) => {
  switch (action.type) {
    case ADD_CATEGORY: {
      return { ...state, category: action.payload };
    }
    case SET_CATEGORY_TYPE: {
      return { ...state, categoryType: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default category;
