import {    
    ADD_USER
  } from '../../actions/types';
  
  const initState = {
    user: {
      user: [],
      pageNo: 0
    },
    
  };
  
  const user = (state = initState, action) => {
    switch (action.type) {
     
      case ADD_USER: {
        return { ...state, user: action.payload }
      }    
      default: {
        return {...state}
      }
    }
  }
  
  export default user;
  