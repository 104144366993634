import { SET_SETTING, SET_ABUSE } from "../../actions/types";

const initState = {
  setting: {},
  abuse: [],
};

const setting = (state = initState, action) => {
  switch (action.type) {
    case SET_SETTING: {
      return { ...state, setting: action.payload };
    }
    case SET_ABUSE: {
      return { ...state, abuse: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default setting;
