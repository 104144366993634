export const TimeZone = [
    { value: "Etc/GMT+12", label: "Etc/GMT+12" },
    { value: "Etc/GMT+11", label: "Etc/GMT+11" },
    { value: "Pacific/Midway", label: "Pacific/Midway" },
    { value: "Pacific/Niue", label: "Pacific/Niue" },
    { value: "Pacific/Pago_Pago", label: "Pacific/Pago_Pago" },
    { value: "Etc/GMT+10", label: "Etc/GMT+10" },
    { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
    { value: "Pacific/Johnston", label: "Pacific/Johnston" },
    { value: "Pacific/Rarotonga", label: "Pacific/Rarotonga" },
    { value: "Pacific/Tahiti", label: "Pacific/Tahiti" },
    { value: "America/Anchorage", label: "America/Anchorage" },
    { value: "America/Juneau", label: "America/Juneau" },
    { value: "America/Nome", label: "America/Nome" },
    { value: "America/Sitka", label: "America/Sitka" },
    { value: "America/Yakutat", label: "America/Yakutat" },
    { value: "America/Santa_Isabel", label: "America/Santa_Isabel" },
    { value: "America/Dawson", label: "America/Dawson" },
    { value: "America/Los_Angeles", label: "America/Los_Angeles" },
    { value: "America/Tijuana", label: "America/Tijuana" },
    { value: "America/Vancouver", label: "America/Vancouver" },
    { value: "America/Whitehorse", label: "America/Whitehorse" },
    { value: "America/Dawson", label: "America/Dawson" },
    { value: "America/Los_Angeles", label: "America/Los_Angeles" },
    { value: "America/Tijuana", label: "America/Tijuana" },
    { value: "America/Vancouver", label: "America/Vancouver" },
    { value: "America/Whitehorse", label: "America/Whitehorse" },
    { value: "PST8PDT", label: "PST8PDT" },
    { value: "America/Creston", label: "America/Creston" },
    { value: "America/Dawson_Creek", label: "America/Dawson_Creek" },
    { value: "America/Hermosillo", label: "America/Hermosillo" },
    { value: "America/Phoenix", label: "America/Phoenix" },
    { value: "Etc/GMT+7", label: "Etc/GMT+7" },
    { value: "America/Chihuahua", label: "America/Chihuahua" },
    { value: "America/Mazatlan", label: "America/Mazatlan" },
    { value: "America/Boise", label: "America/Boise" },
    { value: "America/Cambridge_Bay", label: "America/Cambridge_Bay" },
    { value: "America/Denver", label: "America/Denver" },
    { value: "America/Edmonton", label: "America/Edmonton" },
    { value: "America/Inuvik", label: "America/Inuvik" },
    { value: "America/Ojinaga", label: "America/Ojinaga" },
    { value: "America/Yellowknife", label: "America/Yellowknife" },
    { value: "MST7MDT", label: "MST7MDT" },
    { value: "America/Belize", label: "America/Belize" },
    { value: "America/Costa_Rica", label: "America/Costa_Rica" },
    { value: "America/El_Salvador", label: "America/El_Salvador" },
    { value: "America/Guatemala", label: "America/Guatemala" },
    { value: "America/Managua", label: "America/Managua" },
    { value: "America/Tegucigalpa", label: "America/Tegucigalpa" },
    { value: "Etc/GMT+6", label: "Etc/GMT+6" },
    { value: "Pacific/Galapagos", label: "Pacific/Galapagos" },
    { value: "America/Chicago", label: "America/Chicago" },
    { value: "America/Indiana/Knox", label: "America/Indiana/Knox" },
    { value: "America/Indiana/Tell_City", label: "America/Indiana/Tell_City" },
    { value: "America/Matamoros", label: "America/Matamoros" },
    { value: "America/Menominee", label: "America/Menominee" },
    { value: "America/North_Dakota/Beulah", label: "America/North_Dakota/Beulah" },
    { value: "America/North_Dakota/Center", label: "America/North_Dakota/Center" },
    { value: "America/North_Dakota/New_Salem", label: "America/North_Dakota/New_Salem" },
    { value: "America/Rainy_River", label: "America/Rainy_River" },
    { value: "America/Rankin_Inlet", label: "America/Rankin_Inlet" },
    { value: "America/Resolute", label: "America/Resolute" },
    { value: "America/Winnipeg", label: "America/Winnipeg" },
    { value: "CST6CDT", label: "CST6CDT" },
    { value: "America/Bahia_Banderas", label: "America/Bahia_Banderas" },
    { value: "America/Cancun", label: "America/Cancun" },
    { value: "America/Merida", label: "America/Merida" },
    { value: "America/Mexico_City", label: "America/Mexico_City" },
    { value: "America/Monterrey", label: "America/Monterrey" },
    { value: "America/Regina", label: "America/Regina" },
    { value: "America/Swift_Current", label: "America/Swift_Current" },
    { value: "America/Bogota", label: "America/Bogota" },
    { value: "America/Cayman", label: "America/Cayman" },
    { value: "America/Coral_Harbour", label: "America/Coral_Harbour" },
    { value: "America/Eirunepe", label: "America/Eirunepe" },
    { value: "America/Guayaquil", label: "America/Guayaquil" },
    { value: "America/Jamaica", label: "America/Jamaica" },
    { value: "America/Lima", label: "America/Lima" },
    { value: "America/Panama", label: "America/Panama" },
    { value: "America/Rio_Branco", label: "America/Rio_Branco" },
    { value: "Etc/GMT+5", label: "Etc/GMT+5" },
    { value: "America/Detroit", label: "America/Detroit" },
    { value: "America/Havana", label: "America/Havana" },
    { value: "America/Indiana/Petersburg", label: "America/Indiana/Petersburg" },
    { value: "America/Indiana/Vincennes", label: "America/Indiana/Vincennes" },
    { value: "America/Indiana/Winamac", label: "America/Indiana/Winamac" },
    { value: "America/Iqaluit", label: "America/Iqaluit" },
    { value: "America/Kentucky/Monticello", label: "America/Kentucky/Monticello" },
    { value: "America/Louisville", label: "America/Louisville" },
    { value: "America/Montreal", label: "America/Montreal" },
    { value: "America/Nassau", label: "America/Nassau" },
    { value: "America/New_York", label: "America/New_York" },
    { value: "America/Nipigon", label: "America/Nipigon" },
    { value: "America/Pangnirtung", label: "America/Pangnirtung" },
    { value: "America/Port-au-Prince", label: "America/Port-au-Prince" },
    { value: "America/Thunder_Bay", label: "America/Thunder_Bay" },
    { value: "America/Toronto", label: "America/Toronto" },
    { value: "EST5EDT", label: "EST5EDT" },
    { value: "America/Indiana/Marengo", label: "America/Indiana/Marengo" },
    { value: "America/Indiana/Vevay", label: "America/Indiana/Vevay" },
    { value: "America/Indianapolis", label: "America/Indianapolis" },
    { value: "America/Caracas", label: "America/Caracas" },
    { value: "America/Asuncion", label: "America/Asuncion" },
    { value: "America/Glace_Bay", label: "America/Glace_Bay" },
    { value: "America/Goose_Bay", label: "America/Goose_Bay" },
    { value: "America/Halifax", label: "America/Halifax" },
    { value: "America/Moncton", label: "America/Moncton" },
    { value: "America/Thule", label: "America/Thule" },
    { value: "Atlantic/Bermuda", label: "Atlantic/Bermuda" },
    { value: "America/Campo_Grande", label: "America/Campo_Grande" },
    { value: "America/Cuiaba", label: "America/Cuiaba" },
    { value: "America/Anguilla", label: "America/Anguilla" },
    { value: "America/Antigua", label: "America/Antigua" },
    { value: "America/Aruba", label: "America/Aruba" },
    { value: "America/Barbados", label: "America/Barbados" },
    { value: "America/Blanc-Sablon", label: "America/Blanc-Sablon" },
    { value: "America/Boa_Vista", label: "America/Boa_Vista" },
    { value: "America/Curacao", label: "America/Curacao" },
    { value: "America/Dominica", label: "America/Dominica" },
    { value: "America/Grand_Turk", label: "America/Grand_Turk" },
    { value: "America/Grenada", label: "America/Grenada" },
    { value: "America/Guadeloupe", label: "America/Guadeloupe" },
    { value: "America/Guyana", label: "America/Guyana" },
    { value: "America/Kralendijk", label: "America/Kralendijk" },
    { value: "America/La_Paz", label: "America/La_Paz" },
    { value: "America/Lower_Princes", label: "America/Lower_Princes" },
    { value: "America/Manaus", label: "America/Manaus" },
    { value: "America/Marigot", label: "America/Marigot" },
    { value: "America/Martinique", label: "America/Martinique" },
    { value: "America/Montserrat", label: "America/Montserrat" },
    { value: "America/Port_of_Spain", label: "America/Port_of_Spain" },
    { value: "America/Porto_Velho", label: "America/Porto_Velho" },
    { value: "America/Puerto_Rico", label: "America/Puerto_Rico" },
    { value: "America/Santo_Domingo", label: "America/Santo_Domingo" },
    { value: "America/St_Barthelemy", label: "America/St_Barthelemy" },
    { value: "America/St_Kitts", label: "America/St_Kitts" },
    { value: "America/St_Lucia", label: "America/St_Lucia" },
    { value: "America/St_Thomas", label: "America/St_Thomas" },
    { value: "America/St_Vincent", label: "America/St_Vincent" },
    { value: "America/Tortola", label: "America/Tortola" },
    { value: "Etc/GMT+4", label: "Etc/GMT+4" },
    { value: "America/Santiago", label: "America/Santiago" },
    { value: "Antarctica/Palmer", label: "Antarctica/Palmer" },
    { value: "America/St_Johns", label: "America/St_Johns" },
    { value: "America/Sao_Paulo", label: "America/Sao_Paulo" },
    { value: "America/Argentina/La_Rioja", label: "America/Argentina/La_Rioja" },
    { value: "America/Argentina/Rio_Gallegos", label: "America/Argentina/Rio_Gallegos" },
    { value: "America/Argentina/Salta", label: "America/Argentina/Salta" },
    { value: "America/Argentina/San_Juan", label: "America/Argentina/San_Juan" },
    { value: "America/Argentina/San_Luis", label: "America/Argentina/San_Luis" },
    { value: "America/Argentina/Tucuman", label: "America/Argentina/Tucuman" },
    { value: "America/Argentina/Ushuaia", label: "America/Argentina/Ushuaia" },
    { value: "America/Buenos_Aires", label: "America/Buenos_Aires" },
    { value: "America/Catamarca", label: "America/Catamarca" },
    { value: "America/Cordoba", label: "America/Cordoba" },
    { value: "America/Jujuy", label: "America/Jujuy" },
    { value: "America/Mendoza", label: "America/Mendoza" },
    { value: "America/Araguaina", label: "America/Araguaina" },
    { value: "America/Belem", label: "America/Belem" },
    { value: "America/Cayenne", label: "America/Cayenne" },
    { value: "America/Fortaleza", label: "America/Fortaleza" },
    { value: "America/Maceio", label: "America/Maceio" },
    { value: "America/Paramaribo", label: "America/Paramaribo" },
    { value: "America/Recife", label: "America/Recife" },
    { value: "America/Santarem", label: "America/Santarem" },
    { value: "Antarctica/Rothera", label: "Antarctica/Rothera" },
    { value: "Atlantic/Stanley", label: "Atlantic/Stanley" },
    { value: "Etc/GMT+3", label: "Etc/GMT+3" },
    { value: "America/Godthab", label: "America/Godthab" },
    { value: "America/Montevideo", label: "America/Montevideo" },
    { value: "America/Bahia", label: "America/Bahia" },
    { value: "America/Noronha", label: "America/Noronha" },
    { value: "Atlantic/South_Georgia", label: "Atlantic/South_Georgia" },
    { value: "Etc/GMT+2", label: "Etc/GMT+2", },
    { value: "America/Scoresbysund", label: "America/Scoresbysund" },
    { value: "Atlantic/Azores", label: "Atlantic/Azores" },
    { value: "Atlantic/Cape_Verde", label: "Atlantic/Cape_Verde" },
    { value: "Etc/GMT+1", label: "Etc/GMT+1" },
    { value: "Africa/Casablanca", label: "Africa/Casablanca" },
    { value: "Africa/El_Aaiun", label: "Africa/El_Aaiun" },
    { value: "America/Danmarkshavn", label: "America/Danmarkshavn" },
    { value: "Etc/GMT", label: "Etc/GMT" },
    { value: "Europe/Isle_of_Man", label: "Europe/Isle_of_Man" },
    { value: "Europe/Guernsey", label: "Europe/Guernsey" },
    { value: "Europe/Jersey", label: "Europe/Jersey" },
    { value: "Europe/London", label: "Europe/London" },
    { value: "Europe/Isle_of_Man", label: "Europe/Isle_of_Man" },
    { value: "Europe/Guernsey", label: "Europe/Guernsey" },
    { value: "Europe/Jersey", label: "Europe/Jersey" },
    { value: "Europe/London", label: "Europe/London" },
    { value: "Atlantic/Canary", label: "Atlantic/Canary" },
    { value: "Atlantic/Faeroe", label: "Atlantic/Faeroe" },
    { value: "Atlantic/Madeira", label: "Atlantic/Madeira" },
    { value: "Europe/Dublin", label: "Europe/Dublin" },
    { value: "Europe/Lisbon", label: "Europe/Lisbon" },
    { value: "Africa/Abidjan", label: "Africa/Abidjan" },
    { value: "Africa/Accra", label: "Africa/Accra" },
    { value: "Africa/Bamako", label: "Africa/Bamako" },
    { value: "Africa/Banjul", label: "Africa/Banjul" },
    { value: "Africa/Bissau", label: "Africa/Bissau" },
    { value: "Africa/Conakry", label: "Africa/Conakry" },
    { value: "Africa/Dakar", label: "Africa/Dakar" },
    { value: "Africa/Freetown", label: "Africa/Freetown" },
    { value: "Africa/Lome", label: "Africa/Lome" },
    { value: "Africa/Monrovia", label: "Africa/Monrovia" },
    { value: "Africa/Nouakchott", label: "Africa/Nouakchott" },
    { value: "Africa/Ouagadougou", label: "Africa/Ouagadougou" },
    { value: "Africa/Sao_Tome", label: "Africa/Sao_Tome" },
    { value: "Atlantic/Reykjavik", label: "Atlantic/Reykjavik" },
    { value: "Atlantic/St_Helena", label: "Atlantic/St_Helena" },
    { value: "Arctic/Longyearbyen", label: "Arctic/Longyearbyen" },
    { value: "Europe/Amsterdam", label: "Europe/Amsterdam" },
    { value: "Europe/Andorra", label: "Europe/Andorra" },
    { value: "Europe/Berlin", label: "Europe/Berlin" },
    { value: "Europe/Busingen", label: "Europe/Busingen" },
    { value: "Europe/Gibraltar", label: "Europe/Gibraltar" },
    { value: "Europe/Luxembourg", label: "Europe/Luxembourg" },
    { value: "Europe/Malta", label: "Europe/Malta" },
    { value: "Europe/Monaco", label: "Europe/Monaco" },
    { value: "Europe/Oslo", label: "Europe/Oslo" },
    { value: "Europe/Rome", label: "Europe/Rome" },
    { value: "Europe/San_Marino", label: "Europe/San_Marino" },
    { value: "Europe/Stockholm", label: "Europe/Stockholm" },
    { value: "Europe/Vaduz", label: "Europe/Vaduz" },
    { value: "Europe/Vatican", label: "Europe/Vatican" },
    { value: "Europe/Vienna", label: "Europe/Vienna" },
    { value: "Europe/Zurich", label: "Europe/Zurich" },
    { value: "Europe/Belgrade", label: "Europe/Belgrade" },
    { value: "Europe/Bratislava", label: "Europe/Bratislava" },
    { value: "Europe/Budapest", label: "Europe/Budapest" },
    { value: "Europe/Ljubljana", label: "Europe/Ljubljana" },
    { value: "Europe/Podgorica", label: "Europe/Podgorica" },
    { value: "Europe/Prague", label: "Europe/Prague" },
    { value: "Europe/Tirane", label: "Europe/Tirane" },
    { value: "Africa/Ceuta", label: "Africa/Ceuta" },
    { value: "Europe/Brussels", label: "Europe/Brussels" },
    { value: "Europe/Copenhagen", label: "Europe/Copenhagen" },
    { value: "Europe/Madrid", label: "Europe/Madrid" },
    { value: "Europe/Paris", label: "Europe/Paris" },
    { value: "Europe/Sarajevo", label: "Europe/Sarajevo" },
    { value: "Europe/Skopje", label: "Europe/Skopje" },
    { value: "Europe/Warsaw", label: "Europe/Warsaw" },
    { value: "Europe/Zagreb", label: "Europe/Zagreb" },
    { value: "Africa/Algiers", label: "Africa/Algiers" },
    { value: "Africa/Bangui", label: "Africa/Bangui" },
    { value: "Africa/Brazzaville", label: "Africa/Brazzaville" },
    { value: "Africa/Douala", label: "Africa/Douala" },
    { value: "Africa/Kinshasa", label: "Africa/Kinshasa" },
    { value: "Africa/Lagos", label: "Africa/Lagos" },
    { value: "Africa/Libreville", label: "Africa/Libreville" },
    { value: "Africa/Luanda", label: "Africa/Luanda" },
    { value: "Africa/Malabo", label: "Africa/Malabo" },
    { value: "Africa/Ndjamena", label: "Africa/Ndjamena" },
    { value: "Africa/Niamey", label: "Africa/Niamey" },
    { value: "Africa/Porto-Novo", label: "Africa/Porto-Novo" },
    { value: "Africa/Tunis", label: "Africa/Tunis" },
    { value: "Etc/GMT-1", label: "Etc/GMT-1" },
    { value: "Africa/Windhoek", label: "Africa/Windhoek" },
    { value: "Asia/Nicosia", label: "Asia/Nicosia" },
    { value: "Europe/Athens", label: "Europe/Athens" },
    { value: "Europe/Bucharest", label: "Europe/Bucharest" },
    { value: "Europe/Chisinau", label: "Europe/Chisinau" },
    { value: "Asia/Beirut", label: "Asia/Beirut" },
    { value: "Africa/Cairo", label: "Africa/Cairo" },
    { value: "Asia/Damascus", label: "Asia/Damascus" },
    { value: "Asia/Nicosia", label: "Asia/Nicosia" },
    { value: "Europe/Athens", label: "Europe/Athens" },
    { value: "Europe/Bucharest", label: "Europe/Bucharest" },
    { value: "Europe/Chisinau", label: "Europe/Chisinau" },
    { value: "Europe/Helsinki", label: "Europe/Helsinki" },
    { value: "Europe/Kiev", label: "Europe/Kiev" },
    { value: "Europe/Mariehamn", label: "Europe/Mariehamn" },
    { value: "Europe/Nicosia", label: "Europe/Nicosia" },
    { value: "Europe/Riga", label: "Europe/Riga" },
    { value: "Europe/Sofia", label: "Europe/Sofia" },
    { value: "Europe/Tallinn", label: "Europe/Tallinn" },
    { value: "Europe/Uzhgorod", label: "Europe/Uzhgorod" },
    { value: "Europe/Vilnius", label: "Europe/Vilnius" },
    { value: "Europe/Zaporozhye", label: "Europe/Zaporozhye" },
    { value: "Africa/Blantyre", label: "Africa/Blantyre" },
    { value: "Africa/Bujumbura", label: "Africa/Bujumbura" },
    { value: "Africa/Gaborone", label: "Africa/Gaborone" },
    { value: "Africa/Harare", label: "Africa/Harare" },
    { value: "Africa/Johannesburg", label: "Africa/Johannesburg" },
    { value: "Africa/Kigali", label: "Africa/Kigali" },
    { value: "Africa/Lubumbashi", label: "Africa/Lubumbashi" },
    { value: "Africa/Lusaka", label: "Africa/Lusaka" },
    { value: "Africa/Maputo", label: "Africa/Maputo" },
    { value: "Africa/Maseru", label: "Africa/Maseru" },
    { value: "Africa/Mbabane", label: "Africa/Mbabane" },
    { value: "Etc/GMT-2", label: "Etc/GMT-2" },
    { value: "Europe/Helsinki", label: "Europe/Helsinki" },
    { value: "Europe/Kiev", label: "Europe/Kiev" },
    { value: "Europe/Mariehamn", label: "Europe/Mariehamn" },
    { value: "Europe/Riga", label: "Europe/Riga" },
    { value: "Europe/Sofia", label: "Europe/Sofia" },
    { value: "Europe/Tallinn", label: "Europe/Tallinn" },
    { value: "Europe/Uzhgorod", label: "Europe/Uzhgorod" },
    { value: "Europe/Vilnius", label: "Europe/Vilnius" },
    { value: "Europe/Zaporozhye", label: "Europe/Zaporozhye" },
    { value: "Europe/Istanbul", label: "Europe/Istanbul" },
    { value: "Asia/Jerusalem", label: "Asia/Jerusalem" },
    { value: "Africa/Tripoli", label: "Africa/Tripoli" },
    { value: "Asia/Amman", label: "Asia/Amman" },
    { value: "Asia/Baghdad", label: "Asia/Baghdad" },
    { value: "Europe/Kaliningrad", label: "Europe/Kaliningrad" },
    { value: "Asia/Aden", label: "Asia/Aden" },
    { value: "Asia/Bahrain", label: "Asia/Bahrain" },
    { value: "Asia/Kuwait", label: "Asia/Kuwait" },
    { value: "Asia/Qatar", label: "Asia/Qatar" },
    { value: "Asia/Riyadh", label: "Asia/Riyadh" },
    { value: "Africa/Addis_Ababa", label: "Africa/Addis_Ababa" },
    { value: "Africa/Asmera", label: "Africa/Asmera" },
    { value: "Africa/Dar_es_Salaam", label: "Africa/Dar_es_Salaam" },
    { value: "Africa/Djibouti", label: "Africa/Djibouti" },
    { value: "Africa/Juba", label: "Africa/Juba" },
    { value: "Africa/Kampala", label: "Africa/Kampala" },
    { value: "Africa/Khartoum", label: "Africa/Khartoum" },
    { value: "Africa/Mogadishu", label: "Africa/Mogadishu" },
    { value: "Africa/Nairobi", label: "Africa/Nairobi" },
    { value: "Antarctica/Syowa", label: "Antarctica/Syowa" },
    { value: "Etc/GMT-3", label: "Etc/GMT-3" },
    { value: "Indian/Antananarivo", label: "Indian/Antananarivo" },
    { value: "Indian/Comoro", label: "Indian/Comoro" },
    { value: "Indian/Mayotte", label: "Indian/Mayotte" },
    { value: "Europe/Kirov", label: "Europe/Kirov" },
    { value: "Europe/Moscow", label: "Europe/Moscow" },
    { value: "Europe/Simferopol", label: "Europe/Simferopol" },
    { value: "Europe/Volgograd", label: "Europe/Volgograd" },
    { value: "Europe/Minsk", label: "Europe/Minsk" },
    { value: "Europe/Astrakhan", label: "Europe/Astrakhan" },
    { value: "Europe/Samara", label: "Europe/Samara" },
    { value: "Europe/Ulyanovsk", label: "Europe/Ulyanovsk" },
    { value: "Asia/Tehran", label: "Asia/Tehran" },
    { value: "Asia/Dubai", label: "Asia/Dubai" },
    { value: "Asia/Muscat", label: "Asia/Muscat" },
    { value: "Etc/GMT-4", label: "Etc/GMT-4" },
    { value: "Asia/Baku", label: "Asia/Baku" },
    { value: "Indian/Mahe", label: "Indian/Mahe" },
    { value: "Indian/Mauritius", label: "Indian/Mauritius" },
    { value: "Indian/Reunion", label: "Indian/Reunion" },
    { value: "Asia/Tbilisi", label: "Asia/Tbilisi" },
    { value: "Asia/Yerevan", label: "Asia/Yerevan" },
    { value: "Asia/Kabul", label: "Asia/Kabul" },
    { value: "Antarctica/Mawson", label: "Antarctica/Mawson" },
    { value: "Asia/Aqtau", label: "Asia/Aqtau" },
    { value: "Asia/Aqtobe", label: "Asia/Aqtobe" },
    { value: "Asia/Ashgabat", label: "Asia/Ashgabat" },
    { value: "Asia/Dushanbe", label: "Asia/Dushanbe" },
    { value: "Asia/Oral", label: "Asia/Oral" },
    { value: "Asia/Samarkand", label: "Asia/Samarkand" },
    { value: "Asia/Tashkent", label: "Asia/Tashkent" },
    { value: "Etc/GMT-5", label: "Etc/GMT-5" },
    { value: "Indian/Kerguelen", label: "Indian/Kerguelen" },
    { value: "Indian/Maldives", label: "Indian/Maldives" },
    { value: "Asia/Yekaterinburg", label: "Asia/Yekaterinburg" },
    { value: "Asia/Karachi", label: "Asia/Karachi" },
    { value: "Asia/Kolkata", label: "Asia/Kolkata" },
    { value: "Asia/Colombo", label: "Asia/Colombo" },
    { value: "Asia/Kathmandu", label: "Asia/Kathmandu" },
    { value: "Antarctica/Vostok", label: "Antarctica/Vostok" },
    { value: "Asia/Almaty", label: "Asia/Almaty" },
    { value: "Asia/Bishkek", label: "Asia/Bishkek" },
    { value: "Asia/Qyzylorda", label: "Asia/Qyzylorda" },
    { value: "Asia/Urumqi", label: "Asia/Urumqi" },
    { value: "Etc/GMT-6", label: "Etc/GMT-6" },
    { value: "Indian/Chagos", label: "Indian/Chagos" },
    { value: "Asia/Dhaka", label: "Asia/Dhaka" },
    { value: "Asia/Thimphu", label: "Asia/Thimphu" },
    { value: "Asia/Rangoon", label: "Asia/Rangoon" },
    { value: "Indian/Cocos", label: "Indian/Cocos" },
    { value: "Antarctica/Davis", label: "Antarctica/Davis" },
    { value: "Asia/Bangkok", label: "Asia/Bangkok" },
    { value: "Asia/Hovd", label: "Asia/Hovd" },
    { value: "Asia/Jakarta", label: "Asia/Jakarta" },
    { value: "Asia/Phnom_Penh", label: "Asia/Phnom_Penh" },
    { value: "Asia/Pontianak", label: "Asia/Pontianak" },
    { value: "Asia/Saigon", label: "Asia/Saigon" },
    { value: "Asia/Vientiane", label: "Asia/Vientiane" },
    { value: "Etc/GMT-7", label: "Etc/GMT-7" },
    { value: "Indian/Christmas", label: "Indian/Christmas" },
    { value: "Asia/Novokuznetsk", label: "Asia/Novokuznetsk" },
    { value: "Asia/Novosibirsk", label: "Asia/Novosibirsk" },
    { value: "Asia/Omsk", label: "Asia/Omsk" },
    { value: "Asia/Hong_Kong", label: "Asia/Hong_Kong" },
    { value: "Asia/Macau", label: "Asia/Macau" },
    { value: "Asia/Shanghai", label: "Asia/Shanghai" },
    { value: "Asia/Krasnoyarsk", label: "Asia/Krasnoyarsk" },
    { value: "Asia/Brunei", label: "Asia/Brunei" },
    { value: "Asia/Kuala_Lumpur", label: "Asia/Kuala_Lumpur" },
    { value: "Asia/Kuching", label: "Asia/Kuching" },
    { value: "Asia/Makassar", label: "Asia/Makassar" },
    { value: "Asia/Manila", label: "Asia/Manila" },
    { value: "Asia/Singapore", label: "Asia/Singapore" },
    { value: "Etc/GMT-8", label: "Etc/GMT-8" },
    { value: "Antarctica/Casey", label: "Antarctica/Casey" },
    { value: "Australia/Perth", label: "Australia/Perth" },
    { value: "Asia/Taipei", label: "Asia/Taipei" },
    { value: "Asia/Choibalsan", label: "Asia/Choibalsan" },
    { value: "Asia/Ulaanbaatar", label: "Asia/Ulaanbaatar" },
    { value: "Asia/Irkutsk", label: "Asia/Irkutsk" },
    { value: "Asia/Dili", label: "Asia/Dili" },
    { value: "Asia/Jayapura", label: "Asia/Jayapura" },
    { value: "Asia/Tokyo", label: "Asia/Tokyo" },
    { value: "Etc/GMT-9", label: "Etc/GMT-9" },
    { value: "Pacific/Palau", label: "Pacific/Palau" },
    { value: "Asia/Pyongyang", label: "Asia/Pyongyang" },
    { value: "Asia/Seoul", label: "Asia/Seoul" },
    { value: "Australia/Adelaide", label: "Australia/Adelaide" },
    { value: "Australia/Broken_Hill", label: "Australia/Broken_Hill" },
    { value: "Australia/Darwin", label: "Australia/Darwin" },
    { value: "Australia/Brisbane", label: "Australia/Brisbane" },
    { value: "Australia/Lindeman", label: "Australia/Lindeman" },
    { value: "Australia/Melbourne", label: "Australia/Melbourne" },
    { value: "Australia/Sydney", label: "Australia/Sydney" },
    { value: "Antarctica/DumontDUrville", label: "Antarctica/DumontDUrville" },
    { value: "Etc/GMT-10", label: "Etc/GMT-10" },
    { value: "Pacific/Guam", label: "Pacific/Guam" },
    { value: "Pacific/Port_Moresby", label: "Pacific/Port_Moresby" },
    { value: "Pacific/Saipan", label: "Pacific/Saipan" },
    { value: "Pacific/Truk", label: "Pacific/Truk" },
    { value: "Australia/Currie", label: "Australia/Currie" },
    { value: "Australia/Hobart", label: "Australia/Hobart" },
    { value: "Asia/Chita", label: "Asia/Chita" },
    { value: "Asia/Khandyga", label: "Asia/Khandyga" },
    { value: "Asia/Yakutsk", label: "Asia/Yakutsk" },
    { value: "Antarctica/Macquarie", label: "Antarctica/Macquarie" },
    { value: "Etc/GMT-11", label: "Etc/GMT-11" },
    { value: "Pacific/Efate", label: "Pacific/Efate" },
    { value: "Pacific/Guadalcanal", label: "Pacific/Guadalcanal" },
    { value: "Pacific/Kosrae", label: "Pacific/Kosrae" },
    { value: "Pacific/Noumea", label: "Pacific/Noumea" },
    { value: "Pacific/Ponape", label: "Pacific/Ponape" },
    { value: "Asia/Sakhalin", label: "Asia/Sakhalin" },
    { value: "Asia/Ust-Nera", label: "Asia/Ust-Nera" },
    { value: "Asia/Vladivostok", label: "Asia/Vladivostok" },
    { value: "Antarctica/McMurdo", label: "Antarctica/McMurdo" },
    { value: "Pacific/Auckland", label: "Pacific/Auckland" },
    { value: "Etc/GMT-12", label: "Etc/GMT-12" },
    { value: "Pacific/Funafuti", label: "Pacific/Funafuti" },
    { value: "Pacific/Kwajalein", label: "Pacific/Kwajalein" },
    { value: "Pacific/Majuro", label: "Pacific/Majuro" },
    { value: "Pacific/Nauru", label: "Pacific/Nauru" },
    { value: "Pacific/Tarawa", label: "Pacific/Tarawa" },
    { value: "Pacific/Wake", label: "Pacific/Wake" },
    { value: "Pacific/Wallis", label: "Pacific/Wallis" },
    { value: "Pacific/Fiji", label: "Pacific/Fiji" },
    { value: "Asia/Anadyr", label: "Asia/Anadyr" },
    { value: "Asia/Kamchatka", label: "Asia/Kamchatka" },
    { value: "Asia/Magadan", label: "Asia/Magadan" },
    { value: "Asia/Srednekolymsk", label: "Asia/Srednekolymsk" },
    { value: "Asia/Kamchatka", label: "Asia/Kamchatka" },
    { value: "Etc/GMT-13", label: "Etc/GMT-13" },
    { value: "Pacific/Enderbury", label: "Pacific/Enderbury" },
    { value: "Pacific/Fakaofo", label: "Pacific/Fakaofo" },
    { value: "Pacific/Tongatapu", label: "Pacific/Tongatapu" },
    { value: "Pacific/Apia", label: "Pacific/Apia" },
]

export const ArabicTimeZone = [
    {
        value: "Etc/GMT+12",
        label: "Etc/GMT+12"
    },
    {
        value: "Etc/GMT+11",
        label: "Etc/GMT+11"
    },
    {
        value: "Pacific/Midway",
        label: "المحيط الهادئ/ميدواي"
    },
    {
        value: "Pacific/Niue",
        label: "المحيط الهادئ/نيوي"
    },
    {
        value: "Pacific/Pago_Pago",
        label: "المحيط الهادئ/باغو باغو"
    },
    {
        value: "Etc/GMT+10",
        label: "Etc/GMT+10"
    },
    {
        value: "Pacific/Honolulu",
        label: "المحيط الهادئ/هونولولو"
    },
    {
        value: "Pacific/Johnston",
        label: "المحيط الهادئ/جونستون"
    },
    {
        value: "Pacific/Rarotonga",
        label: "المحيط الهادئ/راروتونجا"
    },
    {
        value: "Pacific/Tahiti",
        label: "المحيط الهادئ/تاهيتي"
    },
    {
        value: "America/Anchorage",
        label: "أمريكا/أنكوريدج"
    },
    {
        value: "America/Juneau",
        label: "أمريكا/جونيو"
    },
    {
        value: "America/Nome",
        label: "أمريكا/نوم"
    },
    {
        value: "America/Sitka",
        label: "أمريكا/سيتكا"
    },
    {
        value: "America/Yakutat",
        label: "أمريكا/ياكوتات"
    },
    {
        value: "America/Santa_Isabel",
        label: "أمريكا/سانتا إيزابيل"
    },
    {
        value: "America/Dawson",
        label: "أمريكا/داوسون"
    },
    {
        value: "America/Los_Angeles",
        label: "أمريكا/لوس أنجلوس"
    },
    {
        value: "America/Tijuana",
        label: "أمريكا/تيخوانا"
    },
    {
        value: "America/Vancouver",
        label: "أمريكا/فانكوفر"
    },
    {
        value: "America/Whitehorse",
        label: "أمريكا/وايتهورس"
    },
    {
        value: "America/Creston",
        label: "أمريكا/كريستون"
    },
    {
        value: "America/Dawson_Creek",
        label: "أمريكا/داوسون كريك"
    },
    {
        value: "America/Hermosillo",
        label: "أمريكا/هيرموسيلو"
    },
    {
        value: "America/Phoenix",
        label: "أمريكا/فينيكس"
    },
    {
        value: "Etc/GMT+7",
        label: "Etc/GMT+7"
    },
    {
        value: "America/Chihuahua",
        label: "أمريكا/تشيواوا"
    },
    {
        value: "America/Mazatlan",
        label: "أمريكا/مازاتلان"
    },
    {
        value: "America/Boise",
        label: "أمريكا/بويسي"
    },
    {
        value: "America/Cambridge_Bay",
        label: "أمريكا/كامبريدج باي"
    },
    {
        value: "America/Denver",
        label: "أمريكا/دينفر"
    },
    {
        value: "America/Edmonton",
        label: "أمريكا/إدمونتون"
    },
    {
        value: "America/Inuvik",
        label: "أمريكا/إنوفيك"
    },
    {
        value: "America/Ojinaga",
        label: "أمريكا/أوخيناجا"
    },
    {
        value: "America/Yellowknife",
        label: "أمريكا/يلونايف"
    },
    {
        value: "America/Belize",
        label: "أمريكا/بيليز"
    },
    {
        value: "America/Costa_Rica",
        label: "أمريكا/كوستاريكا"
    },
    {
        value: "America/El_Salvador",
        label: "أمريكا/السلفادور"
    },
    {
        value: "America/Guatemala",
        label: "أمريكا/غواتيمالا"
    },
    {
        value: "America/Managua",
        label: "أمريكا/ماناغوا"
    },
    {
        value: "America/Tegucigalpa",
        label: "أمريكا/تيغوسيغالبا"
    },
    {
        value: "Etc/GMT+6",
        label: "Etc/GMT+6"
    },
    {
        value: "America/Chicago",
        label: "أمريكا/شيكاغو"
    },
    {
        value: "America/Indiana/Knox",
        label: "أمريكا/إنديانا/نوكس"
    },
    {
        value: "America/Indiana/Tell_City",
        label: "أمريكا/إنديانا/تيل سيتي"
    },
    {
        value: "America/Matamoros",
        label: "أمريكا/ماتاموروس"
    },
    {
        value: "America/Menominee",
        label: "أمريكا/مينوميني"
    },
    {
        value: "America/North_Dakota/Beulah",
        label: "أمريكا/داكوتا الشمالية/بيولا"
    },
    {
        value: "America/North_Dakota/Center",
        label: "أمريكا/داكوتا الشمالية/سنتر"
    },
    {
        value: "America/North_Dakota/New_Salem",
        label: "أمريكا/داكوتا الشمالية/نيو سالم"
    },
    {
        value: "America/Rainy_River",
        label: "أمريكا/ريني ريفر"
    },
    {
        value: "America/Rankin_Inlet",
        label: "أمريكا/رانكين إنلت"
    },
    {
        value: "America/Resolute",
        label: "أمريكا/ريزوليوت"
    },
    {
        value: "America/Winnipeg",
        label: "أمريكا/وينيبيغ"
    },
    {
        value: "America/Bahia_Banderas",
        label: "أمريكا/باهيا بانديراس"
    },
    {
        value: "America/Indiana/Indianapolis",
        label: "أمريكا/إنديانا/إنديانابوليس"
    },
    {
        value: "America/Mexico_City",
        label: "أمريكا/مكسيكو سيتي"
    },
    {
        value: "America/Indiana/Petersburg",
        label: "أمريكا/إنديانا/بيترسبرج"
    },
    {
        value: "America/Kentucky/Monticello",
        label: "أمريكا/كنتاكي/مونتيسيلو"
    },
    {
        value: "America/Indiana/Marengo",
        label: "أمريكا/إنديانا/مارينجو"
    },
    {
        value: "America/Indiana/Vevay",
        label: "أمريكا/إنديانا/فيفاي"
    },
    {
        value: "America/Kentucky/Louisville",
        label: "أمريكا/كنتاكي/لويسفيل"
    },
    {
        value: "America/Cancun",
        label: "أمريكا/كانكون"
    },
    {
        value: "America/Merida",
        label: "أمريكا/ميريدا"
    },
    {
        value: "America/Monterrey",
        label: "أمريكا/مونتيري"
    },
    {
        value: "America/Regina",
        label: "أمريكا/ريجاينا"
    },
    {
        value: "America/Swift_Current",
        label: "أمريكا/سويفت كارنت"
    },
    {
        value: "America/Bogota",
        label: "أمريكا/بوغوتا"
    },
    {
        value: "America/Cayman",
        label: "أمريكا/كايمان"
    },
    {
        value: "America/Lima",
        label: "أمريكا/ليما"
    },
    {
        value: "America/Panama",
        label: "أمريكا/باناما"
    },
    {
        value: "Etc/GMT+5",
        label: "Etc/GMT+5"
    },
    {
        value: "America/Indiana/Winamac",
        label: "أمريكا/إنديانا/ويناماك"
    },
    {
        value: "America/Indianapolis",
        label: "أمريكا/إنديانابوليس"
    },
    {
        value: "America/Caracas",
        label: "أمريكا/كاراكاس"
    },
    {
        value: "America/Anguilla",
        label: "أمريكا/أنغويلا"
    },
    {
        value: "America/Antigua",
        label: "أمريكا/أنتيغوا"
    },
    {
        value: "America/Aruba",
        label: "أمريكا/أروبا"
    },
    {
        value: "America/Barbados",
        label: "أمريكا/بربادوس"
    },
    {
        value: "America/Dominica",
        label: "أمريكا/دومينيكا"
    },
    {
        value: "America/Grenada",
        label: "أمريكا/غرينادا"
    },
    {
        value: "America/Guadeloupe",
        label: "أمريكا/جوادلوب"
    },
    {
        value: "America/Martinique",
        label: "أمريكا/مارتينيك"
    },
    {
        value: "America/Montserrat",
        label: "أمريكا/مونتسيرات"
    },
    {
        value: "America/Puerto_Rico",
        label: "أمريكا/بورتو ريكو"
    },
    {
        value: "America/St_Barthelemy",
        label: "أمريكا/سانت بارتيليمي"
    },
    {
        value: "America/St_Kitts",
        label: "أمريكا/سانت كيتس"
    },
    {
        value: "America/St_Lucia",
        label: "أمريكا/سانت لوسيا"
    },
    {
        value: "America/St_Thomas",
        label: "أمريكا/سانت توماس"
    },
    {
        value: "America/St_Vincent",
        label: "أمريكا/سانت فينسنت"
    },
    {
        value: "America/Tortola",
        label: "أمريكا/تورتولا"
    },
    {
        value: "Etc/GMT+4",
        label: "Etc/GMT+4"
    },
    {
        value: "America/Sao_Paulo",
        label: "أمريكا/ساو باولو"
    },
    {
        value: "America/Argentina/La_Rioja",
        label: "أمريكا/الأرجنتين/لا ريوخا"
    },
    {
        value: "America/Argentina/Rio_Gallegos",
        label: "أمريكا/الأرجنتين/ريو غاليغوس"
    },
    {
        value: "America/Argentina/Salta",
        label: "أمريكا/الأرجنتين/سالتا"
    },
    {
        value: "America/Argentina/San_Juan",
        label: "أمريكا/الأرجنتين/سان خوان"
    },
    {
        value: "America/Argentina/San_Luis",
        label: "أمريكا/الأرجنتين/سان لويس"
    },
    {
        value: "America/Argentina/Tucuman",
        label: "أمريكا/الأرجنتين/توكومان"
    },
    {
        value: "America/Argentina/Ushuaia",
        label: "أمريكا/الأرجنتين/أوشوايا"
    },
    {
        value: "America/Buenos_Aires",
        label: "أمريكا/بوينس آيرس"
    },
    {
        value: "Etc/GMT+3",
        label: "Etc/GMT+3"
    },
    {
        value: "America/Montevideo",
        label: "أمريكا/مونتيفيديو"
    },
    {
        value: "America/Noronha",
        label: "أمريكا/نورونها"
    },
    {
        value: "Atlantic/South_Georgia",
        label: "الأطلسي/جورجيا الجنوبية"
    },
    {
        value: "Etc/GMT+2",
        label: "Etc/GMT+2"
    },
    {
        value: "Atlantic/Azores",
        label: "الأطلسي/الأزور"
    },
    {
        value: "Atlantic/Cape_Verde",
        label: "الأطلسي/الرأس الأخضر"
    },
    {
        value: "Etc/GMT+1",
        label: "Etc/GMT+1"
    },
    {
        value: "Africa/Casablanca",
        label: "أفريقيا/الدار البيضاء"
    },
    {
        value: "Africa/El_Aaiun",
        label: "أفريقيا/العيون"
    },
    {
        value: "Etc/GMT",
        label: "Etc/GMT"
    },
    {
        value: "Europe/Lisbon",
        label: "أوروبا/لشبونة"
    },
    {
        value: "Africa/Abidjan",
        label: "أفريقيا/أبيدجان"
    },
    {
        value: "Africa/Accra",
        label: "أفريقيا/أكرا"
    },
    {
        value: "Africa/Bamako",
        label: "أفريقيا/باماكو"
    },
    {
        value: "Africa/Banjul",
        label: "أفريقيا/بانجول"
    },
    {
        value: "Africa/Bissau",
        label: "أفريقيا/بيساو"
    },
    {
        value: "Africa/Conakry",
        label: "أفريقيا/كوناكري"
    },
    {
        value: "Africa/Dakar",
        label: "أفريقيا/داكار"
    },
    {
        value: "Africa/Freetown",
        label: "أفريقيا/فريتاون"
    },
    {
        value: "Africa/Lome",
        label: "أفريقيا/لومي"
    },
    {
        value: "Africa/Monrovia",
        label: "أفريقيا/مونروفيا"
    },
    {
        value: "Africa/Nouakchott",
        label: "أفريقيا/نواكشوط"
    },
    {
        value: "Africa/Ouagadougou",
        label: "أفريقيا/واغادوغو"
    },
    {
        value: "Africa/Sao_Tome",
        label: "أفريقيا/ساو تومي"
    },
    {
        value: "Africa/Timbuktu",
        label: "أفريقيا/تمبكتو"
    },
    {
        value: "America/Danmarkshavn",
        label: "أمريكا/دانماركشافن"
    },
    {
        value: "Antarctica/Troll",
        label: "القارة القطبية الجنوبية/ترول"
    },
    {
        value: "Europe/Isle_of_Man",
        label: "أوروبا/جزيرة مان"
    },
    {
        value: "Europe/London",
        label: "أوروبا/لندن"
    },
    {
        value: "Atlantic/Faeroe",
        label: "الأطلسي/جزر فارو"
    },
    {
        value: "Arctic/Longyearbyen",
        label: "القطب الشمالي/لونغييربين"
    },
    {
        value: "Atlantic/Jan_Mayen",
        label: "الأطلسي/جان ماين"
    },
    {
        value: "Europe/Guernsey",
        label: "أوروبا/غيرنسي"
    },
    {
        value: "Europe/Jersey",
        label: "أوروبا/جيرسي"
    },
    {
        value: "Atlantic/Madeira",
        label: "الأطلسي/ماديرا"
    },
    {
        value: "Atlantic/Reykjavik",
        label: "الأطلسي/ريكيافيك"
    },
    {
        value: "Europe/Belfast",
        label: "أوروبا/بلفاست"
    },
    {
        value: "Atlantic/Canary",
        label: "الأطلسي/الكناري"
    },
    {
        value: "Europe/Dublin",
        label: "أوروبا/دبلن"
    },
    {
        value: "Europe/Amsterdam",
        label: "أوروبا/أمستردام"
    },
    {
        value: "Europe/Andorra",
        label: "أوروبا/أندورا"
    },
    {
        value: "Europe/Berlin",
        label: "أوروبا/برلين"
    },
    {
        value: "Europe/Busingen",
        label: "أوروبا/بوسينجن"
    },
    {
        value: "Europe/Gibraltar",
        label: "أوروبا/جبل طارق"
    },
    {
        value: "Europe/Luxembourg",
        label: "أوروبا/لوكسمبورغ"
    },
    {
        value: "Europe/Malta",
        label: "أوروبا/مالطا"
    },
    {
        value: "Europe/Monaco",
        label: "أوروبا/موناكو"
    },
    {
        value: "Europe/Oslo",
        label: "أوروبا/أوسلو"
    },
    {
        value: "Europe/Rome",
        label: "أوروبا/روما"
    },
    {
        value: "Europe/San_Marino",
        label: "أوروبا/سان مارينو"
    },
    {
        value: "Europe/Stockholm",
        label: "أوروبا/ستوكهولم"
    },
    {
        value: "Europe/Vaduz",
        label: "أوروبا/فادوز"
    },
    {
        value: "Europe/Vatican",
        label: "أوروبا/الفاتيكان"
    },
    {
        value: "Europe/Vienna",
        label: "أوروبا/فيينا"
    },
    {
        value: "Europe/Zurich",
        label: "أوروبا/زيوريخ"
    },
    {
        value: "Europe/Belgrade",
        label: "أوروبا/بلغراد"
    },
    {
        value: "Europe/Bratislava",
        label: "أوروبا/براتيسلافا"
    },
    {
        value: "Europe/Budapest",
        label: "أوروبا/بودابست"
    },
    {
        value: "Europe/Ljubljana",
        label: "أوروبا/ليوبليانا"
    },
    {
        value: "Europe/Podgorica",
        label: "أوروبا/بودغوريكا"
    },
    {
        value: "Europe/Prague",
        label: "أوروبا/براغ"
    },
    {
        value: "Europe/Tirane",
        label: "أوروبا/تيرانا"
    },
    {
        value: "Europe/Brussels",
        label: "أوروبا/بروكسل"
    },
    {
        value: "Europe/Copenhagen",
        label: "أوروبا/كوبنهاغن"
    },
    {
        value: "Europe/Madrid",
        label: "أوروبا/مدريد"
    },
    {
        value: "Europe/Paris",
        label: "أوروبا/باريس"
    },
    {
        value: "Europe/Sarajevo",
        label: "أوروبا/سراييفو"
    },
    {
        value: "Europe/Skopje",
        label: "أوروبا/سكوبي"
    },
    {
        value: "Europe/Warsaw",
        label: "أوروبا/وارسو"
    },
    {
        value: "Europe/Zagreb",
        label: "أوروبا/زغرب"
    },
    {
        value: "Africa/Algiers",
        label: "أفريقيا/الجزائر"
    },
    {
        value: "Metropolitan/Amsterdam",
        label: "متروبوليتان/أمستردام"
    },
    {
        value: "Metropolitan/Athens",
        label: "متروبوليتان/أثينا"
    },
    {
        value: "Metropolitan/Belgrade",
        label: "متروبوليتان/بلغراد"
    },
    {
        value: "Metropolitan/Berlin",
        label: "متروبوليتان/برلين"
    },
    {
        value: "Metropolitan/Bratislava",
        label: "متروبوليتان/براتيسلافا"
    },
    {
        value: "Metropolitan/Brussels",
        label: "متروبوليتان/بروكسل"
    },
    {
        value: "Metropolitan/Bucharest",
        label: "متروبوليتان/بوخارست"
    },
    {
        value: "Metropolitan/Budapest",
        label: "متروبوليتان/بودابست"
    },
    {
        value: "Metropolitan/Copenhagen",
        label: "متروبوليتان/كوبنهاغن"
    },
    {
        value: "Metropolitan/Dublin",
        label: "متروبوليتان/دبلن"
    },
    {
        value: "Metropolitan/Helsinki",
        label: "متروبوليتان/هلسنكي"
    },
    {
        value: "Metropolitan/Istanbul",
        label: "متروبوليتان/اسطنبول"
    },
    {
        value: "Metropolitan/Kiev",
        label: "متروبوليتان/كييف"
    },
    {
        value: "Metropolitan/Lisbon",
        label: "متروبوليتان/لشبونة"
    },
    {
        value: "Metropolitan/Ljubljana",
        label: "متروبوليتان/ليوبليانا"
    },
    {
        value: "Metropolitan/London",
        label: "متروبوليتان/لندن"
    },
    {
        value: "Metropolitan/Luxembourg",
        label: "متروبوليتان/لوكسمبورغ"
    },
    {
        value: "Metropolitan/Madrid",
        label: "متروبوليتان/مدريد"
    },
    {
        value: "Metropolitan/Malta",
        label: "متروبوليتان/مالطا"
    },
    {
        value: "Metropolitan/Minsk",
        label: "متروبوليتان/مينسك"
    },
    {
        value: "Metropolitan/Monaco",
        label: "متروبوليتان/موناكو"
    },
    {
        value: "Metropolitan/Moscow",
        label: "متروبوليتان/موسكو"
    },
    {
        value: "Metropolitan/Oslo",
        label: "متروبوليتان/أوسلو"
    },
    {
        value: "Metropolitan/Paris",
        label: "متروبوليتان/باريس"
    },
    {
        value: "Metropolitan/Prague",
        label: "متروبوليتان/براغ"
    },
    {
        value: "Metropolitan/Riga",
        label: "متروبوليتان/ريغا"
    },
    {
        value: "Metropolitan/Rome",
        label: "متروبوليتان/روما"
    },
    {
        value: "Metropolitan/Samara",
        label: "متروبوليتان/سمارا"
    },
    {
        value: "Metropolitan/Sarajevo",
        label: "متروبوليتان/سراييفو"
    },
    {
        value: "Metropolitan/Skopje",
        label: "متروبوليتان/سكوبي"
    },
    {
        value: "Metropolitan/Sofia",
        label: "متروبوليتان/صوفيا"
    },
    {
        value: "Metropolitan/Stockholm",
        label: "متروبوليتان/ستوكهولم"
    },
    {
        value: "Metropolitan/Tallinn",
        label: "متروبوليتان/تالين"
    },
    {
        value: "Metropolitan/Tirane",
        label: "متروبوليتان/تيرانا"
    },
    {
        value: "Metropolitan/Ulyanovsk",
        label: "متروبوليتان/أوليانوفسك"
    },
    {
        value: "Metropolitan/Vaduz",
        label: "متروبوليتان/فادوز"
    },
    {
        value: "Metropolitan/Vatican",
        label: "متروبوليتان/الفاتيكان"
    },
    {
        value: "Metropolitan/Vienna",
        label: "متروبوليتان/فيينا"
    },
    {
        value: "Metropolitan/Vilnius",
        label: "متروبوليتان/فيلنيوس"
    },
    {
        value: "Metropolitan/Volgograd",
        label: "متروبوليتان/فولغوغراد"
    },
    {
        value: "Metropolitan/Warsaw",
        label: "متروبوليتان/وارسو"
    },
    {
        value: "Metropolitan/Zagreb",
        label: "متروبوليتان/زغرب"
    },
    {
        value: "Metropolitan/Zaporozhye",
        label: "متروبوليتان/زابوروجيا"
    },
    {
        value: "Metropolitan/Zurich",
        label: "متروبوليتان/زيوريخ"
    },
    {
        value: "WET",
        label: "WET"
    },
    {
        value: "Africa/Ceuta",
        label: "أفريقيا/سبتة"
    },
    {
        value: "Africa/Tunis",
        label: "أفريقيا/تونس"
    },
    {
        value: "Asia/Beirut",
        label: "آسيا/بيروت"
    },
    {
        value: "Africa/Cairo",
        label: "أفريقيا/القاهرة"
    },
    {
        value: "Asia/Damascus",
        label: "آسيا/دمشق"
    },
    {
        value: "Asia/Nicosia",
        label: "آسيا/نيقوسيا"
    },
    {
        value: "Europe/Athens",
        label: "أوروبا/أثينا"
    },
    {
        value: "Europe/Bucharest",
        label: "أوروبا/بوخارست"
    },
    {
        value: "Europe/Chisinau",
        label: "أوروبا/كيشيناو"
    },
    {
        value: "Africa/Blantyre",
        label: "أفريقيا/بلانتاير"
    },
    {
        value: "Africa/Bujumbura",
        label: "أفريقيا/بوجومبورا"
    },
    {
        value: "Africa/Gaborone",
        label: "أفريقيا/غابورون"
    },
    {
        value: "Africa/Harare",
        label: "أفريقيا/هراري"
    },
    {
        value: "Africa/Johannesburg",
        label: "أفريقيا/جوهانسبرج"
    },
    {
        value: "Africa/Kigali",
        label: "أفريقيا/كيغالي"
    },
    {
        value: "Africa/Lubumbashi",
        label: "أفريقيا/لوبومباشي"
    },
    {
        value: "Africa/Lusaka",
        label: "أفريقيا/لوساكا"
    },
    {
        value: "Africa/Maputo",
        label: "أفريقيا/مابوتو"
    },
    {
        value: "Africa/Maseru",
        label: "أفريقيا/ماسيرو"
    },
    {
        value: "Africa/Mbabane",
        label: "أفريقيا/مباباني"
    },
    {
        value: "Europe/Helsinki",
        label: "أوروبا/هلسنكي"
    },
    {
        value: "Europe/Kiev",
        label: "أوروبا/كييف"
    },
    {
        value: "Europe/Mariehamn",
        label: "أوروبا/ماريهامن"
    },
    {
        value: "Europe/Riga",
        label: "أوروبا/ريغا"
    },
    {
        value: "Europe/Sofia",
        label: "أوروبا/صوفيا"
    },
    {
        value: "Europe/Tallinn",
        label: "أوروبا/تالين"
    },
    {
        value: "Europe/Uzhgorod",
        label: "أوروبا/أوجورود"
    },
    {
        value: "Europe/Vilnius",
        label: "أوروبا/فيلنيوس"
    },
    {
        value: "Europe/Zaporozhye",
        label: "أوروبا/زابوروجيا"
    },
    {
        value: "Asia/Jerusalem",
        label: "آسيا/القدس"
    },
    {
        value: "Africa/Tripoli",
        label: "أفريقيا/طرابلس"
    },
    {
        value: "Asia/Amman",
        label: "آسيا/عمان"
    },
    {
        value: "Asia/Baghdad",
        label: "آسيا/بغداد"
    },
    {
        value: "Europe/Kaliningrad",
        label: "أوروبا/كالينينغراد"
    },
    {
        value: "Asia/Aden",
        label: "آسيا/عدن"
    },
    {
        value: "Asia/Bahrain",
        label: "آسيا/البحرين"
    },
    {
        value: "Asia/Kuwait",
        label: "آسيا/الكويت"
    },
    {
        value: "Asia/Qatar",
        label: "آسيا/قطر"
    },
    {
        value: "Asia/Riyadh",
        label: "آسيا/الرياض"
    },
    {
        value: "Africa/Addis_Ababa",
        label: "أفريقيا/أديس أبابا"
    },
    {
        value: "Africa/Asmera",
        label: "أفريقيا/أسمرة"
    },
    {
        value: "Africa/Dar_es_Salaam",
        label: "أفريقيا/دار السلام"
    },
    {
        value: "Africa/Djibouti",
        label: "أفريقيا/جيبوتي"
    },
    {
        value: "Africa/Juba",
        label: "أفريقيا/جوبا"
    },
    {
        value: "Africa/Kampala",
        label: "أفريقيا/كامبالا"
    },
    {
        value: "Africa/Khartoum",
        label: "أفريقيا/الخرطوم"
    },
    {
        value: "Africa/Mogadishu",
        label: "أفريقيا/مقديشو"
    },
    {
        value: "Africa/Nairobi",
        label: "أفريقيا/نيروبي"
    },
    {
        value: "Antarctica/Syowa",
        label: "أنتاركتيكا/سيوا"
    },
    {
        value: "Etc/GMT-3",
        label: "ت.غ.م+3"
    },
    {
        value: "Indian/Antananarivo",
        label: "هندي/أنتاناناريفو"
    },
    {
        value: "Indian/Comoro",
        label: "هندي/جزر القمر"
    },
    {
        value: "Indian/Mayotte",
        label: "هندي/مايوت"
    },
    {
        value: "Europe/Kirov",
        label: "أوروبا/كيروف"
    },
    {
        value: "Europe/Moscow",
        label: "أوروبا/موسكو"
    },
    {
        value: "Europe/Simferopol",
        label: "أوروبا/سيمفروبول"
    },
    {
        value: "Europe/Volgograd",
        label: "أوروبا/فولغوغراد"
    },
    {
        value: "Europe/Minsk",
        label: "أوروبا/مينسك"
    },
    {
        value: "Europe/Astrakhan",
        label: "أوروبا/أستراخان"
    },
    {
        value: "Europe/Samara",
        label: "أوروبا/سمارا"
    },
    {
        value: "Europe/Saratov",
        label: "أوروبا/ساراتوف"
    },
    {
        value: "Asia/Tehran",
        label: "آسيا/طهران"
    },
    {
        value: "Asia/Dubai",
        label: "آسيا/دبي"
    },
    {
        value: "Asia/Muscat",
        label: "آسيا/مسقط"
    },
    {
        value: "Asia/Gaza",
        label: "آسيا/غزة"
    },
    {
        value: "Asia/Hebron",
        label: "آسيا/الخليل"
    },
    {
        value: "Etc/GMT-4",
        label: "ت.غ.م+4"
    },
    {
        value: "Asia/Baku",
        label: "آسيا/باكو"
    },
    {
        value: "Indian/Mahe",
        label: "هندي/ماهي"
    },
    {
        value: "Indian/Mauritius",
        label: "هندي/موريشيوس"
    },
    {
        value: "Indian/Reunion",
        label: "هندي/ريونيون"
    },
    {
        value: "Asia/Tbilisi",
        label: "آسيا/تبليسي"
    },
    {
        value: "Asia/Yerevan",
        label: "آسيا/يريفان"
    },
    {
        value: "Asia/Kabul",
        label: "آسيا/كابول"
    },
    {
        value: "Antarctica/Mawson",
        label: "أنتاركتيكا/ماوسون"
    },
    {
        value: "Asia/Aqtau",
        label: "آسيا/أكتاو"
    },
    {
        value: "Asia/Aqtobe",
        label: "آسيا/أكتوب"
    },
    {
        value: "Asia/Ashgabat",
        label: "آسيا/عشق‌آباد"
    },
    {
        value: "Asia/Dushanbe",
        label: "آسيا/دوشنبه"
    },
    {
        value: "Asia/Oral",
        label: "آسيا/أورال"
    },
    {
        value: "Asia/Samarkand",
        label: "آسيا/سمرقند"
    },
    {
        value: "Asia/Tashkent",
        label: "آسيا/طشقند"
    },
    {
        value: "Etc/GMT-5",
        label: "ت.غ.م+5"
    },
    {
        value: "Indian/Kerguelen",
        label: "هندي/كيرغولين"
    },
    {
        value: "Indian/Maldives",
        label: "هندي/المالديف"
    },
    {
        value: "Asia/Yekaterinburg",
        label: "آسيا/يكاتيرينبورغ"
    },
    {
        value: "Asia/Karachi",
        label: "آسيا/كراتشي"
    },
    {
        value: "Asia/Kolkata",
        label: "آسيا/كولكاتا"
    },
    {
        value: "Asia/Colombo",
        label: "آسيا/كولومبو"
    },
    {
        value: "Asia/Kathmandu",
        label: "آسيا/كاتماندو"
    },
    {
        value: "Antarctica/Vostok",
        label: "أنتاركتيكا/فوستوك"
    },
    {
        value: "Asia/Almaty",
        label: "آسيا/ألماتي"
    },
    {
        value: "Asia/Bishkek",
        label: "آسيا/بيشكك"
    },
    {
        value: "Asia/Qyzylorda",
        label: "آسيا/قيزيلوردا"
    },
    {
        value: "Asia/Urumqi",
        label: "آسيا/أورومتشي"
    },
    {
        value: "Etc/GMT-6",
        label: "ت.غ.م+6"
    },
    {
        value: "Indian/Chagos",
        label: "هندي/شاغوس"
    },
    {
        value: "Asia/Dhaka",
        label: "آسيا/دكا"
    },
    {
        value: "Asia/Thimphu",
        label: "آسيا/تيمفو"
    },
    {
        value: "Asia/Rangoon",
        label: "آسيا/يانغون"
    },
    {
        value: "Indian/Cocos",
        label: "هندي/جزر كوكوس"
    },
    {
        value: "Antarctica/Davis",
        label: "أنتاركتيكا/ديفيس"
    },
    {
        value: "Asia/Bangkok",
        label: "آسيا/بانكوك"
    },
    {
        value: "Asia/Hovd",
        label: "آسيا/هوفد"
    },
    {
        value: "Asia/Jakarta",
        label: "آسيا/جاكرتا"
    },
    {
        value: "Asia/Phnom_Penh",
        label: "آسيا/نوم بنه"
    },
    {
        value: "Asia/Pontianak",
        label: "آسيا/بونتياناك"
    },
    {
        value: "Asia/Saigon",
        label: "آسيا/سايجون"
    },
    {
        value: "Asia/Vientiane",
        label: "آسيا/فيانتيان"
    },
    {
        value: "Etc/GMT-7",
        label: "ت.غ.م+7"
    },
    {
        value: "Indian/Christmas",
        label: "هندي/جزيرة الكريسماس"
    },
    {
        value: "Asia/Novokuznetsk",
        label: "آسيا/نوفوكوزنتسك"
    },
    {
        value: "Asia/Novosibirsk",
        label: "آسيا/نوفوسيبيرسك"
    },
    {
        value: "Asia/Omsk",
        label: "آسيا/أومسك"
    },
    {
        value: "Asia/Hong_Kong",
        label: "آسيا/هونج كونج"
    },
    {
        value: "Asia/Macau",
        label: "آسيا/ماكاو"
    },
    {
        value: "Asia/Shanghai",
        label: "آسيا/شنغهاي"
    },
    {
        value: "Asia/Krasnoyarsk",
        label: "آسيا/كراسنويارسك"
    },
    {
        value: "Asia/Brunei",
        label: "آسيا/بروناي"
    },
    {
        value: "Asia/Kuala_Lumpur",
        label: "آسيا/كوالا لمبور"
    },
    {
        value: "Asia/Kuching",
        label: "آسيا/كوتشينغ"
    },
    {
        value: "Asia/Makassar",
        label: "آسيا/ماكاسار"
    },
    {
        value: "Asia/Manila",
        label: "آسيا/مانيلا"
    },
    {
        value: "Asia/Singapore",
        label: "آسيا/سنغافورة"
    },
    {
        value: "Etc/GMT-8",
        label: "ت.غ.م+8"
    },
    {
        value: "Antarctica/Casey",
        label: "أنتاركتيكا/كيسي"
    },
    {
        value: "Australia/Perth",
        label: "أستراليا/برث"
    },
    {
        value: "Asia/Taipei",
        label: "آسيا/تايبيه"
    },
    {
        value: "Asia/Choibalsan",
        label: "آسيا/تشويبالسان"
    },
    {
        value: "Asia/Ulaanbaatar",
        label: "آسيا/أولان باتور"
    },
    {
        value: "Asia/Irkutsk",
        label: "آسيا/إيركوتسك"
    },
    {
        value: "Asia/Dili",
        label: "آسيا/ديلي"
    },
    {
        value: "Asia/Jayapura",
        label: "آسيا/جايابورا"
    },
    {
        value: "Asia/Tokyo",
        label: "آسيا/طوكيو"
    },
    {
        value: "Etc/GMT-9",
        label: "ت.غ.م+9"
    },
    {
        value: "Asia/Pyongyang",
        label: "آسيا/بيونغ يانغ"
    },
    {
        value: "Asia/Seoul",
        label: "آسيا/سيول"
    },
    {
        value: "Antarctica/DumontDUrville",
        label: "أنتاركتيكا/دومونت دورفيل"
    },
    {
        value: "Etc/GMT-10",
        label: "ت.غ.م+10"
    },
    {
        value: "Pacific/Guam",
        label: "باسيفيك/غوام"
    },
    {
        value: "Pacific/Port_Moresby",
        label: "باسيفيك/بورت موريسبي"
    },
    {
        value: "Pacific/Saipan",
        label: "باسيفيك/سايبان"
    },
    {
        value: "Pacific/Truk",
        label: "باسيفيك/تروك"
    },
    {
        value: "Asia/Khandyga",
        label: "آسيا/خانديغا"
    },
    {
        value: "Asia/Yakutsk",
        label: "آسيا/ياكوتسك"
    },
    {
        value: "Asia/Sakhalin",
        label: "آسيا/ساخالين"
    },
    {
        value: "Asia/Ust-Nera",
        label: "آسيا/أوست نيرا"
    },
    {
        value: "Asia/Vladivostok",
        label: "آسيا/فلاديفوستوك"
    },
    {
        value: "Asia/Anadyr",
        label: "آسيا/أنادير"
    },
    {
        value: "Asia/Magadan",
        label: "آسيا/ماجادان"
    },
    {
        value: "Asia/Kamchatka",
        label: "آسيا/كامتشاتكا"
    },
    {
        value: "Pacific/Chuuk",
        label: "باسيفيك/تشوك"
    },
    {
        value: "Pacific/Yap",
        label: "باسيفيك/ياب"
    },
    {
        value: "Pacific/Efate",
        label: "باسيفيك/إفاتي"
    },
    {
        value: "Pacific/Guadalcanal",
        label: "باسيفيك/غوادالكانال"
    },
    {
        value: "Pacific/Kosrae",
        label: "باسيفيك/كوسراي"
    },
    {
        value: "Pacific/Noumea",
        label: "باسيفيك/نوميا"
    },
    {
        value: "Pacific/Pohnpei",
        label: "باسيفيك/بونابي"
    },
    {
        value: "Etc/GMT-11",
        label: "ت.غ.م+11"
    },
    {
        value: "Pacific/Pitcairn",
        label: "باسيفيك/بيتكيرن"
    },
    {
        value: "Pacific/Marquesas",
        label: "باسيفيك/ماركيز"
    },
    {
        value: "PST8PDT",
        label: "PST8PDT"
    },
    {
        value: "MST7MDT",
        label: "MST7MDT"
    },
    {
        value: "Pacific/Galapagos",
        label: "المحيط الهادئ/جزر الغالاباغوس"
    },
    {
        value: "CST6CDT",
        label: "CST6CDT"
    },
    {
        value: "America/Coral_Harbour",
        label: "أمريكا/كورال هاربور"
    },
    {
        value: "America/Eirunepe",
        label: "أمريكا/إيرونيبي"
    },
    {
        value: "America/Guayaquil",
        label: "أمريكا/غواياكويل"
    },
    {
        value: "America/Jamaica",
        label: "أمريكا/جامايكا"
    },
    {
        value: "America/Rio_Branco",
        label: "أمريكا/ريو برانكو"
    },
    {
        value: "America/Detroit",
        label: "أمريكا/ديترويت"
    },
    {
        value: "America/Havana",
        label: "أمريكا/هافانا"
    },
    {
        value: "America/Indiana/Vincennes",
        label: "أمريكا/إنديانا/فينسينيس"
    },
    {
        value: "America/Iqaluit",
        label: "أمريكا/إيكالويت"
    },
    {
        value: "America/Louisville",
        label: "أمريكا/لويسفيل"
    },
    {
        value: "America/Montreal",
        label: "أمريكا/مونتريال"
    },
    {
        value: "America/Nassau",
        label: "أمريكا/ناساو"
    },
    {
        value: "America/New_York",
        label: "أمريكا/نيويورك"
    },
    {
        value: "America/Nipigon",
        label: "أمريكا/نيبيجون"
    },
    {
        value: "America/Pangnirtung",
        label: "أمريكا/بانجنيرتونج"
    },
    {
        value: "America/Port-au-Prince",
        label: "أمريكا/بورت أو برنس"
    },
    {
        value: "America/Thunder_Bay",
        label: "أمريكا/ثندر باي"
    },
    {
        value: "America/Toronto",
        label: "أمريكا/تورونتو"
    },
    {
        value: "EST5EDT",
        label: "EST5EDT"
    },
    {
        value: "America/Asuncion",
        label: "أمريكا/أسونسيون"
    },
    {
        value: "America/Glace_Bay",
        label: "أمريكا/خليج جلاس"
    },
    {
        value: "America/Goose_Bay",
        label: "أمريكا/خليج جوس"
    },
    {
        value: "America/Halifax",
        label: "أمريكا/هاليفاكس"
    },
    {
        value: "America/Moncton",
        label: "أمريكا/مونكتون"
    },
    {
        value: "America/Thule",
        label: "أمريكا/ثول"
    },
    {
        value: "Atlantic/Bermuda",
        label: "الأطلسي/برمودا"
    },
    {
        value: "America/Campo_Grande",
        label: "أمريكا/كامبو غراندي"
    },
    {
        value: "America/Cuiaba",
        label: "أمريكا/كويابا"
    },
    {
        value: "America/Blanc-Sablon",
        label: "أمريكا/بلان سابلون"
    },
    {
        value: "America/Boa_Vista",
        label: "أمريكا/بوا فيستا"
    },
    {
        value: "America/Curacao",
        label: "أمريكا/كوراساو"
    },
    {
        value: "America/Grand_Turk",
        label: "أمريكا/غراند تورك"
    },
    {
        value: "America/Guyana",
        label: "أمريكا/غيانا"
    },
    {
        value: "America/Kralendijk",
        label: "أمريكا/كرالينديك"
    },
    {
        value: "America/La_Paz",
        label: "أمريكا/لا باز"
    },
    {
        value: "America/Lower_Princes",
        label: "أمريكا/لور برينسيس"
    },
    {
        value: "America/Manaus",
        label: "أمريكا/ماناوس"
    },
    {
        value: "America/Marigot",
        label: "أمريكا/ماريغوت"
    },
    {
        value: "America/Port_of_Spain",
        label: "أمريكا/ميناء إسبانيا"
    },
    {
        value: "America/Porto_Velho",
        label: "أمريكا/بورتو فيلهو"
    },
    {
        value: "America/Santo_Domingo",
        label: "أمريكا/سانتو دومينغو"
    },
    {
        value: "America/Santiago",
        label: "أمريكا/سانتياغو"
    },
    {
        value: "Etc/GMT-12",
        label: "ت.غ.م+12"
    },
    {
        value: "Pacific/Wake",
        label: "باسيفيك/واك"
    },
    {
        value: "Pacific/Wallis",
        label: "باسيفيك/والس"
    },
    {
        value: "Etc/GMT-13",
        label: "ت.غ.م+13"
    },
    {
        value: "Pacific/Fakaofo",
        label: "باسيفيك/فاكاوفو"
    },
    {
        value: "Pacific/Tongatapu",
        label: "باسيفيك/تونغاتابو"
    },
    {
        value: "Pacific/Apia",
        label: "باسيفيك/أبيا"
    },
    {
        value: "Pacific/Kiritimati",
        label: "باسيفيك/كيريتيماتي"
    },
    {
        value: "Antarctica/Palmer",
        label: "القارة القطبية الجنوبية/بالمر"
    },
    {
        value: "America/St_Johns",
        label: "أمريكا/سانت جونز"
    },
    {
        value: "America/Catamarca",
        label: "أمريكا/كاتاماركا"
    },
    {
        value: "America/Cordoba",
        label: "أمريكا/كوردوبا"
    },
    {
        value: "America/Jujuy",
        label: "أمريكا/خوخوي"
    },
    {
        value: "America/Mendoza",
        label: "أمريكا/ميندوزا"
    },
    {
        value: "America/Araguaina",
        label: "أمريكا/أراجواينا"
    },
    {
        value: "America/Belem",
        label: "أمريكا/بيليم"
    },
    {
        value: "America/Cayenne",
        label: "أمريكا/كايين"
    },
    {
        value: "America/Fortaleza",
        label: "أمريكا/فورتاليزا"
    },
    {
        value: "America/Maceio",
        label: "أمريكا/ماسيو"
    },
    {
        value: "America/Paramaribo",
        label: "أمريكا/باراماريبو"
    },
    {
        value: "America/Recife",
        label: "أمريكا/ريسيفي"
    },
    {
        value: "America/Santarem",
        label: "أمريكا/سانتاريم"
    },
    {
        value: "Antarctica/Rothera",
        label: "القارة القطبية الجنوبية/روثيرا"
    },
    {
        value: "Atlantic/Stanley",
        label: "الأطلسي/ستانلي"
    },
    {
        value: "America/Godthab",
        label: "أمريكا/جودثاب"
    },
    {
        value: "America/Bahia",
        label: "أمريكا/باهيا"
    },
    {
        value: "America/Scoresbysund",
        label: "أمريكا/سكوريسبيسوند"
    },
    {
        value: "Atlantic/St_Helena",
        label: "الأطلسي/سانت هيلينا"
    },
    {
        value: "Africa/Bangui",
        label: "أفريقيا/بانغوي"
    },
    {
        value: "Africa/Brazzaville",
        label: "أفريقيا/برازافيل"
    },
    {
        value: "Africa/Douala",
        label: "أفريقيا/دوالا"
    },
    {
        value: "Africa/Kinshasa",
        label: "أفريقيا/كينشاسا"
    },
    {
        value: "Africa/Lagos",
        label: "أفريقيا/لاغوس"
    },
    {
        value: "Africa/Libreville",
        label: "أفريقيا/ليبرفيل"
    },
    {
        value: "Africa/Luanda",
        label: "أفريقيا/لواندا"
    },
    {
        value: "Africa/Malabo",
        label: "أفريقيا/مالابو"
    },
    {
        value: "Africa/Ndjamena",
        label: "أفريقيا/نجامينا"
    },
    {
        value: "Africa/Niamey",
        label: "أفريقيا/نيامي"
    },
    {
        value: "Africa/Porto-Novo",
        label: "أفريقيا/بورتو نوفو"
    },
    {
        value: "Etc/GMT-1",
        label: "ت.غ.م+1"
    },
    {
        value: "Africa/Windhoek",
        label: "أفريقيا/ويندهوك"
    },
    {
        value: "Europe/Nicosia",
        label: "أوروبا/نيقوسيا"
    },
    {
        value: "Etc/GMT-2",
        label: "ت.غ.م+2"
    },
    {
        value: "Europe/Istanbul",
        label: "أوروبا/اسطنبول"
    },
    {
        value: "Europe/Ulyanovsk",
        label: "أوروبا/أوليانوفسك"
    },
    {
        value: "Pacific/Palau",
        label: "المحيط الهادئ/بالاو"
    },
    {
        value: "Australia/Adelaide",
        label: "أستراليا/أديليد"
    },
    {
        value: "Australia/Broken_Hill",
        label: "أستراليا/بروكن هيل"
    },
    {
        value: "Australia/Darwin",
        label: "أستراليا/داروين"
    },
    {
        value: "Australia/Brisbane",
        label: "أستراليا/بريسبين"
    },
    {
        value: "Australia/Lindeman",
        label: "أستراليا/ليندمان"
    },
    {
        value: "Australia/Melbourne",
        label: "أستراليا/ملبورن"
    },
    {
        value: "Australia/Sydney",
        label: "أستراليا/سيدني"
    },
    {
        value: "Australia/Currie",
        label: "أستراليا/كوري"
    },
    {
        value: "Australia/Hobart",
        label: "أستراليا/هوبارت"
    },
    {
        value: "Asia/Chita",
        label: "آسيا/تشيتا"
    },
    {
        value: "Antarctica/Macquarie",
        label: "القارة القطبية الجنوبية/ماكواري"
    },
    {
        value: "Pacific/Ponape",
        label: "المحيط الهادئ/بونابي"
    },
    {
        value: "Antarctica/McMurdo",
        label: "القارة القطبية الجنوبية/مكمردو"
    },
    {
        value: "Pacific/Auckland",
        label: "المحيط الهادئ/أوكلاند"
    },
    {
        value: "Pacific/Funafuti",
        label: "المحيط الهادئ/فونافوتي"
    },
    {
        value: "Pacific/Kwajalein",
        label: "المحيط الهادئ/كواجالين"
    },
    {
        value: "Pacific/Majuro",
        label: "المحيط الهادئ/ماجورو"
    },
    {
        value: "Pacific/Nauru",
        label: "المحيط الهادئ/ناورو"
    },
    {
        value: "Pacific/Tarawa",
        label: "المحيط الهادئ/تاراوا"
    },
    {
        value: "Pacific/Fiji",
        label: "المحيط الهادئ/فيجي"
    },
    {
        value: "Asia/Srednekolymsk",
        label: "آسيا/سريدنكوليمسك"
    },
    {
        value: "Pacific/Enderbury",
        label: "المحيط الهادئ/إندربوري"
    }
]
