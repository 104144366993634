import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import en from './en.json';
import ar from './ar.json';
import { GlobalVariable } from '../util';


// the translations
// (tip: move them in separate JSON files and import them)
const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar,
  },
};

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: localStorage.getItem(GlobalVariable.localLang),
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },    
  });
  export const getTranslation = (value) => {
    let localLanguage = localStorage.getItem(GlobalVariable.localLang)
    
    return i18n.t(value, { lng: localLanguage });
  };
export default i18n;
