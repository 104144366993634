import { ADD_QUEUE } from "../types";
import { api } from "../../../api/api";
import toast from 'react-hot-toast';
import { GlobalVariable } from "../../../util/index";
import { history } from "../../../history";
import { getTranslation } from "../../../i18n/i18n";
import { setLoaderAction } from "../loader/index";
import { getQueueServiceAction } from "../service/index";
import { QueueApi } from "../../../api/queueApi";
const api_status = GlobalVariable.apiResponseStatus;

/**
 @name  addQueueAction
 @file queue.js
 @description This method is used for create new queue  
 */
export const addQueueAction = (queue) => {
  return async (dispatch, store) => {
    let {
      name,
      arabicName,
      status,
      service,
      // serviceTime,
      // appointmentSize,
      // concurrentAppointment,
    } = queue;

    if (!name) {
      toast.error(getTranslation("GLOBLE_MESSAGE.nameRequired"));
      return true;
    }
    if (!arabicName) {
      toast.error(getTranslation("GLOBLE_MESSAGE.arNameRequired"));
      return true;
    }

    if (!status.value) {
      toast.error(getTranslation("GLOBLE_MESSAGE.statusRequired"));
      return true;
    }

    if (!service || service.length <= 0) {
      toast.error(getTranslation("GLOBLE_MESSAGE.serviceRequired"));
      return true;
    }

    // if (!serviceTime) {
    //   toast.error(getTranslation("GLOBLE_MESSAGE.serviceTimeRequired"));
    //   return true;
    // }
    // if (!appointmentSize) {
    //   toast.error(getTranslation("GLOBLE_MESSAGE.appointmentSizeRequired"));
    //   return true;
    // }
    // if(!concurrentAppointment){
    //     toast.error(getTranslation('GLOBLE_MESSAGE.concurrentAppointmentRequired'));
    //     return true;
    // }

    dispatch(setLoaderAction(true));
    let serviceID = [];
    for (let i = 0; i < service.length; i++) {
      serviceID.push(service[i].value);
    }
    let queueData = {
      name,
      merchantID: store().auth.login.user.merchantID,
      isActive: status.value === "active" ? true : false,
      nameTrans: [
        {
          name,
          lang: "en",
        },
        {
          name: arabicName,
          lang: "ar",
        },
      ],
      serviceID: serviceID,
      // serviceTime,
      // appointmentSize,
      // concurrentAppointment,
    };

    let queueGet = await api("queue", queueData, "post");

    if (queueGet.status === api_status.success) {
      let queueServices = [];
      for (let i = 0; i < service.length; i++) {
        queueServices.push({
          id: service[i].value,
          name: service[i].label,
        });
      }
      let queueUpdateData = {
        refQueueId: queueGet.data.data.id,
        merchantId: store().auth.login.user.merchantID,
        name,
        status: "a",
        isActive: false,
        serviceId: queueServices,
        // serviceTime,
        // appointmentSize,
        // concurrentAppointment,
      };
      await QueueApi(`queue/change`, queueUpdateData, "post");

      dispatch(getQueueAction({ name: "" }));
      toast.success(getTranslation("QUEUE.create"));
      dispatch(setLoaderAction(false));
      dispatch(getQueueServiceAction());
      history.push("/queues");
    } else {
      toast.error(queueGet.message.message);
      dispatch(setLoaderAction(false));
    }
  };
};

/**
 @name  getQueueAction
 @file queue.js
 @description This method is used for get queues  
 */
export const getQueueAction = (data) => {
  return async (dispatch, store) => {
    let { name } = data;
    let query = `queue?merchantID=${store().auth.login.user.merchantID}`;
    if (store().auth.login.user.role === GlobalVariable.manager) {
    let {id} = store().auth.login.user
    query = query + `&userID=${id}`
   }
    if (name) {
      query = query + `&name=${name}`;
    }

    if (data && data.status && data.status !== "All") {
      if (data.status === "Active") {
        query = query + `&isActive=true`;
      }
      if (data.status === "Inactive") {
        query = query + `&isActive=false`;
      }
    }
    if (data && data.service && data.service !== "All") {
      query = query + `&service=${data.service}`;
    }

    let getQueue = await api(query, {}, "get");
    if (getQueue.status === api_status.success) {
      let onlineQueueData = getQueue.data.data.onlineQueue;
      let queueDataArray = getQueue.data.data.queue;
      if (onlineQueueData.length > 0 && queueDataArray.length > 0) {
        for (let i = 0; i < queueDataArray.length; i++) {
          queueDataArray[i].isOnline = false;
          for (let j = 0; j < onlineQueueData.length; j++) {
            if (queueDataArray[i].id === onlineQueueData[j].refQueueId) {
              queueDataArray[i].isOnline = true;
            }
          }
        }

        getQueue.data.data.queue = queueDataArray;
      }
      dispatch({
        type: ADD_QUEUE,
        payload: getQueue.data.data,
      });
      return getQueue?.data?.data?.queue;
    }
  };
};

/**
 @name  getQueueByIdAction
 @file queue.js
 @description This method is used for get queue by id 
 */
export const getQueueByIdAction = async (id) => {
  let getQueue = await api(`queue/${id}`, {}, "get");

  if (getQueue.status === api_status.success) {
    return { success: true, data: getQueue.data.data };
    // toast.success('Category delete Successfully!');
  } else {
    return { success: false };
  }
};

/**
 @name  updateQueueAction
 @file queue.js
 @description This method is used for update queue  
 */
export const updateQueueAction = (queue, mainData) => {
  return async (dispatch, store) => {
    let {
      name,
      arabicName,
      status,
      service,
      // serviceTime,
      // appointmentSize,
      // concurrentAppointment,
    } = queue;

    if (!name) {
      toast.error(getTranslation("GLOBLE_MESSAGE.nameRequired"));
      return true;
    }
    if (!arabicName) {
      toast.error(getTranslation("GLOBLE_MESSAGE.arNameRequired"));
      return true;
    }

    if (!status.value) {
      toast.error(getTranslation("GLOBLE_MESSAGE.statusRequired"));
      return true;
    }

    // if (!serviceTime) {
    //   toast.error(getTranslation("GLOBLE_MESSAGE.serviceTimeRequired"));
    //   return true;
    // }
    // if (!appointmentSize) {
    //   toast.error(getTranslation("GLOBLE_MESSAGE.appointmentSizeRequired"));
    //   return true;
    // }
    // if(!concurrentAppointment){
    //     toast.error(getTranslation('GLOBLE_MESSAGE.concurrentAppointmentRequired'));
    //     return true;
    // }

    for (let i = 0; i < mainData.queueName.length; i++) {
      if (mainData.queueName[i].lang === "en") {
        mainData.queueName[i].name = name;
      }
      if (mainData.queueName[i].lang === "ar") {
        mainData.queueName[i].name = arabicName;
      }
    }
    let serviceID = [];
    for (let i = 0; i < service.length; i++) {
      serviceID.push({ ...service[i] });
    }
    dispatch(setLoaderAction(true));
    let serviceData = {
      id: mainData.id,
      name,
      merchantID: store().auth.login.user.merchantID,
      isActive: status.value === "active" ? true : false,
      serviceID: serviceID,
      nameTrans: mainData.queueName,
      // serviceTime,
      // appointmentSize,
      // concurrentAppointment,
    };

    let queueGet = await api("queue", serviceData, "put");

    if (queueGet.status === api_status.success) {
      let queueServices = [];
      for (let i = 0; i < service.length; i++) {
        if (service[i].status !== "d") {
          queueServices.push({
            id: service[i].id,
            name: service[i].name,
          });
        }
      }

      let queueUpdateData = {
        refQueueId: mainData.id,
        merchantId: store().auth.login.user.merchantID,
        name,
        status: "u",
        isActive: status.value === "active" ? true : false,
        serviceId: queueServices,
        // serviceTime,
        // appointmentSize,
        // concurrentAppointment,
      };
      await QueueApi(`queue/change`, queueUpdateData, "post");
      dispatch(getQueueAction({ name: "" }));
      toast.success(getTranslation("QUEUE.update"));
      dispatch(setLoaderAction(false));
      dispatch(getQueueServiceAction());
      history.push("/queues");
    } else {
      toast.error(queueGet.message.message);
      dispatch(setLoaderAction(false));
    }
  };
};

/**
 @name  deleteQueueAction
 @file queue.js
 @description This method is used for delete  queue  
 */
export const deleteQueueAction = (data) => {
  return async (dispatch, store) => {
    dispatch(setLoaderAction(true));
    let deleteMerchant = await api(`queue?id=${data.id}`, {}, "delete");

    if (deleteMerchant.status === api_status.success) {
      let queueUpdateData = {
        refQueueId: data.id,
        merchantId: store().auth.login.user.merchantID,
        name: data.name,
        status: "d",
        isActive: false,
        serviceId: data.services,
        // serviceTime: data.serviceTime,
        // appointmentSize: data.appointmentSize,
        // concurrentAppointment: data.concurrentAppointment,
      };
      await QueueApi(`queue/change`, queueUpdateData, "post");

      dispatch(getQueueAction({ name: "" }));
      dispatch(getQueueServiceAction());
      dispatch(setLoaderAction(false));
      toast.success(getTranslation("QUEUE.delete"));
    } else {
      dispatch(setLoaderAction(false));
    }
  };
};
