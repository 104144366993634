import { store } from "../redux/storeConfig/store";
import axios from "axios";
import {
  setAuthTokenAction,
  setIsLoginAction,
  setAuthRefreshTokenAction,
  setLoginUserAction,
  errorDetection,
  checkTokenRefresh,
} from "../redux/actions/auth/index";
import { apiErrorHandling, GlobalVariable } from "../util/index";
import { setLoaderAction } from "../redux/actions/loader/index";
import { history } from "../history";
import { encryptStorage } from "../util/encryptStorage";
import { ADD_SERVICE } from "../redux/actions/types";
import i18n from "../i18n/i18n";

const main_url = GlobalVariable.apiUrl.mainUrl;
const api_status = GlobalVariable.apiResponseStatus;

export const api = async (endpoint, data, type) => {
  await checkTokenRefresh();
  const beforeApiRunTime = new Date().getTime();

  let res = {};
  res = await callApi(endpoint, data, type);
  const afterApiRunTime = new Date().getTime();
  const diff = Math.abs(afterApiRunTime - beforeApiRunTime) / 1000;
  const errorData = {
    serviceName: "",
    api: main_url + endpoint,
    osType: "web",
    appVersion: "",
    errorDetail: "Api is taking more than 20 seconds to return data",
    userId: store.getState().auth.login.user.merchantID,
  };
  // checking if any api is taking more than 20 seconds to return data then send error or warning to database
  if (diff > 20) {
    await errorDetection(errorData);
  }

  if (res.status === api_status.unAuthorized) {
    await unAuthorizeFunction(res, endpoint, data, type);
    if (type === "post") {
      res = {
        status: api_status.unAuthorized,
        message: { message: "Session expire!" },
      };
    } else {
      res = {
        status: api_status.unAuthorized,
        message: "Session expire!",
      };
    }
    // res = await callApi(endpoint, data, type);
  } else {
    await checkResponse(res, endpoint, data, type);
  }

  return res;
};

export const checkResponse = async (data, endpoint, bodyData, apiType) => {
  if (data.status === api_status.serverError) {
    // navigate('ServerError');
    return true;
  }

  return true;
};

export const unAuthorizeFunction = async (
  data,
  endpoint,
  bodyData,
  apiType
) => {
  if (data.status === api_status.unAuthorized) {
    try {
      store.dispatch(setIsLoginAction(false));
      store.dispatch(setLoginUserAction({}));
      store.dispatch(setAuthTokenAction({}));
      store.dispatch(setAuthRefreshTokenAction({}));
      store.dispatch({
        type: ADD_SERVICE,
        payload: { service: [], pageNo: 0 },
      });
      // store.dispatch(logOutAction());
      encryptStorage.remove(GlobalVariable.localStoreName);
      store.dispatch(setLoaderAction(false));
      // history.push('/login');
      return true;
    } catch (e) {
      // store.dispatch(logOutAction());
      store.dispatch(setLoaderAction(false));
      history.push("/login");
      return true;
    }
  }
};

export const callApi = async (endpoint, data, type) => {
  let res = {};

  let token = await store.getState().auth.login.token;

  let url = main_url + endpoint;

  let headers = {
    "Content-Type": "application/json",
    "x-auth": token,
    "lang": i18n.language,
  };

  switch (type) {
    case "post":
      try {
        await axios
          .post(url, data, { headers: headers })
          .then(function (response) {
            res = response;
          })
          .catch((err) => {
            res = apiErrorHandling(err, "post");
          });
      } catch (e) { }
      break;
    case "get":
      await axios
        .get(url, { headers: headers })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "get");
        });

      break;
    case "put":
      await axios
        .put(url, data, { headers: headers })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "put");
        });

      break;
    case "putImage":
      await axios
        .put(url, data, { headers: headers })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "putImage");
        });

      break;
    case "putFormData":
      let headersForm = {
        "x-auth": token,
      };
      await axios
        .put(url, data, { headers: headersForm })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "putFormData");
        });

      break;
    case "postWithFormdata":
      let headersForm1 = {
        "x-auth": token,
        "Content-Type": "multipart/form-data",
      };
      try {
        await axios
          .post(url, data, { headers: headersForm1 })
          .then(function (response) {
            res = response;
          })
          .catch((err) => {
            res = apiErrorHandling(err, "postWithFormdata");
          });
      } catch (e) {}
      break;
    case "patch":
      await axios
        .patch(url, data, { headers: headers })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "patch");
        });

      break;
    case "delete":
      await axios
        .delete(url, { headers: headers })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = apiErrorHandling(err, "delete");
        });
      break;
    default:
      return true;
  }

  return res;
};
