export const CountryDialCode = [{
  english_name: "Afghanistan",
  arabic_name: "أفغانستان",
  countryCode: "AF",
  alpha3_code: "AFG",
  value: "93"
}, {
  english_name: "Åland Islands",
  arabic_name: "جزر أولاند",
  countryCode: "AX",
  alpha3_code: "ALA",
  value: "358"
}, {
  english_name: "Albania",
  arabic_name: "ألبانيا",
  countryCode: "AL",
  alpha3_code: "ALB",
  value: "355"
}, {
  english_name: "Algeria",
  arabic_name: "الجزائر",
  countryCode: "DZ",
  alpha3_code: "DZA",
  value: "213"
}, {
  english_name: "American Samoa",
  arabic_name: "ساموا الأمريكية",
  countryCode: "AS",
  alpha3_code: "ASM",
  value: "684"
}, {
  english_name: "Andorra",
  arabic_name: "أندورا",
  countryCode: "AD",
  alpha3_code: "AND",
  value: "376"
}, {
  english_name: "Angola",
  arabic_name: "أنغولا",
  countryCode: "AO",
  alpha3_code: "AGO",
  value: "244"
}, {
  english_name: "Anguilla",
  arabic_name: "أنغويلا",
  countryCode: "AI",
  alpha3_code: "AIA",
  value: "264"
}, {
  english_name: "Antarctica",
  arabic_name: "القارة القطبية الجنوبية",
  countryCode: "AQ",
  alpha3_code: "ATA",
  value: "672"
}, {
  english_name: "Antigua and Barbuda",
  arabic_name: "أنتيغوا وبربودا",
  countryCode: "AG",
  alpha3_code: "ATG",
  value: "268"
}, {
  english_name: "Argentina",
  arabic_name: "الأرجنتين",
  countryCode: "AR",
  alpha3_code: "ARG",
  value: "54"
}, {
  english_name: "Armenia",
  arabic_name: "أرمينيا",
  countryCode: "AM",
  alpha3_code: "ARM",
  value: "374"
}, {
  english_name: "Aruba",
  arabic_name: "أروبا",
  countryCode: "AW",
  alpha3_code: "ABW",
  value: "297"
}, {
  english_name: "Australia",
  arabic_name: "أستراليا",
  countryCode: "AU",
  alpha3_code: "AUS",
  value: "61"
}, {
  english_name: "Austria",
  arabic_name: "النمسا",
  countryCode: "AT",
  alpha3_code: "AUT",
  value: "43"
}, {
  english_name: "Azerbaijan",
  arabic_name: "أذربيجان",
  countryCode: "AZ",
  alpha3_code: "AZE",
  value: "994"
}, {
  english_name: "Bahamas",
  arabic_name: "باهاماس",
  countryCode: "BS",
  alpha3_code: "BHS",
  value: "1"
}, {
  english_name: "Bahrain",
  arabic_name: "البحرين",
  countryCode: "BH",
  alpha3_code: "BHR",
  value: "973"
}, {
  english_name: "Bangladesh",
  arabic_name: "بنغلاديش",
  countryCode: "BD",
  alpha3_code: "BGD",
  value: "880"
}, {
  english_name: "Barbados",
  arabic_name: "بربادوس",
  countryCode: "BB",
  alpha3_code: "BRB",
  value: "246"
}, {
  english_name: "Belarus",
  arabic_name: "بيلاروسيا",
  countryCode: "BY",
  alpha3_code: "BLR",
  value: "375"
}, {
  english_name: "Belgium",
  arabic_name: "بلجيكا",
  countryCode: "BE",
  alpha3_code: "BEL",
  value: "32"
}, {
  english_name: "Belize",
  arabic_name: "بليز",
  countryCode: "BZ",
  alpha3_code: "BLZ",
  value: "501"
}, {
  english_name: "Benin",
  arabic_name: "بنين",
  countryCode: "BJ",
  alpha3_code: "BEN",
  value: "229"
}, {
  english_name: "Bermuda",
  arabic_name: "برمودا",
  countryCode: "BM",
  alpha3_code: "BMU",
  value: "1"
}, {
  english_name: "Bhutan",
  arabic_name: "بوتان",
  countryCode: "BT",
  alpha3_code: "BTN",
  value: "975"
}, {
  english_name: "Bolivia",
  arabic_name: "بوليفيا",
  countryCode: "BO",
  alpha3_code: "BOL",
  value: "591"
}, {
  english_name: "Bosnia and Herzegovina",
  arabic_name: "البوسنة والهرسك",
  countryCode: "BA",
  alpha3_code: "BIH",
  value: "387"
}, {
  english_name: "Botswana",
  arabic_name: "بوتسوانا",
  countryCode: "BW",
  alpha3_code: "BWA",
  value: "267"
}, {
  english_name: "Bouvet Island",
  arabic_name: "جزيرة بوفيه",
  countryCode: "BV",
  alpha3_code: "BVT",
  value: "47"
}, {
  english_name: "Brazil",
  arabic_name: "البرازيل",
  countryCode: "BR",
  alpha3_code: "BRA",
  value: "55"
}, {
  english_name: "British Indian Ocean Territory",
  arabic_name: "إقليم المحيط الهندي البريطاني",
  countryCode: "IO",
  alpha3_code: "IOT",
  value: "246"
}, {
  english_name: "British Virgin Islands",
  arabic_name: "جزر العذراء البريطانية",
  countryCode: "VG",
  alpha3_code: "VGB",
  value: "1"
}, {
  english_name: "Brunei",
  arabic_name: "بروناي",
  countryCode: "BN",
  alpha3_code: "BRN",
  value: "673"
}, {
  english_name: "Bulgaria",
  arabic_name: "بلغاريا",
  countryCode: "BG",
  alpha3_code: "BGR",
  value: "359"
}, {
  english_name: "Burkina Faso",
  arabic_name: "بوركينا فاسو",
  countryCode: "BF",
  alpha3_code: "BFA",
  value: "226"
}, {
  english_name: "Burundi",
  arabic_name: "بوروندي",
  countryCode: "BI",
  alpha3_code: "BDI",
  value: "257"
}, {
  english_name: "Cabo Verde",
  arabic_name: "كابو فيردي",
  countryCode: "CV",
  alpha3_code: "CPV",
  value: "238"
}, {
  english_name: "Cambodia",
  arabic_name: "كمبوديا",
  countryCode: "KH",
  alpha3_code: "KHM",
  value: "855"
}, {
  english_name: "Cameroon",
  arabic_name: "الكاميرون",
  countryCode: "CM",
  alpha3_code: "CMR",
  value: "237"
}, {
  english_name: "Canada",
  arabic_name: "كندا",
  countryCode: "CA",
  alpha3_code: "CAN",
  value: "1"
}, {
  english_name: "Caribbean Netherlands",
  arabic_name: "الجزر الكاريبية الهولندية",
  countryCode: "BQ",
  alpha3_code: "BES",
  value: "599"
}, {
  english_name: "Cayman Islands",
  arabic_name: "جزر كايمان",
  countryCode: "KY",
  alpha3_code: "CYM",
  value: "1345"
}, {
  english_name: "Central African Republic",
  arabic_name: "جمهورية أفريقيا الوسطى",
  countryCode: "CF",
  alpha3_code: "CAF",
  value: "236"
}, {
  english_name: "Chad",
  arabic_name: "تشاد",
  countryCode: "TD",
  alpha3_code: "TCD",
  value: "235"
}, {
  english_name: "Chile",
  arabic_name: "تشيلي",
  countryCode: "CL",
  alpha3_code: "CHL",
  value: "56"
}, {
  english_name: "China",
  arabic_name: "الصين",
  countryCode: "CN",
  alpha3_code: "CHN",
  value: "86"
}, {
  english_name: "Christmas Island",
  arabic_name: "جزيرة الكريسماس",
  countryCode: "CX",
  alpha3_code: "CXR",
  value: "61"
}, {
  english_name: "Cocos (Keeling) Islands",
  arabic_name: "جزر كوكوس",
  countryCode: "CC",
  alpha3_code: "CCK",
  value: "61"
}, {
  english_name: "Colombia",
  arabic_name: "كولومبيا",
  countryCode: "CO",
  alpha3_code: "COL",
  value: "57"
}, {
  english_name: "Comoros",
  arabic_name: "جزر القمر",
  countryCode: "KM",
  alpha3_code: "COM",
  value: "269"
}, {
  english_name: "Congo Republic",
  arabic_name: "جمهورية الكونغو",
  countryCode: "CG",
  alpha3_code: "COG",
  value: "242"
}, {
  english_name: "Cook Islands",
  arabic_name: "جزر كوك",
  countryCode: "CK",
  alpha3_code: "COK",
  value: "682"
}, {
  english_name: "Costa Rica",
  arabic_name: "كوستاريكا",
  countryCode: "CR",
  alpha3_code: "CRI",
  value: "506"
}, {
  english_name: "Croatia",
  arabic_name: "كرواتيا",
  countryCode: "HR",
  alpha3_code: "HRV",
  value: "385"
}, {
  english_name: "Cuba",
  arabic_name: "كوبا",
  countryCode: "CU",
  alpha3_code: "CUB",
  value: "53"
}, {
  english_name: "Curaçao",
  arabic_name: "كوراساو",
  countryCode: "CW",
  alpha3_code: "CUW",
  value: "599"
}, {
  english_name: "Cyprus",
  arabic_name: "قبرص",
  countryCode: "CY",
  alpha3_code: "CYP",
  value: "357"
}, {
  english_name: "Czechia",
  arabic_name: "التشيك",
  countryCode: "CZ",
  alpha3_code: "CZE",
  value: "420"
}, {
  english_name: "Denmark",
  arabic_name: "الدنمارك",
  countryCode: "DK",
  alpha3_code: "DNK",
  value: "45"
}, {
  english_name: "Djibouti",
  arabic_name: "جيبوتي",
  countryCode: "DJ",
  alpha3_code: "DJI",
  value: "253"
}, {
  english_name: "Dominica",
  arabic_name: "دومينيكا",
  countryCode: "DM",
  alpha3_code: "DMA",
  value: "767"
}, {
  english_name: "Dominican Republic",
  arabic_name: "جمهورية الدومينيكان",
  countryCode: "DO",
  alpha3_code: "DOM",
  value: "1"
}, {
  english_name: "DR Congo",
  arabic_name: "جمهورية الكونغو الديمقراطية",
  countryCode: "CD",
  alpha3_code: "COD",
  value: "243"
}, {
  english_name: "Ecuador",
  arabic_name: "الاكوادور",
  countryCode: "EC",
  alpha3_code: "ECU",
  value: "593"
}, {
  english_name: "Egypt",
  arabic_name: "مصر",
  countryCode: "EG",
  alpha3_code: "EGY",
  value: "20"
}, {
  english_name: "El Salvador",
  arabic_name: "السلفادور",
  countryCode: "SV",
  alpha3_code: "SLV",
  value: "503"
}, {
  english_name: "Equatorial Guinea",
  arabic_name: "غينيا الاستوائية",
  countryCode: "GQ",
  alpha3_code: "GNQ",
  value: "240"
}, {
  english_name: "Eritrea",
  arabic_name: "إريتريا",
  countryCode: "ER",
  alpha3_code: "ERI",
  value: "291"
}, {
  english_name: "Estonia",
  arabic_name: "إستونيا",
  countryCode: "EE",
  alpha3_code: "EST",
  value: "372"
}, {
  english_name: "Eswatini",
  arabic_name: "إسواتيني",
  countryCode: "SZ",
  alpha3_code: "SWZ",
  value: "268"
}, {
  english_name: "Ethiopia",
  arabic_name: "إثيوبيا",
  countryCode: "ET",
  alpha3_code: "ETH",
  value: "251"
}, {
  english_name: "Falkland Islands",
  arabic_name: "جزر فوكلاند",
  countryCode: "FK",
  alpha3_code: "FLK",
  value: "500"
}, {
  english_name: "Faroe Islands",
  arabic_name: "جزر فارو",
  countryCode: "FO",
  alpha3_code: "FRO",
  value: "298"
}, {
  english_name: "Fiji",
  arabic_name: "فيجي",
  countryCode: "FJ",
  alpha3_code: "FJI",
  value: "679"
}, {
  english_name: "Finland",
  arabic_name: "فنلندا",
  countryCode: "FI",
  alpha3_code: "FIN",
  value: "358"
}, {
  english_name: "France",
  arabic_name: "فرنسا",
  countryCode: "FR",
  alpha3_code: "FRA",
  value: "33"
}, {
  english_name: "French Guiana",
  arabic_name: "غويانا الفرنسية",
  countryCode: "GF",
  alpha3_code: "GUF",
  value: "594"
}, {
  english_name: "French Polynesia",
  arabic_name: "بولينزيا الفرنسية",
  countryCode: "PF",
  alpha3_code: "PYF",
  value: "689"
}, {
  english_name: "French Southern Territories",
  arabic_name: "أراض فرنسية جنوبية",
  countryCode: "TF",
  alpha3_code: "ATF",
  value: "262"
}, {
  english_name: "Gabon",
  arabic_name: "الجابون",
  countryCode: "GA",
  alpha3_code: "GAB",
  value: "241"
}, {
  english_name: "Gambia",
  arabic_name: "غامبيا",
  countryCode: "GM",
  alpha3_code: "GMB",
  value: "220"
}, {
  english_name: "Georgia",
  arabic_name: "‫جورجيا",
  countryCode: "GE",
  alpha3_code: "GEO",
  value: "995"
}, {
  english_name: "Germany",
  arabic_name: "ألمانيا",
  countryCode: "DE",
  alpha3_code: "DEU",
  value: "49"
}, {
  english_name: "Ghana",
  arabic_name: "غانا",
  countryCode: "GH",
  alpha3_code: "GHA",
  value: "233"
}, {
  english_name: "Gibraltar",
  arabic_name: "جبل طارق",
  countryCode: "GI",
  alpha3_code: "GIB",
  value: "350"
}, {
  english_name: "Greece",
  arabic_name: "اليونان",
  countryCode: "GR",
  alpha3_code: "GRC",
  value: "30"
}, {
  english_name: "Greenland",
  arabic_name: "جرينلاند",
  countryCode: "GL",
  alpha3_code: "GRL",
  value: "299"
}, {
  english_name: "Grenada",
  arabic_name: "غرينادا",
  countryCode: "GD",
  alpha3_code: "GRD",
  value: "473"
}, {
  english_name: "Guadeloupe",
  arabic_name: "غوادلوب",
  countryCode: "GP",
  alpha3_code: "GLP",
  value: "590"
}, {
  english_name: "Guam",
  arabic_name: "غوام",
  countryCode: "GU",
  alpha3_code: "GUM",
  value: "1"
}, {
  english_name: "Guatemala",
  arabic_name: "غواتيمالا",
  countryCode: "GT",
  alpha3_code: "GTM",
  value: "502"
}, {
  english_name: "Guernsey",
  arabic_name: "غيرنزي",
  countryCode: "GG",
  alpha3_code: "GGY",
  value: "44"
}, {
  english_name: "Guinea",
  arabic_name: "غينيا",
  countryCode: "GN",
  alpha3_code: "GIN",
  value: "224"
}, {
  english_name: "Guinea-Bissau",
  arabic_name: "غينيا بيساو",
  countryCode: "GW",
  alpha3_code: "GNB",
  value: "245"
}, {
  english_name: "Guyana",
  arabic_name: "غيانا",
  countryCode: "GY",
  alpha3_code: "GUY",
  value: "592"
}, {
  english_name: "Haiti",
  arabic_name: "هايتي",
  countryCode: "HT",
  alpha3_code: "HTI",
  value: "509"
}, {
  english_name: "Heard Island and McDonald Islands",
  arabic_name: "جزيرة هيرد وجزر ماكدونالد",
  countryCode: "HM",
  alpha3_code: "HMD",
  value: "672"
}, {
  english_name: "Honduras",
  arabic_name: "هندوراس",
  countryCode: "HN",
  alpha3_code: "HND",
  value: "504"
}, {
  english_name: "Hong Kong",
  arabic_name: "هونج كونج",
  countryCode: "HK",
  alpha3_code: "HKG",
  value: "852"
}, {
  english_name: "Hungary",
  arabic_name: "هنجاريا",
  countryCode: "HU",
  alpha3_code: "HUN",
  value: "36"
}, {
  english_name: "Iceland",
  arabic_name: "آيسلندا",
  countryCode: "IS",
  alpha3_code: "ISL",
  value: "354"
}, {
  english_name: "India",
  arabic_name: "الهند",
  countryCode: "IN",
  alpha3_code: "IND",
  value: "91"
}, {
  english_name: "Indonesia",
  arabic_name: "أندونيسيا",
  countryCode: "ID",
  alpha3_code: "IDN",
  value: "62"
}, {
  english_name: "Iran",
  arabic_name: "إيران",
  countryCode: "IR",
  alpha3_code: "IRN",
  value: "98"
}, {
  english_name: "Iraq",
  arabic_name: "العراق",
  countryCode: "IQ",
  alpha3_code: "IRQ",
  value: "964"
}, {
  english_name: "Ireland",
  arabic_name: "أيرلندا",
  countryCode: "IE",
  alpha3_code: "IRL",
  value: "353"
}, {
  english_name: "Isle of Man",
  arabic_name: "جزيرة مان",
  countryCode: "IM",
  alpha3_code: "IMN",
  value: "44"
},
{
  english_name: "Palestine",
  arabic_name: "فلسطين",
  countryCode: "IL",
  alpha3_code: "ISR",
  value: "972"
},
{
  english_name: "Italy",
  arabic_name: "إيطاليا",
  countryCode: "IT",
  alpha3_code: "ITA",
  value: "39"
}, {
  english_name: "Ivory Coast",
  arabic_name: "ساحل العاج",
  countryCode: "CI",
  alpha3_code: "CIV",
  value: "225"
}, {
  english_name: "Jamaica",
  arabic_name: "جامايكا",
  countryCode: "JM",
  alpha3_code: "JAM",
  value: "1876"
}, {
  english_name: "Japan",
  arabic_name: "اليابان",
  countryCode: "JP",
  alpha3_code: "JPN",
  value: "81"
}, {
  english_name: "Jersey",
  arabic_name: "جيرسي",
  countryCode: "JE",
  alpha3_code: "JEY",
  value: "44"
}, {
  english_name: "Jordan",
  arabic_name: "الأردن",
  countryCode: "JO",
  alpha3_code: "JOR",
  value: "962"
}, {
  english_name: "Kazakhstan",
  arabic_name: "كازاخستان",
  countryCode: "KZ",
  alpha3_code: "KAZ",
  value: "7"
}, {
  english_name: "Kenya",
  arabic_name: "كينيا",
  countryCode: "KE",
  alpha3_code: "KEN",
  value: "254"
}, {
  english_name: "Kiribati",
  arabic_name: "كيريباتي",
  countryCode: "KI",
  alpha3_code: "KIR",
  value: "686"
}, {
  english_name: "Kosovo",
  arabic_name: "كوسوفو",
  countryCode: "XK",
  alpha3_code: "XKX",
  value: "383"
}, {
  english_name: "Kuwait",
  arabic_name: "الكويت",
  countryCode: "KW",
  alpha3_code: "KWT",
  value: "965"
}, {
  english_name: "Kyrgyzstan",
  arabic_name: "قيرغيزستان",
  countryCode: "KG",
  alpha3_code: "KGZ",
  value: "996"
}, {
  english_name: "Laos",
  arabic_name: "لاوس",
  countryCode: "LA",
  alpha3_code: "LAO",
  value: "856"
}, {
  english_name: "Latvia",
  arabic_name: "لاتفيا",
  countryCode: "LV",
  alpha3_code: "LVA",
  value: "371"
}, {
  english_name: "Lebanon",
  arabic_name: "لبنان",
  countryCode: "LB",
  alpha3_code: "LBN",
  value: "961"
}, {
  english_name: "Lesotho",
  arabic_name: "ليسوتو",
  countryCode: "LS",
  alpha3_code: "LSO",
  value: "266"
}, {
  english_name: "Liberia",
  arabic_name: "ليبيريا",
  countryCode: "LR",
  alpha3_code: "LBR",
  value: "231"
}, {
  english_name: "Libya",
  arabic_name: "ليبيا",
  countryCode: "LY",
  alpha3_code: "LBY",
  value: "218"
}, {
  english_name: "Liechtenstein",
  arabic_name: "ليختنشتاين",
  countryCode: "LI",
  alpha3_code: "LIE",
  value: "423"
}, {
  english_name: "Lithuania",
  arabic_name: "ليتوانيا",
  countryCode: "LT",
  alpha3_code: "LTU",
  value: "370"
}, {
  english_name: "Luxembourg",
  arabic_name: "لوكسمبورغ",
  countryCode: "LU",
  alpha3_code: "LUX",
  value: "352"
}, {
  english_name: "Macao",
  arabic_name: "ماكاو",
  countryCode: "MO",
  alpha3_code: "MAC",
  value: "853"
}, {
  english_name: "Madagascar",
  arabic_name: "مدغشقر",
  countryCode: "MG",
  alpha3_code: "MDG",
  value: "261"
}, {
  english_name: "Malawi",
  arabic_name: "مالاوي",
  countryCode: "MW",
  alpha3_code: "MWI",
  value: "265"
}, {
  english_name: "Malaysia",
  arabic_name: "ماليزيا",
  countryCode: "MY",
  alpha3_code: "MYS",
  value: "60"
}, {
  english_name: "Maldives",
  arabic_name: "المالديف",
  countryCode: "MV",
  alpha3_code: "MDV",
  value: "960"
}, {
  english_name: "Mali",
  arabic_name: "مالي",
  countryCode: "ML",
  alpha3_code: "MLI",
  value: "223"
}, {
  english_name: "Malta",
  arabic_name: "مالطا",
  countryCode: "MT",
  alpha3_code: "MLT",
  value: "356"
}, {
  english_name: "Marshall Islands",
  arabic_name: "جزر مارشال",
  countryCode: "MH",
  alpha3_code: "MHL",
  value: "692"
}, {
  english_name: "Martinique",
  arabic_name: "مارتينيك",
  countryCode: "MQ",
  alpha3_code: "MTQ",
  value: "33"
}, {
  english_name: "Mauritania",
  arabic_name: "موريتانيا",
  countryCode: "MR",
  alpha3_code: "MRT",
  value: "222"
}, {
  english_name: "Mauritius",
  arabic_name: "موريشيوس",
  countryCode: "MU",
  alpha3_code: "MUS",
  value: "230"
}, {
  english_name: "Mayotte",
  arabic_name: "مايوت",
  countryCode: "YT",
  alpha3_code: "MYT",
  value: "262"
}, {
  english_name: "Mexico",
  arabic_name: "المكسيك",
  countryCode: "MX",
  alpha3_code: "MEX",
  value: "52"
}, {
  english_name: "Micronesia",
  arabic_name: "ولايات ميكرونيسيا المتحدة",
  countryCode: "FM",
  alpha3_code: "FSM",
  value: "691"
}, {
  english_name: "Moldova",
  arabic_name: "مولدوفا",
  countryCode: "MD",
  alpha3_code: "MDA",
  value: "373"
}, {
  english_name: "Monaco",
  arabic_name: "موناكو",
  countryCode: "MC",
  alpha3_code: "MCO",
  value: "377"
}, {
  english_name: "Mongolia",
  arabic_name: "منغوليا",
  countryCode: "MN",
  alpha3_code: "MNG",
  value: "976"
}, {
  english_name: "Montenegro",
  arabic_name: "مونتينيغرو",
  countryCode: "ME",
  alpha3_code: "MNE",
  value: "382"
}, {
  english_name: "Montserrat",
  arabic_name: "مونتسرات",
  countryCode: "MS",
  alpha3_code: "MSR",
  value: "1664"
}, {
  english_name: "Morocco",
  arabic_name: "المغرب",
  countryCode: "MA",
  alpha3_code: "MAR",
  value: "212"
}, {
  english_name: "Mozambique",
  arabic_name: "موزمبيق",
  countryCode: "MZ",
  alpha3_code: "MOZ",
  value: "258"
}, {
  english_name: "Myanmar",
  arabic_name: "ميانمار",
  countryCode: "MM",
  alpha3_code: "MMR",
  value: "95"
}, {
  english_name: "Namibia",
  arabic_name: "ناميبيا",
  countryCode: "NA",
  alpha3_code: "NAM",
  value: "264"
}, {
  english_name: "Nauru",
  arabic_name: "ناورو",
  countryCode: "NR",
  alpha3_code: "NRU",
  value: "674"
}, {
  english_name: "Nepal",
  arabic_name: "نيبال",
  countryCode: "NP",
  alpha3_code: "NPL",
  value: "977"
}, {
  english_name: "Netherlands",
  arabic_name: "هولندا",
  countryCode: "NL",
  alpha3_code: "NLD",
  value: "31"
}, {
  english_name: "Netherlands Antilles",
  arabic_name: "جزر الأنتيل الهولندية",
  countryCode: "AN",
  alpha3_code: "ANT",
  value: "599"
}, {
  english_name: "New Caledonia",
  arabic_name: "كاليدونيا الجديدة",
  countryCode: "NC",
  alpha3_code: "NCL",
  value: "687"
}, {
  english_name: "New Zealand",
  arabic_name: "نيوزيلندا",
  countryCode: "NZ",
  alpha3_code: "NZL",
  value: "64"
}, {
  english_name: "Nicaragua",
  arabic_name: "نيكاراغوا",
  countryCode: "NI",
  alpha3_code: "NIC",
  value: "505"
}, {
  english_name: "Niger",
  arabic_name: "النيجر",
  countryCode: "NE",
  alpha3_code: "NER",
  value: "227"
}, {
  english_name: "Nigeria",
  arabic_name: "نيجيريا",
  countryCode: "NG",
  alpha3_code: "NGA",
  value: "234"
}, {
  english_name: "Niue",
  arabic_name: "نييوي",
  countryCode: "NU",
  alpha3_code: "NIU",
  value: "683"
}, {
  english_name: "Norfolk Island",
  arabic_name: "جزيرة نورفولك",
  countryCode: "NF",
  alpha3_code: "NFK",
  value: "6723"
}, {
  english_name: "North Korea",
  arabic_name: "كوريا الشمالية",
  countryCode: "KP",
  alpha3_code: "PRK",
  value: "850"
}, {
  english_name: "North Macedonia",
  arabic_name: "مقدونيا الشمالية",
  countryCode: "MK",
  alpha3_code: "MKD",
  value: "389"
}, {
  english_name: "Northern Mariana Islands",
  arabic_name: "جزر ماريانا الشمالية",
  countryCode: "MP",
  alpha3_code: "MNP",
  value: "1"
}, {
  english_name: "Norway",
  arabic_name: "النرويج",
  countryCode: "NO",
  alpha3_code: "NOR",
  value: "47"
}, {
  english_name: "Oman",
  arabic_name: "سلطنة عمان",
  countryCode: "OM",
  alpha3_code: "OMN",
  value: "968"
}, {
  english_name: "Pakistan",
  arabic_name: "باكستان",
  countryCode: "PK",
  alpha3_code: "PAK",
  value: "92"
}, {
  english_name: "Palau",
  arabic_name: "بالاو",
  countryCode: "PW",
  alpha3_code: "PLW",
  value: "680"
}, {
  english_name: "Palestine",
  arabic_name: "فلسطين",
  countryCode: "PS",
  alpha3_code: "PSE",
  value: "970"
}, {
  english_name: "Panama",
  arabic_name: "بنما",
  countryCode: "PA",
  alpha3_code: "PAN",
  value: "507"
}, {
  english_name: "Papua New Guinea",
  arabic_name: "بابوا غينيا الجديدة",
  countryCode: "PG",
  alpha3_code: "PNG",
  value: "675"
}, {
  english_name: "Paraguay",
  arabic_name: "باراغواي",
  countryCode: "PY",
  alpha3_code: "PRY",
  value: "595"
}, {
  english_name: "Peru",
  arabic_name: "بيرو",
  countryCode: "PE",
  alpha3_code: "PER",
  value: "51"
}, {
  english_name: "Philippines",
  arabic_name: "الفلبين",
  countryCode: "PH",
  alpha3_code: "PHL",
  value: "63"
}, {
  english_name: "Pitcairn Islands",
  arabic_name: "جزر بيتكيرن",
  countryCode: "PN",
  alpha3_code: "PCN",
  value: "672"
}, {
  english_name: "Poland",
  arabic_name: "بولندا",
  countryCode: "PL",
  alpha3_code: "POL",
  value: "48"
}, {
  english_name: "Portugal",
  arabic_name: "البرتغال",
  countryCode: "PT",
  alpha3_code: "PRT",
  value: "351"
}, {
  english_name: "Puerto Rico",
  arabic_name: "بورتوريكو",
  countryCode: "PR",
  alpha3_code: "PRI",
  value: "1787"
}, {
  english_name: "Qatar",
  arabic_name: "قطر",
  countryCode: "QA",
  alpha3_code: "QAT",
  value: "974"
}, {
  english_name: "Réunion",
  arabic_name: "ريونيون",
  countryCode: "RE",
  alpha3_code: "REU",
  value: "262"
}, {
  english_name: "Romania",
  arabic_name: "رومانيا",
  countryCode: "RO",
  alpha3_code: "ROU",
  value: "40"
}, {
  english_name: "Russia",
  arabic_name: "روسيا",
  countryCode: "RU",
  alpha3_code: "RUS",
  value: "7"
}, {
  english_name: "Rwanda",
  arabic_name: "رواندا",
  countryCode: "RW",
  alpha3_code: "RWA",
  value: "250"
}, {
  english_name: "Saint Barthélemy",
  arabic_name: "سان بارتيلمي",
  countryCode: "BL",
  alpha3_code: "BLM",
  value: "590"
}, {
  english_name: "Saint Helena",
  arabic_name: "سانت هيلينا",
  countryCode: "SH",
  alpha3_code: "SHN",
  value: "290"
}, {
  english_name: "Saint Kitts and Nevis",
  arabic_name: "سانت كيتس ونيفيس",
  countryCode: "KN",
  alpha3_code: "KNA",
  value: "1869"
}, {
  english_name: "Saint Lucia",
  arabic_name: "سانت لوسيا",
  countryCode: "LC",
  alpha3_code: "LCA",
  value: "1758"
}, {
  english_name: "Saint Martin",
  arabic_name: "سانت مارتن",
  countryCode: "MF",
  alpha3_code: "MAF",
  value: "590"
}, {
  english_name: "Saint Pierre and Miquelon",
  arabic_name: "سان بيير وميكلون",
  countryCode: "PM",
  alpha3_code: "SPM",
  value: "508"
}, {
  english_name: "Saint Vincent and the Grenadines",
  arabic_name: "سانت فينسنت والغرينادين",
  countryCode: "VC",
  alpha3_code: "VCT",
  value: "784"
}, {
  english_name: "Samoa",
  arabic_name: "ساموا",
  countryCode: "WS",
  alpha3_code: "WSM",
  value: "685"
}, {
  english_name: "San Marino",
  arabic_name: "سان مارينو",
  countryCode: "SM",
  alpha3_code: "SMR",
  value: "378"
}, {
  english_name: "São Tomé and Príncipe",
  arabic_name: "ساو تومي وبرينسيب",
  countryCode: "ST",
  alpha3_code: "STP",
  value: "239"
}, {
  english_name: "Saudi Arabia",
  arabic_name: "السعودية",
  countryCode: "SA",
  alpha3_code: "SAU",
  value: "966"
}, {
  english_name: "Senegal",
  arabic_name: "السنغال",
  countryCode: "SN",
  alpha3_code: "SEN",
  value: "221"
}, {
  english_name: "Serbia",
  arabic_name: "صربيا",
  countryCode: "RS",
  alpha3_code: "SRB",
  value: "381"
}, {
  english_name: "Seychelles",
  arabic_name: "سيشل",
  countryCode: "SC",
  alpha3_code: "SYC",
  value: "248"
}, {
  english_name: "Sierra Leone",
  arabic_name: "سيراليون",
  countryCode: "SL",
  alpha3_code: "SLE",
  value: "232"
}, {
  english_name: "Singapore",
  arabic_name: "سنغافورة",
  countryCode: "SG",
  alpha3_code: "SGP",
  value: "65"
}, {
  english_name: "Sint Maarten",
  arabic_name: "سانت مارتن",
  countryCode: "SX",
  alpha3_code: "SXM",
  value: "599"
}, {
  english_name: "Slovakia",
  arabic_name: "سلوفاكيا",
  countryCode: "SK",
  alpha3_code: "SVK",
  value: "421"
}, {
  english_name: "Slovenia",
  arabic_name: "سلوفينيا",
  countryCode: "SI",
  alpha3_code: "SVN",
  value: "386"
}, {
  english_name: "Solomon Islands",
  arabic_name: "جزر سليمان",
  countryCode: "SB",
  alpha3_code: "SLB",
  value: "677"
}, {
  english_name: "Somalia",
  arabic_name: "الصومال",
  countryCode: "SO",
  alpha3_code: "SOM",
  value: "252"
}, {
  english_name: "South Africa",
  arabic_name: "جنوب أفريقيا",
  countryCode: "ZA",
  alpha3_code: "ZAF",
  value: "27"
}, {
  english_name: "South Georgia and South Sandwich Islands",
  arabic_name: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  countryCode: "GS",
  alpha3_code: "SGS",
  value: "500"
}, {
  english_name: "South Korea",
  arabic_name: "كوريا الجنوبية",
  countryCode: "KR",
  alpha3_code: "KOR",
  value: "82"
}, {
  english_name: "South Sudan",
  arabic_name: "جنوب السودان",
  countryCode: "SS",
  alpha3_code: "SSD",
  value: "211"
}, {
  english_name: "Spain",
  arabic_name: "إسبانيا",
  countryCode: "ES",
  alpha3_code: "ESP",
  value: "34"
}, {
  english_name: "Sri Lanka",
  arabic_name: "سريلانكا",
  countryCode: "LK",
  alpha3_code: "LKA",
  value: "94"
}, {
  english_name: "Sudan",
  arabic_name: "السودان",
  countryCode: "SD",
  alpha3_code: "SDN",
  value: "249"
}, {
  english_name: "Suriname",
  arabic_name: "سورينام",
  countryCode: "SR",
  alpha3_code: "SUR",
  value: "597"
}, {
  english_name: "Svalbard and Jan Mayen",
  arabic_name: "سفالبارد ويان ماين",
  countryCode: "SJ",
  alpha3_code: "SJM",
  value: "47"
}, {
  english_name: "Sweden",
  arabic_name: "السويد",
  countryCode: "SE",
  alpha3_code: "SWE",
  value: "46"
}, {
  english_name: "Switzerland",
  arabic_name: "سويسرا",
  countryCode: "CH",
  alpha3_code: "CHE",
  value: "41"
}, {
  english_name: "Syria",
  arabic_name: "سوريا",
  countryCode: "SY",
  alpha3_code: "SYR",
  value: "963"
}, {
  english_name: "Taiwan",
  arabic_name: "تايوان",
  countryCode: "TW",
  alpha3_code: "TWN",
  value: "886"
}, {
  english_name: "Tajikistan",
  arabic_name: "طاجيكستان",
  countryCode: "TJ",
  alpha3_code: "TJK",
  value: "992"
}, {
  english_name: "Tanzania",
  arabic_name: "تنزانيا",
  countryCode: "TZ",
  alpha3_code: "TZA",
  value: "255"
}, {
  english_name: "Thailand",
  arabic_name: "تايلاند",
  countryCode: "TH",
  alpha3_code: "THA",
  value: "66"
}, {
  english_name: "Timor-Leste",
  arabic_name: "تيمور الشرقية",
  countryCode: "TL",
  alpha3_code: "TLS",
  value: "670"
}, {
  english_name: "Togo",
  arabic_name: "توجو",
  countryCode: "TG",
  alpha3_code: "TGO",
  value: "228"
}, {
  english_name: "Tokelau",
  arabic_name: "توكيلاو",
  countryCode: "TK",
  alpha3_code: "TKL",
  value: "690"
}, {
  english_name: "Tonga",
  arabic_name: "تونغا",
  countryCode: "TO",
  alpha3_code: "TON",
  value: "676"
}, {
  english_name: "Trinidad and Tobago",
  arabic_name: "ترينيداد وتوباغو",
  countryCode: "TT",
  alpha3_code: "TTO",
  value: "868"
}, {
  english_name: "Tunisia",
  arabic_name: "تونس",
  countryCode: "TN",
  alpha3_code: "TUN",
  value: "216"
}, {
  english_name: "Turkey",
  arabic_name: "تركيا",
  countryCode: "TR",
  alpha3_code: "TUR",
  value: "90"
}, {
  english_name: "Turkmenistan",
  arabic_name: "تركمانستان",
  countryCode: "TM",
  alpha3_code: "TKM",
  value: "993"
}, {
  english_name: "Turks and Caicos Islands",
  arabic_name: "جزر توركس وكايكوس",
  countryCode: "TC",
  alpha3_code: "TCA",
  value: "1"
}, {
  english_name: "Tuvalu",
  arabic_name: "توفالو",
  countryCode: "TV",
  alpha3_code: "TUV",
  value: "688"
}, {
  english_name: "U.S. Minor Outlying Islands",
  arabic_name: "جزر الولايات المتحدة الصغيرة النائية",
  countryCode: "UM",
  alpha3_code: "UMI",
  value: "246"
}, {
  english_name: "U.S. Virgin Islands",
  arabic_name: "جزر العذراء الأمريكية",
  countryCode: "VI",
  alpha3_code: "VIR",
  value: "1"
}, {
  english_name: "Uganda",
  arabic_name: "أوغندا",
  countryCode: "UG",
  alpha3_code: "UGA",
  value: "256"
}, {
  english_name: "Ukraine",
  arabic_name: "أوكرانيا",
  countryCode: "UA",
  alpha3_code: "UKR",
  value: "380"
}, {
  english_name: "United Arab Emirates",
  arabic_name: "الإمارات العربية المتحدة",
  countryCode: "AE",
  alpha3_code: "ARE",
  value: "971"
}, {
  english_name: "United Kingdom",
  arabic_name: "المملكة المتحدة",
  countryCode: "GB",
  alpha3_code: "GBR",
  value: "44"
}, {
  english_name: "United States of America",
  arabic_name: "الولايات المتحدة الأمريكية",
  countryCode: "US",
  alpha3_code: "USA",
  value: "1"
}, {
  english_name: "Uruguay",
  arabic_name: "أوروغواي",
  countryCode: "UY",
  alpha3_code: "URY",
  value: "598"
}, {
  english_name: "Uzbekistan",
  arabic_name: "أوزبكستان",
  countryCode: "UZ",
  alpha3_code: "UZB",
  value: "998"
}, {
  english_name: "Vanuatu",
  arabic_name: "فانواتو",
  countryCode: "VU",
  alpha3_code: "VUT",
  value: "678"
}, {
  english_name: "Vatican City",
  arabic_name: "مدينة الفاتيكان",
  countryCode: "VA",
  alpha3_code: "VAT",
  value: "379"
}, {
  english_name: "Venezuela",
  arabic_name: "فنزويلا",
  countryCode: "VE",
  alpha3_code: "VEN",
  value: "58"
}, {
  english_name: "Vietnam",
  arabic_name: "فيتنام",
  countryCode: "VN",
  alpha3_code: "VNM",
  value: "84"
}, {
  english_name: "Wallis and Futuna",
  arabic_name: "واليس وفوتونا",
  countryCode: "WF",
  alpha3_code: "WLF",
  value: "681"
}, {
  english_name: "Western Sahara",
  arabic_name: "الصحراء الغربية",
  countryCode: "EH",
  alpha3_code: "ESH",
  value: "212"
}, {
  english_name: "Yemen",
  arabic_name: "اليمن",
  countryCode: "YE",
  alpha3_code: "YEM",
  value: "967"
}, {
  english_name: "Zambia",
  arabic_name: "زامبيا",
  countryCode: "ZM",
  alpha3_code: "ZMB",
  value: "260"
}, {
  english_name: "Zimbabwe",
  arabic_name: "زيمبابوي",
  countryCode: "ZW",
  alpha3_code: "ZWE",
  value: "263"
}]