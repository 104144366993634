import moment from "moment";
import i18n from "../i18n/i18n";
import { GlobalVariable } from "./globleVariable";
import React, { useEffect, useRef } from "react";
export function parseMillisecondsIntoReadableTime(milliseconds) {
  //Get hours from milliseconds
  let hours = milliseconds / (1000 * 60 * 60);
  let absoluteHours = Math.floor(hours);
  let h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

  //Get remainder from hours and convert to minutes
  let minutes = (hours - absoluteHours) * 60;
  let absoluteMinutes = Math.floor(minutes);
  let m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  let seconds = (minutes - absoluteMinutes) * 60;
  let absoluteSeconds = Math.floor(seconds);
  let s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;

  return h + ":" + m + ":" + s;
}

export function parseMinutesIntoReadableTime(min) {
  return moment
    .utc(moment.duration(min, "minutes").asMilliseconds()).locale("en-gb")
    .format("HH:mm:ss");
}

export function getColorForStatus(status) {
  if (status === "Not processed") {
    return "rgb(186, 61, 22)";
  } else if (status === "cancelled") {
    return "rgb(219, 216, 11)";
  } else if (status === "completed") {
    return "rgb(8, 166, 27)";
  } else if (status === "no-show") {
    return "rgb(166, 8, 8)";
  } else {
    return "rgb(8, 24, 166)";
  }
}

export function getStatusProperName(status) {
  if (status === "Not processed") {
    return i18n.t("REPORT.Not Processed");
  } else if (status === "cancelled") {
    return i18n.t("REPORT.Cancelled");
  } else if (status === "completed") {
    return i18n.t("REPORT.Served");
  } else if (status === "no-show") {
    return i18n.t("REPORT.No Show");
  } else {
    return i18n.t("REPORT.Total Tickets");
  }
}

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * @name apiErrorHandling
 * @description It's for check response
 * @author (avshesh savani) 15/09/2022
 */
export function apiErrorHandling(error, type) {
  let message = "Oops! Something went wrong on our end. Please try again later or contact our support team."
  try {
    if (error?.response?.status === 500) {
      return {
        status: 500,
        message:
          type !== "post"
            ? message
            : { message },
      };
    } else {
      return {
        status: error.response ? error.response.status : 0,
        message: type !== "post"
          ? error.response.data.message
          : error.response.data,
      };

    }
  } catch (error) {
    return {
      status: 500,
      message:
        type !== "post"
          ? message
          : { message },
    };
  }
}

/**
 * @name checkRoles
 * @description It's check for  the roles
 * @author (Kamiyab Asamadi) 07/12/2022
 */
export const checkRoles = (item, virtualAgent) => {
  let roleCodition =
    item.role === GlobalVariable.agent ||
    item.role === GlobalVariable.concierge;
  if (virtualAgent) {
    roleCodition =
      item.role === GlobalVariable.agent ||
      item.role === GlobalVariable.concierge ||
      item.role === GlobalVariable.virtualAgent;
  }
  return roleCodition;
};

export const zeroPad = (num, places) => String(num).padStart(places, '0')

/**
 * @name GetArabicNumber
 * @description English number to arabic number, make sure pass number in string value
 * @param number
 * @requires number
 */
export function GetArabicNumber(number) {
  number = `${number}`
  var charIndex = 0;
  var NumericArabic = "";
  while (charIndex < number.length) {
    switch (number[charIndex]) {
      case ".":
        NumericArabic += ".";
        break;

      case "0":
        NumericArabic += "٠";
        break;

      case "1":
        NumericArabic += "١";
        break;

      case "2":
        NumericArabic += "٢";
        break;

      case "3":
        NumericArabic += "٣";
        break;

      case "4":
        NumericArabic += "٤";
        break;

      case "5":
        NumericArabic += "٥";
        break;

      case "6":
        NumericArabic += "٦";
        break;

      case "7":
        NumericArabic += "٧";
        break;

      case "8":
        NumericArabic += "٨";
        break;

      case "9":
        NumericArabic += "٩";
        break;

      default:
        NumericArabic += number[charIndex];
        break;
    }

    charIndex++;
  }
  return NumericArabic;
}

export const generateShortName = (name) => {
  if (name) {
    let nameSplit = name.split(" ");
    if (nameSplit.length === 1) {
      return nameSplit[0].charAt(0).toUpperCase();
    }
    if (nameSplit.length > 1) {
      return nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase();
    }
  }
  return ''
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export const isValidInputPrefix = (input) => {
  const allowedCharactersRegex = /^[a-zA-Z0-9]+$/; // Allow only letters and numbers
  return allowedCharactersRegex.test(input);
};

/**
 * @name getArabicWeekDay
 * @description check day in english and arabic and retun day in as per language
 * @param day
 * @requires day
 */

export const getArabicWeekDay = (day) => {
  switch (day.toLowerCase()) {
    case "sunday":
    case "الأحد":
      return i18n.t("APPOINTMENTDASHBOARD.Sunday");
    case "monday":
    case "الإثنين":
      return i18n.t("APPOINTMENTDASHBOARD.Monday");
    case "tuesday":
    case "الثلاثاء":
      return i18n.t("APPOINTMENTDASHBOARD.Tuesday");
    case "wednesday":
    case "الأربعاء":
      return i18n.t("APPOINTMENTDASHBOARD.Wednesday");
    case "thursday":
    case "الخميس":
      return i18n.t("APPOINTMENTDASHBOARD.Thursday");
    case "friday":
    case "الجمعة":
      return i18n.t("APPOINTMENTDASHBOARD.Friday");
    case "saturday":
    case "السبت":
      return i18n.t("APPOINTMENTDASHBOARD.Saturday");
    default:
      return day;
  }
};

/**
 * @name CustomNoDataComponent
 * @description This Function Use For Custome  No Data Component Used in React-table
 * @author (Jenish Patel) 29/04/2024
 */

export const CustomNoDataComponent = ({ state, ...rest }, loading) => {
  if (loading) {
    return null;
  }
  return <div className="rt-noData">{i18n.t("GLOBLE_MESSAGE.loading")}</div>
}


export const getDateLabel = (date) => {
  if (date?.length > 0) {
    const startDate = moment(date[0]).startOf('day');

    const endDate = date.length > 1 ? moment(date[1]).endOf('day') : startDate;

    if (startDate.isSame(moment(), 'day') && endDate.isSame(moment(), 'day')) {
      return i18n.t("DASHBOARD.Today");
    } else if (startDate.isSame(moment().subtract(1, 'days'), 'day') && endDate.isSame(moment().subtract(1, 'days'), 'day')) {
      return i18n.t("DASHBOARD.Yesterday");
    } else if (startDate.isSame(moment().subtract(7, 'days').startOf('day'), 'day') && endDate.isSame(moment().subtract(1, 'days').endOf('day'), 'day')) {
      return i18n.t("DASHBOARD.Last 7 days");
    } else if (startDate.isSame(moment().subtract(30, 'days').startOf('day'), 'day') && endDate.isSame(moment().endOf('day'), 'day')) {
      return i18n.t("DASHBOARD.Last 30 days");
    } else if (startDate.isSame(moment().startOf('month'), 'day') && endDate.isSame(moment().endOf('month'), 'day')) {
      return i18n.t("DASHBOARD.This month");
    } else if (startDate.isSame(moment().subtract(1, 'months').startOf('month'), 'day') && endDate.isSame(moment().subtract(1, 'months').endOf('month'), 'day')) {
      return i18n.t("DASHBOARD.Last Month");
    } else if (date[0] === i18n.t("DASHBOARD.All")) {
      return i18n.t("DASHBOARD.All");
    } else {
      return i18n.t("DASHBOARD.Custom Range");
    }
  } else {
    return i18n.t("GLOBLE_MESSAGE.All");
  }
};
