import { combineReducers } from "redux";

import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
// import { reducer as toastrReducer } from "react-redux-toastr";
import serviceReducers from "./service/index";
import userReducers from "./user/index";
import LoaderReducers from "./loader/index";
import branchReducers from "./branch/index";
import queueReducers from "./queue/index";
import categoryReducers from "./category/index";
import settingReducers from "./setting/index";
import dashboardReducer from "./dashboard/index";
import feedbackReducer from "./feedback/index";
import formReducer from "./form";
const rootReducer = combineReducers({
  customizer: customizer,
  dashboard: dashboardReducer,
  auth: auth,
  navbar: navbar,
  // toastr: toastrReducer,
  service: serviceReducers,
  user: userReducers,
  loader: LoaderReducers,
  branch: branchReducers,
  queue: queueReducers,
  category: categoryReducers,
  setting: settingReducers,
  feedback: feedbackReducer,
  form:formReducer,
});

export default rootReducer;
