import {    
    ADD_QUEUE,
    GET_QUEUE_SERVICE
  } from '../../actions/types';
  
  const initState = {
    queue: {
      queue: [],
      pageNo: 0
    },
    service: []
  };
  
  const queue = (state = initState, action) => {
    switch (action.type) {
     
      case ADD_QUEUE: {
        return { ...state, queue: action.payload }
      }    
      case GET_QUEUE_SERVICE: {
        return { ...state, service: action.payload }
      }
      
      default: {
        return {...state}
      }
    }
  }
  
  export default queue;
  