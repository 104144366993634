import { GlobalVariable } from "./globleVariable";

var socketIOClient = require("socket.io-client");
var sailsIOClient = require("sails.io.js");

let io;

const connectSocket = () => {
  if (socketIOClient.sails) {
    io = socketIOClient;
  } else {
    io = sailsIOClient(socketIOClient);
  }
  io.sails.url = GlobalVariable.apiUrl.socketUrl;
  io.sails.transports = ["websocket"];
  io.sails.path = "/queue/socket.io";
  io.sails.useCORSRouteToGetCookie = false;
  io.sails.reconnection = true;
  io.sails.reconnectionAttempts = 20;
  io.sails.reconnectionDelay = 100;
  io.socket.removeAllListeners();
};
export { io, connectSocket };